import React, { Fragment } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Dashboard from "../../../../components/Dashboard";
import agent from "../../../../agent";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../../../store/types";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import Icon from "../../../../components/Icon";
import B2BTables from "./b2b";
import B2BATables from "./b2ba";
import CDNRTables from "./cdnr";
import CDNRATables from "./cdnra";
import IMGTables from "./img";
import IMGSEZTables from "./imgsez";
import TCSTables from "./tcs";
import TDSTables from "./tds";
import TDSATables from "./tdsa";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/solid";
import ReactPaginate from "react-paginate";
import { downloadFile } from "../../../../helpers/downloadFile";
import {
  gstr2TypesApi as types,
  gstr2Types
} from "../../../../constants/gstr2types";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstr2transactions"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    }),
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload })
});

const headers2A_B2B = [
  "GSTIN of supplier",
  "Trade/Legal name of the Supplier",
  "Invoice Details",
  "Place of supply",
  "Supply Attract Reverse Charge",
  "Rate (%)",
  "Taxable Value (₹)",
  "Tax Amount",
  "GSTR-1/IFF/GSTR-5 Filing Status",
  "GSTR-1/IFF/GSTR-5 Filing Date",
  "GSTR-1/IFF/GSTR-5 Filing Period",
  "GSTR-3B Filing Status",
  "Amendment made, if any",
  "Tax Period in which Amended",
  "Effective date of cancellation",
  "Source",
  "IRN",
  "IRN date"
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class GSTR2ASummary extends React.Component<
  RouteComponentProps,
  PropsFromRedux
> {
  state: {
    loading: boolean;
    selected: string;
    distinctTypes: any;
    gstrDetails: any;
    totalRecords: number;
    currPage: number;
    changeView: boolean;
    selectedOption: string;
    routerData: any;
    exportIcon: string;
    searchText: string;
    typing: boolean;
    typingTimeout: number;
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      selected: "",
      distinctTypes: [],
      gstrDetails: {},
      totalRecords: 0,
      currPage: 0,
      changeView: false,
      selectedOption: "-",
      routerData: [],
      exportIcon: "outline/download",
      searchText: "",
      typing: false,
      typingTimeout: 0
    };
  }

  chunkSize = 20;

  componentDidMount() {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.currentGst !== (this.props as any).currentGst ||
      prevProps.currentMonth !== (this.props as any).currentMonth ||
      prevProps.currentYear !== (this.props as any).currentYear ||
      (this.props as any).bulkCopyDone
    ) {
      this.onLoad();
    }
  }

  getDistinctTypes = ({ distincts }: any) => {
    let DistinctTypes: any = [];
    const types2A = new Set(distincts["2a"]);
    const types2B = new Set(distincts["2b"]);
    const distinct2A = Object.keys(types["2a"]).filter((x: any) =>
      types2A.has(x)
    );
    const distinct2B = Object.keys(types["2a"]).filter((x: any) =>
      types2B.has(x)
    );
    distinct2A.forEach((type: any) => {
      DistinctTypes.push(types["2a"][type]);
    });
    distinct2B.forEach((type: any) => {
      DistinctTypes.push(types["2b"][type]);
    });
    this.setState({ distinctTypes: DistinctTypes });
  };

  onLoad = () => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      agent.Returns.gstr2distinctTypes(month, year, gstId)
        .then((response: any) => {
          this.getDistinctTypes(response);
          this.setState({
            loading: false,
            currPage: 0
          });
          if (this.state.distinctTypes?.length > 0) {
            if (this.state.selected === "")
              this.setState({
                selected: this.state.distinctTypes[0],
                routerData: [
                  (this.props as any).currentGst?._id,
                  (this.props as any).currentMonth,
                  (this.props as any).currentYear
                ]
              });
            this.onSelect(false, false);
          }
        })
        .catch((err: any) => {
          this.setState({ loading: false, currPage: 0 });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
    if ((this.props as any).bulkCopyDone) {
      (this.props as any).updateCommon({ bulkCopyDone: false });
    }
  };

  onSelect = (downloadtype: boolean, notification: boolean) => {
    let gstId: string = (this.props as any).currentGst?._id;
    let year: string = (this.props as any).currentYear;
    let month: string = (this.props as any).currentMonth;
    let skip: string = (this.state.currPage * this.chunkSize).toString();
    let limit: string = this.chunkSize.toString();
    let selected: any = gstr2Types[this.state.selected];
    let platform: string = selected?.platform;
    let type: string = selected?.type;
    let download: boolean = downloadtype;
    let searchText = this.state.searchText;
    downloadtype === false
      ? agent.Returns.gstr2Data(
          month,
          year,
          gstId,
          skip,
          limit,
          platform,
          type,
          download,
          searchText
        )
          .then((response: any) => {
            this.setState({
              gstrDetails: response,
              loading: false,
              totalRecords: response.length,
              changeView: false
            });
          })
          .catch((err: any) => {
            this.setState({ loading: false, changeView: false });
            (this.props as any).addNotification(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          })
      : agent.Returns.gstr2Data(
          month,
          year,
          gstId,
          skip,
          limit,
          platform,
          type,
          download,
          searchText
        )
          .then((response: any) => {
            downloadFile(
              response,
              `${this.state.selected}-${month}${year}.xlsx`
            );

            this.setState({
              loading: false,
              exportIcon: "outline/download"
            });
          })
          .catch((err: any) => {
            this.setState({
              loading: false,
              exportIcon: "outline/download"
            });
            (this.props as any).addNotification(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.onSelect(false, false);
    });
  };

  setSelected = (ev: any) => {
    this.setState({ selected: ev, currPage: 0, changeView: true }, () => {
      this.onSelect(false, false);
    });
  };

  // Option in table for Copy to Purchase
  setSelectedOption = (ev: any) => {
    this.setState({ selectedOption: ev }, () => {
      this.onSelect(false, false);
    });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      typing: false,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.onSelect(false, false);
      }, 700)
    });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="gstr2transactions">
          <div className="max-w-7xl grid grid-flow-col grid-cols-2 mx-auto mt-2">
            <h1 className="inline-block text-2xl font-semibold text-gray-900 mb-4">
              GSTR - 2A/2B
            </h1>
            <div className="ml-80">
              {this.state.distinctTypes?.length > 0 ? (
                <Listbox
                  value={this.state.selected}
                  onChange={this.setSelected}
                >
                  {({ open }) => (
                    <>
                      <div className="mt-1 right-0 relative max-w-xs">
                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-indigo-500 sm:text-sm">
                          <span className="block truncate">
                            {this.state.selected}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {this.state.distinctTypes.map((type: any) => (
                              <Listbox.Option
                                className={({ active }) =>
                                  active
                                    ? "text-white bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9"
                                    : "text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                                }
                                value={type}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={
                                        selected
                                          ? "font-semibold block truncate"
                                          : "font-normal block truncate"
                                      }
                                    >
                                      {type}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={
                                          active
                                            ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                            : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                        }
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              ) : null}
            </div>
          </div>
          {!this.state.loading ? (
            (this.state.distinctTypes?.length > 0 &&
              this.state.gstrDetails.transactions?.length > 0) ||
            (this.state.distinctTypes?.length > 0 &&
              this.state.gstrDetails.transactions?.length > 0 &&
              this.state.searchText.length > 0) ? (
              <>
                <div className="flex grid-cols-2 basis-2 grid max-w-7xl mt-2">
                  <div className="justify-self-start">
                    {/*  <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      onClick={() => this.onSelect(true, false)}
                    >
                      <Icon
                        name={this.state.exportIcon}
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Export as XLSX
                    </button> */}
                  </div>
                  <div>
                    <div className="justify-self-stretch items-center">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Search in date, invoice number, party name, GSTIN, amounts & remarks"
                        value={this.state.searchText}
                        onChange={(ev: any) => {
                          this.handleSearchTextChange(ev);
                        }}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                {this.state.selected === "GSTR - 2A (B2B)" ||
                this.state.selected === "GSTR - 2B (B2B)" ? (
                  <B2BTables
                    state={this.state}
                    setSelectedOption={this.setSelectedOption}
                    //routeData={this.state.routerData} Removed by Rohit as it is was not working correctly on top dropdown changes. Taken directly from props
                  />
                ) : null}
                {this.state.selected === "GSTR - 2A (B2BA)" ||
                this.state.selected === "GSTR - 2B (B2BA)" ? (
                  <B2BATables state={this.state} />
                ) : null}
                {this.state.selected === "GSTR - 2A (CDN)" ||
                this.state.selected === "GSTR - 2B (CDN)" ? (
                  <CDNRTables
                    state={this.state}
                    setSelectedOption={this.setSelectedOption}
                    //routeData={this.state.routerData} Removed by Rohit as it is was not working correctly on top dropdown changes. Taken directly from props
                  />
                ) : null}
                {this.state.selected === "GSTR - 2A (CDNRA)" ||
                this.state.selected === "GSTR - 2B (CDNRA)" ? (
                  <CDNRATables state={this.state} />
                ) : null}
                {this.state.selected === "GSTR - 2A (IMG)" ||
                this.state.selected === "GSTR - 2B (IMG)" ? (
                  <IMGTables state={this.state} />
                ) : null}
                {this.state.selected === "GSTR - 2A (IMGSEZ)" ||
                this.state.selected === "GSTR - 2B (IMGSEZ)" ? (
                  <IMGSEZTables state={this.state} />
                ) : null}
                {this.state.selected === "GSTR - 2A (TCS)" ? (
                  <TCSTables state={this.state} />
                ) : null}
                {this.state.selected === "GSTR - 2A (TDS)" ? (
                  <TDSTables state={this.state} />
                ) : null}
                {this.state.selected === "GSTR - 2A (TDSA)" ? (
                  <TDSATables state={this.state} />
                ) : null}
                {/* Show pagination when total records > chunk count */}
                {this.state.totalRecords > 0 && !this.state.changeView ? (
                  <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing{" "}
                          <span className="font-medium">
                            {this.state.currPage * this.chunkSize + 1}
                          </span>{" "}
                          to{" "}
                          <span className="font-medium">
                            {(this.state.currPage + 1) * this.chunkSize >
                            this.state.totalRecords
                              ? this.state.totalRecords
                              : (this.state.currPage + 1) * this.chunkSize}
                          </span>{" "}
                          of{" "}
                          <span className="font-medium">
                            {this.state.totalRecords}
                          </span>{" "}
                          results
                        </p>
                      </div>
                    </div>
                    <div id="pagination" className="text-sm text-gray-500 my-2">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          this.state.totalRecords / this.chunkSize
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  strokeWidth={1}
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No GSTR-2A/GSTR-2B entries present.
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by importing files.
                </p>
                <div className="mt-6">
                  <Link
                    to={(this.props as any).urlInfo + "/returns/gstr2/import"}
                  >
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Icon
                        name="outline/upload"
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      IMPORT
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            // until the data loads, show loading skeleton
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-lg">
                          <table className="table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {headers2A_B2B.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(16)].map((e, i) => (
                                    <td className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default connector(GSTR2ASummary);
