import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Dashboard from "../../components/Dashboard";
import RadioButton from "../../components/RadioButton";

export default class Report extends React.Component<RouteComponentProps> {
  state: { loading: boolean };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  render() {
    return (
      <Dashboard match={this.props.match}>
        <h1 className="text-2xl font-semibold text-gray-900">Reports</h1>

        <RadioButton
          list={[
            {
              title: "GST Summary Report",
              description:
                "Download excel file which shows all sales, sales return, purchase and purchase return for the selected month/quarter/year along with a summary.",
              link: this.props.match.url + "/summary"
            }
          ]}
          handleValue={""}
        ></RadioButton>
      </Dashboard>
    );
  }
}
