import { Fragment, useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { RouteComponentProps } from "react-router";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";

import Dashboard from "../../../components/Dashboard";
import ColumnsFilter from "../../../components/columnsFilter";
import agent from "../../../agent";
import { onNotify } from "../../../store/reducers/notification";
import Icon from "../../../components/Icon";
import { tabNames } from "../../../constants/itcClaimTabs";
import convertNumber from "../../../helpers/convertNumber";
import getState from "../../../constants/states";
import { formatDate } from "../../../helpers/formatDate";
import ReactPaginate from "react-paginate";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "itcclaim"
  },
  dataLayerName: "PageDataLayer"
};
interface ITCClaimProps extends RouteComponentProps {}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const options = ["Yes", "No", "-"];

const ITCClaim = (props: ITCClaimProps) => {
  TagManager.dataLayer(tagManagerArgs);

  const dispatch = useDispatch();
  const { currentGst, currentMonth, currentYear } = useSelector(
    (state: any) => state.common
  );

  const [tabs, setTabs] = useState<any>({
    "2B Purchase": 0,
    "2A Purchase": 0,
    "No 2A/2B Purchase": 0,
    "2B Return": 0,
    "2A Return": 0,
    "No 2A/2B Return": 0
  });

  const [headersItcClaim, setHeadersItcClaim] = useState<any>({
    date: { id: 1, name: "Invoice Date", visible: true },
    itcClaim: { id: 2, name: "Claim ITC", visible: true },
    itcAsPer2B: { id: 3, name: "ITC as per 2B", visible: true },
    itcClaimPeriod: { id: 17, name: "ITC Claimed In", visible: true },
    invoiceNumber: { id: 4, name: "Invoice Number", visible: true },
    partyName: { id: 5, name: "Party Name", visible: true },
    vendorGstin: { id: 6, name: "GSTIN", visible: true },
    HsnSac: { id: 7, name: "HSN/ SAC", visible: true },
    placeOfSupply: { id: 8, name: "Place of Supply", visible: true },
    taxableAmount: { id: 9, name: "Taxable Amount", visible: true },
    gstRate: { id: 10, name: "GST Rate", visible: true },
    igstAmt: { id: 11, name: "IGST Amount", visible: true },
    cgstAmt: { id: 12, name: "CGST Amount", visible: true },
    utgstOrSgstAmt: { id: 13, name: "SGST/UTGST Amount", visible: true },
    invoiceAmount: { id: 14, name: "Invoice Amount", visible: true },
    remarks: { id: 15, name: "Remarks", visible: true }
    // delete: { id: 16, name: 'Delete Transaction', visible: true },
  });

  const chunkSize = 10;
  const [currentTab, setCurrentTab] = useState<string>("2B Purchase");
  const [headers, setHeaders] = useState(headersItcClaim);
  const [itc2BVisible, setItc2BVisible] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [purchases, setPurchases] = useState([]);
  const [currPage, setPage] = useState<number>(0);

  const onLoad = useCallback(() => {
    setLoading(true);
    agent.Returns.itcClaimCount(currentGst._id, currentMonth, currentYear)
      .then(({ response: counts }: any) => {
        setLoading(false);
        const newTabCounts: any = {};
        Object.keys(tabNames).forEach((tab: any) => {
          newTabCounts[tab] = counts[tabNames[tab]] ? counts[tabNames[tab]] : 0;
        });
        setTabs(newTabCounts);
      })
      .catch((err: any) => {
        setLoading(false);
        dispatch(
          onNotify(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          )
        );
      });
  }, [currentGst, currentMonth, currentYear, dispatch]);

  const handleTabChange = (tabName: string) => {
    setCurrentTab(tabName);
    setItc2BVisible(tabName.startsWith("2B"));
    setHeaders(
      Object.keys(headersItcClaim)
        .filter(
          (o: any) =>
            headersItcClaim[o].visible === true &&
            (o !== "itcAsPer2B" || tabName.startsWith("2B"))
        )
        .map(obj => {
          return headersItcClaim[obj];
        })
    );
  };

  const getPurchases = useCallback(
    (tabName: string) => {
      let transactionType = tabName.endsWith("Purchase")
        ? "Purchase"
        : "Purchase Return";
      let skip: string = (currPage * chunkSize).toString();
      let limit: string = chunkSize.toString();
      if (tabName.startsWith("2A")) {
        setLoading(true);
        agent.Returns.get2APurchases(
          currentGst._id,
          currentMonth,
          currentYear,
          transactionType,
          skip,
          limit
        )
          .then((response: any) => {
            setPurchases(response.transactions);
            setRecordCount(response.count ? response.count : 0);
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            dispatch(
              onNotify(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              )
            );
          });
      } else if (tabName.startsWith("2B")) {
        setLoading(true);
        agent.Returns.get2BPurchases(
          currentGst._id,
          currentMonth,
          currentYear,
          transactionType,
          skip,
          limit
        )
          .then((response: any) => {
            setPurchases(response.transactions);
            setRecordCount(response.count ? response.count : 0);
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            dispatch(
              onNotify(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              )
            );
          });
      } else {
        setLoading(true);
        agent.Returns.getUnclaimedPurchases(
          currentGst._id,
          currentMonth,
          currentYear,
          transactionType,
          skip,
          limit
        )
          .then((response: any) => {
            setPurchases(response.transactions);
            setRecordCount(response.count ? response.count : 0);
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            dispatch(
              onNotify(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              )
            );
          });
      }
    },
    [currentGst, currentMonth, currentYear, dispatch, currPage]
  );

  const handlePageClick = (data: any) => {
    setPage(data.selected);
  };

  const toggleHeader = (value: any) => {
    let values: any;
    let toggle: any;
    values = headersItcClaim;
    toggle = Object.keys(values).find(
      (o: any) => values[o].id === value.id && values[o].name === value.name
    );
    values[toggle].visible = !values[toggle].visible;
    setHeadersItcClaim(values);
    const reqdHeaders = requiredHeaders();
    setHeaders(
      Object.keys(reqdHeaders)
        .filter((o: any) => reqdHeaders[o].visible === true)
        .map(obj => {
          return reqdHeaders[obj];
        })
    );
  };

  const requiredHeaders = () => {
    if (!itc2BVisible) {
      const { itcAsPer2B, ...headerValues } = headersItcClaim;
      return headerValues;
    }
    return headersItcClaim;
  };

  const claimItc = (value: any, id: string) => {
    const claim = value === "Yes" ? true : false;
    const removeItcFields = value === "-" ? true : false;
    setLoading(true);
    agent.Returns.claimItc(
      id,
      claim,
      currentMonth,
      currentYear,
      removeItcFields
    )
      .then((response: any) => {
        setLoading(false);
        claim
          ? dispatch(onNotify("Success", "Successfully claimed!", "success"))
          : dispatch(
              onNotify("Success", "Status changed successfully!", "success")
            );
        getPurchases(currentTab);
        onLoad();
      })
      .catch((err: any) => {
        setLoading(false);
        dispatch(
          onNotify(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          )
        );
      });
  };

  useEffect(() => {
    if (currentGst && currentMonth && currentYear) {
      onLoad();
      setPage(0);
    }
  }, [currentGst, currentMonth, currentYear, currentTab, onLoad]);

  useEffect(() => {
    if (currentGst && currentMonth && currentYear) {
      getPurchases(currentTab);
    }
  }, [currentGst, currentMonth, currentYear, currentTab, getPurchases]);

  return (
    <Dashboard match={props.match}>
      <div className="itcclaim">
        <div className="max-w-7xl">
          <h1 className="inline-block text-2xl font-semibold text-gray-900 mb-4">
            ITC Claim
          </h1>

          <div>
            <div className="hidden sm:block bg-white shadow rounded-t-lg px-4 py-5 mt-5">
              <div className="border-b border-gray-200 overflow-scroll 2xl:overflow-hidden">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {Object.keys(tabs).map(tab => (
                    <span
                      key={tab}
                      className={classNames(
                        tab === currentTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                        "whitespace-nowrap flex py-4 px-4 border-b-2 font-medium text-sm cursor-pointer"
                      )}
                      aria-current={tab === currentTab ? "page" : undefined}
                      onClick={() => {
                        handleTabChange(tab);
                      }}
                    >
                      {tab}
                      <span
                        key={tab}
                        className={classNames(
                          tab === currentTab
                            ? "bg-indigo-100 text-indigo-600"
                            : "bg-gray-100 text-gray-900",
                          "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                        )}
                      >
                        {tabs[tab]}
                      </span>
                    </span>
                  ))}
                </nav>
              </div>
              {!loading ? (
                recordCount > 0 ? (
                  <>
                    <div className="max-w-7xl flex justify-end my-3">
                      <ColumnsFilter
                        headers={requiredHeaders()}
                        toggleHeader={toggleHeader}
                      />
                    </div>
                    <div className="mt-3 overflow-scroll">
                      <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            {Object.keys(headers).map((header: string) => (
                              <th
                                key={headers[header]?.id}
                                scope="col"
                                className="w-1/16 px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {headers[header]?.id === 2
                                  ? currentTab === "2B Return" || //the names are hardcoded as tabs array has been modified at this point
                                    currentTab === "2A Return" ||
                                    currentTab === "No 2A/2B Return"
                                    ? "Reverse ITC"
                                    : "Claim ITC"
                                  : headers[header]?.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {purchases.map((row: any) => (
                            <tr key={row._id}>
                              {headersItcClaim["date"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {row.date ? formatDate(row.date) : "-"}
                                </td>
                              ) : null}
                              {headersItcClaim["itcClaim"]?.visible ? (
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                  <div className="mt-1 relative">
                                    <Listbox
                                      value={
                                        row.itcClaim === true
                                          ? "Yes"
                                          : row.itcClaim === false
                                          ? "No"
                                          : "-"
                                      }
                                      onChange={e => claimItc(e, row._id)}
                                    >
                                      {({ open }) => (
                                        <>
                                          <div className="mt-1 right-0 relative max-w-xs">
                                            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-indigo-500 sm:text-sm">
                                              <span className="block truncate">
                                                {row.itcClaim === true
                                                  ? "Yes"
                                                  : row.itcClaim === false
                                                  ? "No"
                                                  : "-"}
                                              </span>
                                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <Icon
                                                  name="outline/select"
                                                  className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </Listbox.Button>

                                            <Transition
                                              show={open}
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                {options.map(
                                                  (option: any, i) => (
                                                    <Listbox.Option
                                                      key={i}
                                                      className={({ active }) =>
                                                        active
                                                          ? "text-white bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9"
                                                          : "text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                                                      }
                                                      value={option}
                                                    >
                                                      {({
                                                        selected,
                                                        active
                                                      }) => (
                                                        <>
                                                          <span
                                                            className={
                                                              selected
                                                                ? "font-semibold block"
                                                                : "font-normal block"
                                                            }
                                                          >
                                                            {option}
                                                          </span>

                                                          {selected ? (
                                                            <span
                                                              className={
                                                                active
                                                                  ? "text-white absolute inset-y-0 right-0 flex items-center pr-2"
                                                                  : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-2"
                                                              }
                                                            >
                                                              <Icon
                                                                name="outline/check"
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                              />
                                                            </span>
                                                          ) : null}
                                                        </>
                                                      )}
                                                    </Listbox.Option>
                                                  )
                                                )}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </>
                                      )}
                                    </Listbox>
                                  </div>
                                </td>
                              ) : null}
                              {itc2BVisible ? (
                                headersItcClaim["itcAsPer2B"]?.visible ? (
                                  <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                    {row.itcAvl
                                      ? row.itcAvl === "Y"
                                        ? "Yes"
                                        : "No"
                                      : "-"}
                                  </td>
                                ) : null
                              ) : null}
                              {headersItcClaim["itcClaimPeriod"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {row.itcClaimMonth
                                    ? row.itcClaimMonth +
                                      " " +
                                      (row.itcClaimMonth === "Apr" ||
                                      row.itcClaimMonth === "May" ||
                                      row.itcClaimMonth === "Jun" ||
                                      row.itcClaimMonth === "Jul" ||
                                      row.itcClaimMonth === "Aug" ||
                                      row.itcClaimMonth === "Sep" ||
                                      row.itcClaimMonth === "Oct" ||
                                      row.itcClaimMonth === "Nov" ||
                                      row.itcClaimMonth === "Dec"
                                        ? row.itcClaimYear.slice(2, 4)
                                        : row.itcClaimMonth === "Jan" ||
                                          row.itcClaimMonth === "Feb" ||
                                          row.itcClaimMonth === "Mar"
                                        ? row.itcClaimYear.slice(5, 7)
                                        : "")
                                    : "-"}
                                </td>
                              ) : null}
                              {headersItcClaim["invoiceNumber"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                  {row.invoiceNumber ? row.invoiceNumber : "-"}
                                </td>
                              ) : null}
                              {headersItcClaim["partyName"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {row.partyName ? row.partyName : "-"}
                                </td>
                              ) : null}
                              {headersItcClaim["vendorGstin"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {row.vendorGstin ? row.vendorGstin : "-"}
                                </td>
                              ) : null}
                              {headersItcClaim["HsnSac"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {row.HsnSac ? row.HsnSac : "-"}
                                </td>
                              ) : null}
                              {headersItcClaim["placeOfSupply"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {row.placeOfSupply
                                    ? getState(row.placeOfSupply)
                                    : "-"}
                                </td>
                              ) : null}
                              {headersItcClaim["taxableAmount"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                  {convertNumber(row.taxableAmount)}
                                </td>
                              ) : null}
                              {headersItcClaim["gstRate"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                  {row.gstRate ? row.gstRate : 0}
                                </td>
                              ) : null}
                              {headersItcClaim["igstAmt"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                  {convertNumber(row.igstAmt)}
                                </td>
                              ) : null}
                              {headersItcClaim["cgstAmt"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                  {convertNumber(row.cgstAmt)}
                                </td>
                              ) : null}
                              {headersItcClaim["utgstOrSgstAmt"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                  {convertNumber(row.utgstOrSgstAmt)}
                                </td>
                              ) : null}
                              {headersItcClaim["invoiceAmount"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                  {convertNumber(row.invoiceAmount)}
                                </td>
                              ) : null}
                              {headersItcClaim["remarks"]?.visible ? (
                                <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                  {row.remarks ? row.remarks : "-"}
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  // Empty activity
                  <>
                    {/* <div className='max-w-7xl flex justify-end mt-2'></div> */}
                    <div className="text-center my-12 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                      <Icon
                        name="outline/document-add"
                        strokeWidth={1}
                        className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                      />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        No Purchase Data
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Get started by importing 2A/2B files.
                      </p>
                      <div className="mt-6">
                        <Link to="./gstr2/import">
                          <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                          >
                            <Icon
                              name="outline/upload"
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            IMPORT
                          </button>
                        </Link>
                      </div>
                    </div>
                  </>
                )
              ) : (
                //Loading
                <>
                  <div className="max-w-7xl flex justify-end my-2">
                    <ColumnsFilter
                      headers={requiredHeaders()}
                      toggleHeader={toggleHeader}
                    />
                  </div>
                  <div className="mt-2">
                    <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {Object.keys(headers).map((header: string) => (
                            <th
                              key={headers[header]?.id}
                              scope="col"
                              className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {headers[header]?.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(4)].map((e, i) => (
                          <tr className="bg-white" key={i}>
                            {Object.keys(headers).map((e, i) => (
                              <td
                                className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                key={i}
                              >
                                <Skeleton />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {recordCount > chunkSize ? (
                <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing{" "}
                        <span className="font-medium">
                          {currPage * chunkSize + 1}
                        </span>{" "}
                        to{" "}
                        <span className="font-medium">
                          {(currPage + 1) * chunkSize > recordCount
                            ? recordCount
                            : (currPage + 1) * chunkSize}
                        </span>{" "}
                        of <span className="font-medium">{recordCount}</span>{" "}
                        results
                      </p>
                    </div>
                  </div>
                  <div id="pagination" className="text-sm text-gray-500 my-2">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={Math.ceil(recordCount / chunkSize)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default ITCClaim;
