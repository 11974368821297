import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Dashboard from "../../../components/Dashboard";
import agent from "../../../agent";
import { ADD_NOTIFICATION } from "../../../store/types";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { ViewGridAddIcon, UploadIcon } from "@heroicons/react/outline";
import convertNumber from "../../../helpers/convertNumber";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "purchasesummary"
  },
  dataLayerName: "PageDataLayer"
};

const items = [
  {
    name: "Add Your GST Number",
    description: "Start Getting Insights by adding a GST Number.",
    href: "/gsts/add",
    iconCss:
      "bg-pink-500 inline-flex items-center justify-center h-10 w-10 rounded-lg",
    icon: ViewGridAddIcon
  },
  {
    name: "Import Purchase Transaction Files",
    description:
      "Start by importing purchase and purchase returns transaction files.",
    href: "./import",
    iconCss:
      "bg-purple-500 inline-flex items-center justify-center h-10 w-10 rounded-lg",
    icon: UploadIcon
  }
];

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const types = ["Purchase", "Purchase Returns", "Net Purchase"];

const headers = [
  "Transaction Type",
  "Taxable Amount",
  "IGST",
  "CGST",
  "SGST/UTGST",
  "Invoice Amount"
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Summary extends React.Component<RouteComponentProps, PropsFromRedux> {
  state: { loading: boolean; summaryDetails: any };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      summaryDetails: {}
    };
  }

  componentDidMount() {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      (prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear) !== (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  }

  onLoad = () => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      agent.Purchase.getSummary(month, year, gstId)
        .then((response: any) => {
          this.setState({
            summaryDetails: response,
            loading: false
          });
          Object.keys(this.state.summaryDetails).map(key => {
            return this.setState({
              summaryDetails: this.state.summaryDetails[key],
              types: this.state.summaryDetails[key]
            });
          });
          this.updateSummaryDetails();
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  subtractNumber = (num1: any, num2: any) => {
    if (
      Number(Number(num1 ? num1 : 0) - Number(num2 ? num2 : 0)) > 0 ||
      Number(Number(num1 ? num1 : 0) - Number(num2 ? num2 : 0)) < 0
    ) {
      return Number(Number(num1 ? num1 : 0) - Number(num2 ? num2 : 0));
    } else return 0;
  };

  updateSummaryDetails = () => {
    let tempSummaryDetails = this.state.summaryDetails.filter(
      (o: any) => o.platform !== "Total"
    );
    tempSummaryDetails.sort((a: any, b: any) =>
      a.platform.localeCompare(b.platform)
    );
    let totalSummary = this.state.summaryDetails.find(
      (o: any) => o.platform === "Total"
    );
    if (totalSummary) tempSummaryDetails.unshift(totalSummary);
    this.setState({
      summaryDetails: tempSummaryDetails
    });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="purchasesummary">
          <h1 className="text-2xl font-semibold text-gray-900 mb-4">
            Purchase Summary
          </h1>
          {!this.state.loading ? (
            this.state.summaryDetails &&
            this.state.summaryDetails.length > 0 ? (
              <>
                {Object.keys(this.state.summaryDetails).map(entry => {
                  return (
                    <div className="my-5">
                      {this.state.summaryDetails[entry].taxableAmount ||
                      this.state.summaryDetails[entry].igst ||
                      this.state.summaryDetails[entry].sgst ||
                      this.state.summaryDetails[entry].invoiceAmount ? (
                        <>
                          <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                            <div className="px-4 py-5 sm:p-6">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {this.state.summaryDetails[entry].platform}
                              </h3>
                            </div>
                          </div>
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  {headers.map(header => (
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      {header}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {types.map(type => (
                                  <tr
                                    className={
                                      type === "Net Purchase"
                                        ? "bg-gray-50"
                                        : "bg-white"
                                    }
                                  >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {type}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {this.state.summaryDetails[entry]
                                        .taxableAmount
                                        ? type === "Purchase"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .taxableAmount.purchase
                                            )
                                          : type === "Purchase Returns"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .taxableAmount.purchaseReturn
                                            )
                                          : convertNumber(
                                              this.subtractNumber(
                                                this.state.summaryDetails[entry]
                                                  .taxableAmount.purchase,
                                                this.state.summaryDetails[entry]
                                                  .taxableAmount.purchaseReturn
                                              )
                                            )
                                        : 0}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {this.state.summaryDetails[entry].igst
                                        ? type === "Purchase"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .igst.purchase
                                            )
                                          : type === "Purchase Returns"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .igst.purchaseReturn
                                            )
                                          : convertNumber(
                                              this.subtractNumber(
                                                this.state.summaryDetails[entry]
                                                  .igst.purchase,
                                                this.state.summaryDetails[entry]
                                                  .igst.purchaseReturn
                                              )
                                            )
                                        : 0}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {this.state.summaryDetails[entry].cgst
                                        ? type === "Purchase"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .cgst.purchase
                                            )
                                          : type === "Purchase Returns"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .cgst.purchaseReturn
                                            )
                                          : convertNumber(
                                              this.subtractNumber(
                                                this.state.summaryDetails[entry]
                                                  .cgst.purchase,
                                                this.state.summaryDetails[entry]
                                                  .cgst.purchaseReturn
                                              )
                                            )
                                        : 0}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {this.state.summaryDetails[entry].sgst
                                        ? type === "Purchase"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .sgst.purchase
                                            )
                                          : type === "Purchase Returns"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .sgst.purchaseReturn
                                            )
                                          : convertNumber(
                                              this.subtractNumber(
                                                this.state.summaryDetails[entry]
                                                  .sgst.purchase,
                                                this.state.summaryDetails[entry]
                                                  .sgst.purchaseReturn
                                              )
                                            )
                                        : 0}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {this.state.summaryDetails[entry]
                                        .invoiceAmount
                                        ? type === "Purchase"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .invoiceAmount.purchase
                                            )
                                          : type === "Purchase Returns"
                                          ? convertNumber(
                                              this.state.summaryDetails[entry]
                                                .invoiceAmount.purchaseReturn
                                            )
                                          : convertNumber(
                                              this.subtractNumber(
                                                this.state.summaryDetails[entry]
                                                  .invoiceAmount.purchase,
                                                this.state.summaryDetails[entry]
                                                  .invoiceAmount.purchaseReturn
                                              )
                                            )
                                        : 0}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="max-w-lg my-20 mx-auto">
                <h2 className="text-lg font-medium text-gray-900">
                  No Purchase Record Present
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Get Your First Purchase Summary by performing the following
                  action
                </p>
                <ul className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
                  {((this.props as any).currentGst
                    ? items.filter(
                        i => i.name === "Import Purchase Transaction Files"
                      )
                    : items.filter(i => i.name === "Add GST Number")
                  ).map((item, itemIdx) => (
                    <li key={itemIdx}>
                      <div className="relative group py-4 flex items-start space-x-3">
                        <div className="flex-shrink-0">
                          <span className={item.iconCss}>
                            <item.icon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-medium text-gray-900">
                            <Link to={item.href}>
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </div>
                          <p className="text-sm text-gray-500">
                            {item.description}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )
          ) : (
            // until the data loads, show loading skeleton
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {headers.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(6)].map((e, i) => (
                                    <td className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default connector(Summary);
