import { UPDATE_COMMON } from "../types";

const initialState = {
  showMaintenance: false,
  maintenanceRunningMessage: "",
  upcomingMaintenanceMessage: ""
};

type CommonState = typeof initialState & { [key: string]: any };

export type CommonAction = {
  type: typeof UPDATE_COMMON;
  payload: Partial<CommonState>;
};

const commonReducer = (state = initialState, action: CommonAction) => {
  switch (action.type) {
    case UPDATE_COMMON:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default commonReducer;
