/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Link, RouteComponentProps } from "react-router-dom";

/* const options = [
  {
    //id: 1,
    title: "Extra GST Credits",
    description: "Purchase more credits as per your current plan"
  },
  {
    //id: 2,
    title: "Renewal",
    description: "Renew your plan which is expiring soon/expired"
  },
  {
    //id: 3,
    title: "Upgrade",
    description: "Upgrade your current plan to a higher validity plans"
  }
]; */

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  list: any;
  handleValue: any;
  //onChange: (item: any) => void;
};

export default function RadioButton(props: Props) {
  const options = props.list;
  const [selectedOptions, setSelectedOptions] = useState();

  useEffect(() => {
    if (selectedOptions) {
      props.handleValue(selectedOptions);
      //props.handleValue(selectedOptions.title);
    }
  }, [selectedOptions]);

  return (
    <RadioGroup value={selectedOptions} onChange={setSelectedOptions}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">
        {/* Select a mailing list */}
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {options.map((option: any) => (
          <RadioGroup.Option
            //key={option.id}
            value={option}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex-1 flex">
                  <Link to={option.link}>
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {option.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-sm text-gray-500"
                      >
                        {option.description}
                      </RadioGroup.Description>
                      {/* <RadioGroup.Description
                      as="span"
                      className="mt-6 text-sm font-medium text-gray-900"
                    >
                      {option.users}
                    </RadioGroup.Description> */}
                    </span>
                  </Link>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-indigo-500" : "border-transparent",
                    "absolute -inset-px rounded-lg pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
