import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Dashboard from "../../../components/Dashboard";
import agent from "../../../agent";
import { ADD_NOTIFICATION } from "../../../store/types";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import fileDownloader from "js-file-download";
import Icon from "../../../components/Icon";
import getState from "../../../constants/states";
import convertNumber from "../../../helpers/convertNumber";
import TagManager from "react-gtm-module";
import FilingFreqency from "../../../helpers/filingFrequency";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstr3b"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const table3_1Headers = [
  "Nature of Supplies",
  "Total Taxable Value",
  "IGST",
  "CGST",
  "SGST/UTGST",
  "Total"
];

//const validMonths = ["Mar", "Jun", "Sep", "Dec"];

const Table3_1 = [
  {
    name: "outwardSuppliesWithoutGst0",
    value:
      "Outward Taxable Supplies (Other than zero rated, nil rated and exempted)"
  },
  {
    name: "outwardSuppliesWithGst",
    value: "Outward Taxable Supplies (Zero Rated)"
  },
  {
    name: "otherSupplies",
    value: "Other Outward Supplies (Nil Rated, Exempted)"
  },
  {
    name: "inwardSupplies",
    value: "Inward Supplies (Liable to Reverse Charge)"
  },
  {
    name: "nonGstOutwardSupplies",
    value: "Non-Gst Outward Supplies"
  },
  {
    name: "total",
    value: "Total"
  }
];

const table4_1Headers = ["", "IGST", "CGST", "SGST/UTGST"];

const table3_2Headers = ["Place of Supply", "Total Taxable Value", "IGST"];

const Table4a = [
  {
    name: "img",
    value: "Import of goods"
  },
  {
    name: "ims",
    value: "Import of services"
  },
  {
    name: "rcm",
    value: "Inward supplies liable to reverse charge (other than 1 & 2 above)"
  },
  {
    name: "isd",
    value: "Inward supplies from ISD"
  },
  {
    name: "itc",
    value: "All other ITC"
  },
  {
    name: "total",
    value: "Total"
  }
];

const Table4b = [
  {
    name: "rule",
    value: "As per Rule 42 & 43 of CGST/SGST rules"
  },
  {
    name: "other",
    value: "Others"
  }
];

const Table4d = [
  {
    name: "rule",
    value: "As per section 17(5)"
  },
  {
    name: "other",
    value: "Others"
  }
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class GSTR3B extends React.Component<RouteComponentProps, PropsFromRedux> {
  state: { loading: boolean; gstr3bDetails: any };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      gstr3bDetails: {}
    };
  }

  componentDidMount() {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  }

  //old comment before adding logic of GSTR3B monthly
  /* componentDidUpdate(prevProps: any) {
    if (
      (prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear) !== (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  } */

  componentDidUpdate(prevProps: any) {
    if (
      (prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear) !== (this.props as any).currentYear
    ) {
      if (
        FilingFreqency.findFilingFreq(
          (this.props as any).currentGst.filingFrequency,
          (this.props as any).currentMonth,
          (this.props as any).currentYear
        ) === "Quarterly"
      ) {
        (this.props as any).currentMonth === "Mar" ||
        (this.props as any).currentMonth === "Jun" ||
        (this.props as any).currentMonth === "Sep" ||
        (this.props as any).currentMonth === "Dec"
          ? this.onLoad()
          : this.displayInvalidMonth();
      } else if (
        FilingFreqency.findFilingFreq(
          (this.props as any).currentGst.filingFrequency,
          (this.props as any).currentMonth,
          (this.props as any).currentYear
        ) === "Monthly"
      ) {
        (this.props as any).currentMonth === "Jan" ||
        (this.props as any).currentMonth === "Feb" ||
        (this.props as any).currentMonth === "Mar" ||
        (this.props as any).currentMonth === "Apr" ||
        (this.props as any).currentMonth === "May" ||
        (this.props as any).currentMonth === "Jun" ||
        (this.props as any).currentMonth === "Jul" ||
        (this.props as any).currentMonth === "Aug" ||
        (this.props as any).currentMonth === "Sep" ||
        (this.props as any).currentMonth === "Oct" ||
        (this.props as any).currentMonth === "Nov" ||
        (this.props as any).currentMonth === "Dec"
          ? this.onLoad()
          : this.displayInvalidMonth();
      }
    }
  }

  displayInvalidMonth = () => {
    this.setState({
      gstrDetails: [],
      loading: false
    });
  };

  validMonths = () => {
    let validMonths =
      FilingFreqency.findFilingFreq(
        (this.props as any).currentGst.filingFrequency,
        (this.props as any).currentMonth,
        (this.props as any).currentYear
      ) === "Quarterly"
        ? ["Mar", "Jun", "Sep", "Dec"]
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ];
    return validMonths;
  };

  onLoad = () => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      agent.Returns.gstr3bData(month, year, gstId)
        .then((response: any) => {
          this.setState({
            gstr3bDetails: response,
            loading: false
          });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  handleClick = () => {
    let gstId: string = (this.props as any).currentGst?._id;
    let year: string = (this.props as any).currentYear;
    let month: string = (this.props as any).currentMonth;
    agent.Returns.gstr3bJson(month, year, gstId)
      .then((data: any) => {
        let res = JSON.stringify(data);
        let clientName = (this.props as any).currentGst?.lgnm;
        let fileName =
          "GSTR-3B " +
          clientName.slice(0, 40) +
          " " +
          month +
          " " +
          year +
          ".json";
        fileDownloader(res, fileName);
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  render() {
    console.log(this.props as any);
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="gstr3b">
          <div>
            <h1 className="inline-block text-2xl font-semibold text-gray-900 mb-4">
              GSTR - 3B
            </h1>
            <button
              type="button"
              onClick={this.handleClick}
              className="float-right inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              Generate JSON
            </button>
          </div>
          {!this.state.loading ? (
            this.validMonths().includes((this.props as any).currentMonth) ? (
              this.state.gstr3bDetails ? (
                <>
                  {this.state.gstr3bDetails?.tb31 ? (
                    <div className="my-4 max-w-7xl mx-auto">
                      <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Table 3.1
                          </h3>
                        </div>
                      </div>
                      <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                        <table className="min-w-full table-auto divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            {table3_1Headers.map((header, i) => (
                              <th
                                key={i}
                                scope="col"
                                className="w-1/16 px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {header}
                              </th>
                            ))}
                          </thead>
                          <tbody>
                            <>
                              {Object.keys(this.state.gstr3bDetails?.tb31).map(
                                (key: any) => {
                                  return (
                                    <tr
                                      className={
                                        key === "total"
                                          ? "bg-gray-100"
                                          : "bg-white"
                                      }
                                    >
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {Table3_1.filter(
                                          nature => nature?.name === key
                                        ).map(filterednature => {
                                          return key === filterednature.name
                                            ? filterednature.value
                                            : null;
                                        })}
                                      </td>
                                      <td className="px-5 py-4 text-center whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails.tb31[key]
                                            ?.taxableValue
                                        )}
                                      </td>
                                      <td className="px-5 py-4 text-right whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails.tb31[key]
                                            ?.igst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 text-right whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails.tb31[key]
                                            ?.cgst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 text-right whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails.tb31[key]
                                            ?.sgst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 text-right whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails.tb31[key]
                                            ?.total
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                  {(this.state.gstr3bDetails?.tb32 &&
                    this.state.gstr3bDetails?.tb32.length > 0) ||
                  (this.state.gstr3bDetails?.tb32Composition &&
                    this.state.gstr3bDetails?.tb32Composition.length > 0) ||
                  (this.state.gstr3bDetails?.tb32UIN &&
                    this.state.gstr3bDetails?.tb32UIN.length > 0) ? (
                    <div className="my-4 max-w-7xl mx-auto">
                      <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                        <div className="px-4 py-5 sm:px-6 py-6 pt-6 pb-1">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Table 3.2
                          </h3>
                        </div>
                      </div>
                      {this.state.gstr3bDetails?.tb32 &&
                      this.state.gstr3bDetails?.tb32.length > 0 ? (
                        <div>
                          <div className="bg-white overflow-hidden shadow sm:rounded-none-lg">
                            <div className="px-4 py-5 pt whitespace-nowrap text-sm font-medium text-gray-600">
                              Supplies made to Unregistered Persons
                            </div>
                          </div>

                          <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                            <table className="min-w-full table-auto divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                {table3_2Headers.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-4 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </thead>
                              <tbody>
                                {Object.keys(
                                  this.state.gstr3bDetails?.tb32
                                ).map((key: any) => {
                                  return (
                                    <tr className="bg-white">
                                      <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {this.state.gstr3bDetails?.tb32[key].pos
                                          ? getState(
                                              this.state.gstr3bDetails?.tb32[
                                                key
                                              ].pos
                                            )
                                          : "-"}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb32[key]
                                            .taxableAmount
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb32[key]
                                            .igst
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                      {this.state.gstr3bDetails?.tb32Composition &&
                      this.state.gstr3bDetails?.tb32Composition.length > 0 ? (
                        <div>
                          <div className="bg-white overflow-hidden shadow sm:rounded-none-lg">
                            <div className="px-4 py-5 pt whitespace-nowrap text-sm font-medium text-gray-600">
                              Supplies made to Composition Taxable Person
                            </div>
                          </div>

                          <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                            <table className="min-w-full table-auto divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                {table3_2Headers.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-4 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </thead>
                              <tbody>
                                {Object.keys(
                                  this.state.gstr3bDetails?.tb32Composition
                                ).map((key: any) => {
                                  return (
                                    <tr className="bg-white">
                                      <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {this.state.gstr3bDetails
                                          ?.tb32Composition[key].pos
                                          ? getState(
                                              this.state.gstr3bDetails
                                                ?.tb32Composition[key].pos
                                            )
                                          : "-"}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails
                                            ?.tb32Composition[key].taxableAmount
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails
                                            ?.tb32Composition[key].igst
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                      {this.state.gstr3bDetails?.tb32UIN &&
                      this.state.gstr3bDetails?.tb32UIN.length > 0 ? (
                        <div>
                          <div className="bg-white overflow-hidden shadow sm:rounded-none-lg">
                            <div className="px-4 py-5 pt whitespace-nowrap text-sm font-medium text-gray-600">
                              Supplies made to UIN Holders
                            </div>
                          </div>

                          <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                            <table className="min-w-full table-auto divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                {table3_2Headers.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-4 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </thead>
                              <tbody>
                                {Object.keys(
                                  this.state.gstr3bDetails?.tb32UIN
                                ).map((key: any) => {
                                  return (
                                    <tr className="bg-white">
                                      <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {this.state.gstr3bDetails?.tb32UIN[key]
                                          .pos
                                          ? getState(
                                              this.state.gstr3bDetails?.tb32UIN[
                                                key
                                              ].pos
                                            )
                                          : "-"}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb32UIN[key]
                                            .taxableAmount
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb32UIN[key]
                                            .igst
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {this.state.gstr3bDetails?.tb41a ||
                  this.state.gstr3bDetails?.tb41b ||
                  this.state.gstr3bDetails?.tb41b ||
                  this.state.gstr3bDetails?.tb41d ? (
                    <div className="my-4 max-w-7xl mx-auto">
                      <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Table 4.1
                          </h3>
                        </div>
                      </div>
                      <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                        <table className="min-w-full table-auto divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            {table4_1Headers.map((header, i) => (
                              <th
                                key={i}
                                scope="col"
                                className="w-1/16 px-1 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {header}
                              </th>
                            ))}
                          </thead>
                          <tbody>
                            {this.state.gstr3bDetails?.tb41a ? (
                              <>
                                <tr className="bg-gray-50">
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                                    (A) ITC Available (whether in full or part)
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                </tr>
                                {Object.keys(
                                  this.state.gstr3bDetails?.tb41a
                                ).map((key: any) => {
                                  return (
                                    <tr className="bg-white">
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {Table4a.filter(
                                          value => value?.name === key
                                        ).map(filteredvalue => {
                                          return key === filteredvalue.name
                                            ? filteredvalue.value
                                            : null;
                                        })}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41a[key]
                                            .igst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41a[key]
                                            .cgst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41a[key]
                                            .sgst
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : null}
                            {this.state.gstr3bDetails?.tb41b ? (
                              <>
                                <tr className="bg-gray-50">
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                                    (B) ITC Reversed
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                </tr>
                                {Object.keys(
                                  this.state.gstr3bDetails?.tb41b
                                ).map((key: any) => {
                                  return (
                                    <tr className="bg-white">
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {Table4b.filter(
                                          value => value?.name === key
                                        ).map(filteredvalue => {
                                          return key === filteredvalue.name
                                            ? filteredvalue.value
                                            : null;
                                        })}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41b[key]
                                            .igst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41b[key]
                                            .cgst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41b[key]
                                            .sgst
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : null}
                            {this.state.gstr3bDetails?.tb41c ? (
                              <>
                                <tr className="bg-gray-50">
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                                    (C) Net ITC Available (A) - (B)
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                </tr>
                                <tr className="bg-white">
                                  <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                    Total
                                  </td>
                                  <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                    {convertNumber(
                                      this.state.gstr3bDetails?.tb41c.igst
                                    )}
                                  </td>
                                  <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                    {convertNumber(
                                      this.state.gstr3bDetails?.tb41c.cgst
                                    )}
                                  </td>
                                  <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                    {convertNumber(
                                      this.state.gstr3bDetails?.tb41c.sgst
                                    )}
                                  </td>
                                </tr>
                              </>
                            ) : null}
                            {this.state.gstr3bDetails?.tb41d ? (
                              <>
                                <tr className="bg-gray-50">
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                                    (D) Ineligible ITC
                                  </td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-600"></td>
                                </tr>
                                {Object.keys(
                                  this.state.gstr3bDetails?.tb41d
                                ).map((key: any) => {
                                  return (
                                    <tr className="bg-white">
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {Table4d.filter(
                                          value => value?.name === key
                                        ).map(filteredvalue => {
                                          return key === filteredvalue.name
                                            ? filteredvalue.value
                                            : null;
                                        })}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41d[key]
                                            .igst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41d[key]
                                            .cgst
                                        )}
                                      </td>
                                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.gstr3bDetails?.tb41d[key]
                                            .sgst
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                // Empty activity
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    strokeWidth={1}
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No sales and purchases record present.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by importing files.
                  </p>
                  <div className="mt-6">
                    <Link to={(this.props as any).urlInfo + "/sales/import"}>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        <Icon
                          name="outline/upload"
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        IMPORT
                      </button>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              // Empty activity
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  strokeWidth={1}
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  GSTR-3B is not required to be filed for <br />
                  first two months of quarter <br />
                  when quarterly is selected in preference.
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by importing sales files for
                  <br /> Mar, Jun, Sep and Dec months.
                </p>
                <div className="mt-6">
                  <Link to={(this.props as any).urlInfo + "/sales/import"}>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                      <Icon
                        name="outline/upload"
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      IMPORT
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            // until the data loads, show loading skeleton
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-lg">
                          <table className="table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {table3_1Headers.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-1 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(6)].map((e, i) => (
                                    <td className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default connector(GSTR3B);
