import React from "react";
import convertNumber from "../../../helpers/convertNumber";

const Invoices = [
  {
    name: "Amazon_B2B",
    value: "Invoices - B2B"
  },
  {
    name: "SEZWP",
    value: "Invoices - SEZ with payment"
  },
  {
    name: "SEZWOP",
    value: "Invoices - SEZ without payment"
  },
  {
    name: "RCM",
    value: "Invoices - Reverse Charge"
  },
  {
    name: "Amazon_B2C",
    value: "Invoices - B2C"
  },
  {
    name: "EWP",
    value: "Invoices - Exports with payment"
  },
  {
    name: "EWOP",
    value: "Invoices - Exports without payment"
  }
];

const invoiceHeaders = [
  "Date",
  "Invoice Number",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "CGST",
  "SGST/UTGST",
  "Invoice Value"
];

const invoiceSezwpHeaders = [
  "Date",
  "Invoice Number",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "Invoice Value"
];

const invoiceSezwopHeaders = [
  "Date",
  "Invoice Number",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "Invoice Value"
];

const invoiceEwpHeaders = [
  "Date",
  "Invoice Number",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "Invoice Value",
  "Port Code",
  "Shipping Bill No./Bill of Export No.",
  "Shipping Bill Date/Bill of Export Date"
];

const invoiceEwopHeaders = [
  "Date",
  "Invoice Number",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "Invoice Value",
  "Port Code",
  "Shipping Bill No./Bill of Export No.",
  "Shipping Bill Date/Bill of Export Date"
];

type Props = { state?: any };

//Main Classs
class InvoiceTables extends React.Component<Props> {
  render() {
    return (
      <>
        {(this.props as any).state.gstrDetails?.invoices
          ? (this.props as any).state.gstrDetails.invoices.map((Invoice: any) =>
              Invoice.invoices?.length > 0 ? (
                <div className="my-4 max-w-7xl mx-auto">
                  <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {Invoices.filter(
                          invoice => invoice?.name === Invoice.name
                        ).map(filteredinvoice => {
                          return Invoice.name === filteredinvoice.name
                            ? filteredinvoice.value
                            : null;
                        })}
                      </h3>
                    </div>
                  </div>
                  <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                    <table className="min-w-full table-auto divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {Invoice.name === "EWOP"
                            ? invoiceEwopHeaders.map(invoiceEwopHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {invoiceEwopHeader}
                                </th>
                              ))
                            : Invoice.name === "EWP"
                            ? invoiceEwpHeaders.map(invoiceEwpHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {invoiceEwpHeader}
                                </th>
                              ))
                            : Invoice.name === "SEZWOP"
                            ? invoiceSezwopHeaders.map(invoiceSezwopHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {invoiceSezwopHeader}
                                </th>
                              ))
                            : Invoice.name === "SEZWP"
                            ? invoiceSezwpHeaders.map(invoiceSezwpHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {invoiceSezwpHeader}
                                </th>
                              ))
                            : invoiceHeaders.map(invoiceHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {invoiceHeader}
                                </th>
                              ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Invoice.invoices.map((row: any) => (
                          <tr className="bg-white">
                            <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                              {row.date
                                ? new Date(row.date).toLocaleDateString()
                                : "-"}
                            </td>
                            <td className="pl-6 pr-4 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                              {row.invoiceNumber ? row.invoiceNumber : "-"}
                            </td>
                            {Invoice.name === "EWOP" ||
                            Invoice.name === "EWP" ? null : (
                              <td className="px-4 py-4 whitespace-wrap text-sm text-gray-500">
                                {row.clientGstin ? row.clientGstin : "-"}
                              </td>
                            )}
                            <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                              {row.partyName ? row.partyName : "-"}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {row.gstRate ? row.gstRate : 0}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {convertNumber(row.taxableAmount)}
                            </td>
                            {Invoice.name === "EWOP" ||
                            Invoice.name === "SEZWOP" ? null : (
                              <>
                                <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                  {convertNumber(row.igstAmt)}
                                </td>
                                {Invoice.name === "EWP" ||
                                Invoice.name === "SEZWP" ? null : (
                                  <>
                                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                      {convertNumber(row.cgstAmt)}
                                    </td>
                                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                      {convertNumber(row.utgstOrSgstAmt)}
                                    </td>
                                  </>
                                )}
                              </>
                            )}
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {convertNumber(row.invoiceAmount)}
                            </td>
                            {Invoice.name === "EWP" ||
                            Invoice.name === "EWOP" ? (
                              <>
                                <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                  {row.portCode ? row.portCode : "-"}
                                </td>
                                <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                  {row.shippingBillNumber
                                    ? row.shippingBillNumber
                                    : "-"}
                                </td>
                                <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                  {row.shippingBillDate
                                    ? new Date(
                                        row.shippingBillDate
                                      ).toLocaleDateString()
                                    : "-"}
                                </td>
                              </>
                            ) : null}
                          </tr>
                        ))}
                        <tr className="bg-gray-50">
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                            Total
                          </td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          {Invoice.name === "EWOP" ||
                          Invoice.name === "EWP" ? null : (
                            <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          )}
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                            {convertNumber(Invoice.total?.taxableAmount)}
                          </td>
                          {Invoice.name === "EWOP" ||
                          Invoice.name === "SEZWOP" ? null : (
                            <>
                              <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                {convertNumber(Invoice.total?.igstAmt)}
                              </td>
                              {Invoice.name === "EWP" ||
                              Invoice.name === "SEZWP" ? null : (
                                <>
                                  <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                    {convertNumber(Invoice.total.cgstAmt)}
                                  </td>
                                  <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                    {convertNumber(
                                      Invoice.total.utgstOrSgstAmt
                                    )}
                                  </td>
                                </>
                              )}
                            </>
                          )}
                          <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                            {convertNumber(Invoice.total.invoiceAmount)}
                          </td>
                          {Invoice.name === "EWP" || Invoice.name === "EWOP" ? (
                            <>
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                            </>
                          ) : null}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null
            )
          : null}
      </>
    );
  }
}

export default InvoiceTables;
