import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Dashboard from "../../../components/Dashboard";
import MultiSelect from "../../../components/MultiSelect";
import periods from "../../../constants/periods";
import agent from "../../../agent";
import { downloadFile } from "../../../helpers/downloadFile";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../../store/types";
import Icon from "../../../components/Icon";

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class SummaryReport extends React.Component<
  RouteComponentProps,
  PropsFromRedux
> {
  state: {
    loading: boolean;
    logging: boolean;
    selectedType: any;
    selectedPeriod: any;
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      logging: false,
      selectedType: periods[0],
      selectedPeriod: ""
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  downloadreport = () => {
    let month = (this.props as any).currentMonth;
    let year = (this.props as any).currentYear;
    let gstId = (this.props as any).currentGst._id;
    let periodType =
      this.state.selectedType.name === "Monthly"
        ? "month"
        : this.state.selectedType.name === "Quarterly"
        ? "quarter"
        : this.state.selectedType.name === "Yearly"
        ? "year"
        : "";
    this.setState({ loading: true, logging: true });
    agent.Report.Summary(month, year, gstId, periodType)
      .then((response: any) => {
        downloadFile(
          response,
          `GST Summary - ${this.state.selectedType.name} - ${
            this.state.selectedType.name === "Monthly"
              ? (this.props as any).currentMonth +
                " - " +
                (this.props as any).currentYear
              : this.state.selectedType.name === "Yearly"
              ? (this.props as any).currentYear
              : (this.props as any).currentMonth === "Apr" ||
                (this.props as any).currentMonth === "May" ||
                (this.props as any).currentMonth === "Jun"
              ? "Apr-Jun - " + (this.props as any).currentYear
              : (this.props as any).currentMonth === "Jul" ||
                (this.props as any).currentMonth === "Aug" ||
                (this.props as any).currentMonth === "Sep"
              ? "Jul-Sep - " + (this.props as any).currentYear
              : (this.props as any).currentMonth === "Oct" ||
                (this.props as any).currentMonth === "Nov" ||
                (this.props as any).currentMonth === "Dec"
              ? "Oct-Dec - " + (this.props as any).currentYear
              : "Jan-Mar - " + (this.props as any).currentYear
          }.xlsx`
        );
        (this.props as any).onNotify(
          "Download",
          "Report has been successfully exported in excel",
          "success"
        );
        this.setState({
          loading: false,
          logging: false,
          exportIcon: "outline/download"
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false, exportIcon: "outline/download" });
        (this.props as any).onNotify(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  handleType = (value: any) => {
    this.setState({ selectedType: value });
  };

  /* handlePeriod = (value: any) => {
    this.setState({ selectedPeriod: value });
  };
 */
  render() {
    return (
      <Dashboard match={this.props.match}>
        <h1 className="text-2xl font-semibold text-gray-900">
          GST Summary Report
        </h1>
        <div className="grid grid-cols-4 grid-rows-3 grid-flow-col gap-x-8 gap-y-4 px-4 sm:px-6 md:px-0 pt-8 pb-2">
          <div>
            <MultiSelect
              placeholder="Select Type"
              items={periods}
              selected={this.state.selectedType}
              onChange={this.handleType}
            ></MultiSelect>
          </div>
          <div className="ml-1 -mt-3 col-span-2 text-sm text-gray-500">
            Note - Report for{" "}
            {this.state.selectedType.name === "Monthly"
              ? (this.props as any).currentMonth +
                " " +
                ((this.props as any).currentMonth === "Jan" ||
                (this.props as any).currentMonth === "Feb" ||
                (this.props as any).currentMonth === "Mar"
                  ? (this.props as any).currentYear?.slice(5, 7) + " "
                  : (this.props as any).currentYear?.slice(2, 4) + " ")
              : this.state.selectedType.name === "Yearly"
              ? (this.props as any).currentYear + " "
              : (this.props as any).currentMonth === "Apr" ||
                (this.props as any).currentMonth === "May" ||
                (this.props as any).currentMonth === "Jun"
              ? "Apr-Jun " + (this.props as any).currentYear?.slice(2, 4) + " "
              : (this.props as any).currentMonth === "Jul" ||
                (this.props as any).currentMonth === "Aug" ||
                (this.props as any).currentMonth === "Sep"
              ? "Jul-Sep " + (this.props as any).currentYear?.slice(2, 4) + " "
              : (this.props as any).currentMonth === "Oct" ||
                (this.props as any).currentMonth === "Nov" ||
                (this.props as any).currentMonth === "Dec"
              ? "Oct-Dec " + (this.props as any).currentYear?.slice(2, 4) + " "
              : "Jan-Mar " + (this.props as any).currentYear?.slice(5, 7) + " "}
            will be downloaded
          </div>

          {/* 
          This dropdown is showing the month, quarter and year on the basis of above dropdown. It is not required in this report.<div>
            <MultiSelect
              placeholder={
                this.state.selectedType.name === "Monthly"
                  ? "Select Month"
                  : this.state.selectedType.name === "Quarterly"
                  ? "Select Quarter"
                  : "Select Year"
              }
              items={
                this.state.selectedType.name === "Monthly"
                  ? months
                  : this.state.selectedType.name === "Quarterly"
                  ? quarters
                  : years
              }
              selected={this.state.selectedPeriod}
              onChange={this.handlePeriod}
            ></MultiSelect> </div>*/}

          <div className="-mt-9">
            <button
              type="button"
              disabled={this.state.selectedType === "" || this.state.logging}
              onClick={() => this.downloadreport()}
              className={
                this.state.selectedType === "" || this.state.logging
                  ? "relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-300 cursor-not-allowed"
                  : "relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
              }
            >
              {this.state.logging ? <Icon name="loading" /> : null}
              Download Report
            </button>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default connector(SummaryReport);
