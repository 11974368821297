import React from "react";
//Redux Notifications
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import Icon from "../../components/Icon";
import routes from "../../constants/routes";
import validGSTIN from "../../helpers/GSTValidationFunction";
import { ADD_GST, ADD_NOTIFICATION } from "../../store/types";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstadd"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addGst: (gst: any) =>
    dispatch({
      type: ADD_GST,
      payload: { gst }
    }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

//Main Classs
class AddClient extends React.Component<RouteComponentProps, PropsFromRedux> {
  state: { GSTN: string; logging: boolean };

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      GSTN: "",
      logging: false
    };
  }

  addGst = () => {
    let isValid = validGSTIN(this.state.GSTN);
    if (isValid) {
      this.setState({ logging: true });
      agent.Gst.addGst(this.state.GSTN)
        .then((response: any) => {
          this.setState({ logging: false });
          this.props.history.push(
            (this.props as any).urlInfo + routes.AgencyClients
          );
          (this.props as any).addGst(response.gst);
          (this.props as any).onNotify(
            "Added GSTN",
            "Successfully added new GSTN.",
            "success"
          );
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).onNotify(
            "Could not get GSTN Data",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      (this.props as any).onNotify(
        "Incorrect GSTN",
        "Please enter a valid GSTIN to proceed.",
        "danger"
      );
    }
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: ev.target.value.trim()
    });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 gstadd">
          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Add Client
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Add the GST number of the client
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form>
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company_website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            GSTN
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="company_website"
                              value={this.state.GSTN}
                              onChange={this.updateState("GSTN")}
                              id="company_website"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              placeholder="08AABCU9603R1ZN"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="button"
                        onClick={this.addGst}
                        disabled={this.state.logging}
                        className={
                          this.state.logging
                            ? "inline-flex disabled justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                            : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                        }
                      >
                        {this.state.logging ? <Icon name="loading" /> : null}
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default connector(AddClient);
