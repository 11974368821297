import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Dashboard from "../../../components/Dashboard";
import agent from "../../../agent";
import { ADD_NOTIFICATION } from "../../../store/types";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import fileDownloader from "js-file-download";
import Icon from "../../../components/Icon";
import NilRatedSupplies from "./nillRated";
import B2CDetails from "./b2c";
import CreditTables from "./credits";
import InvoiceTables from "./invoices";
import TagManager from "react-gtm-module";
import FilingFreqency from "../../../helpers/filingFrequency";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstr1"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const invoiceHeaders = [
  "Date",
  "Invoice Number",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "CGST",
  "SGST/UTGST",
  "Invoice Value"
];

//const validMonths = ["Mar", "Jun", "Sep", "Dec"];

const types = ["Invoices", "Notes"];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class GSTR1Summary extends React.Component<
  RouteComponentProps,
  PropsFromRedux
> {
  state: { loading: boolean; gstrDetails: any };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      gstrDetails: {}
    };
  }

  componentDidMount = () => {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  };

  componentDidUpdate(prevProps: any) {
    if (
      (prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear) !== (this.props as any).currentYear
    ) {
      if (
        FilingFreqency.findFilingFreq(
          (this.props as any).currentGst.filingFrequency,
          (this.props as any).currentMonth,
          (this.props as any).currentYear
        ) === "Quarterly"
      ) {
        (this.props as any).currentMonth === "Mar" ||
        (this.props as any).currentMonth === "Jun" ||
        (this.props as any).currentMonth === "Sep" ||
        (this.props as any).currentMonth === "Dec"
          ? this.onLoad()
          : this.displayInvalidMonth();
      } else if (
        FilingFreqency.findFilingFreq(
          (this.props as any).currentGst.filingFrequency,
          (this.props as any).currentMonth,
          (this.props as any).currentYear
        ) === "Monthly"
      ) {
        (this.props as any).currentMonth === "Jan" ||
        (this.props as any).currentMonth === "Feb" ||
        (this.props as any).currentMonth === "Mar" ||
        (this.props as any).currentMonth === "Apr" ||
        (this.props as any).currentMonth === "May" ||
        (this.props as any).currentMonth === "Jun" ||
        (this.props as any).currentMonth === "Jul" ||
        (this.props as any).currentMonth === "Aug" ||
        (this.props as any).currentMonth === "Sep" ||
        (this.props as any).currentMonth === "Oct" ||
        (this.props as any).currentMonth === "Nov" ||
        (this.props as any).currentMonth === "Dec"
          ? this.onLoad()
          : this.displayInvalidMonth();
      }
    }
  }

  displayInvalidMonth = () => {
    this.setState({
      gstrDetails: [],
      loading: false
    });
  };

  validMonths = () => {
    let validMonths =
      FilingFreqency.findFilingFreq(
        (this.props as any).currentGst.filingFrequency,
        (this.props as any).currentMonth,
        (this.props as any).currentYear
      ) === "Quarterly"
        ? ["Mar", "Jun", "Sep", "Dec"]
        : [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ];
    return validMonths;
  };
  /* dynamicValidMonths = () => {
    let year: string = (this.props as any).currentYear;
    let chosenQuarter: any = {
      Jan: "Q4",
      Feb: "Q4",
      Mar: "Q4",
      Apr: "Q1",
      May: "Q1",
      Jun: "Q1",
      Jul: "Q2",
      Aug: "Q2",
      Sep: "Q2",
      Oct: "Q3",
      Nov: "Q3",
      Dec: "Q3"
    };
    let month: any = (this.props as any).currentMonth;
    let filingFrequency: any = (this.props as any).currentGst.filingFrequency;

    filingFrequency = filingFrequency.find(
      (quarter: any) =>
        quarter.quarter === chosenQuarter[month] && quarter.year === year
    );

    let validMonths: any = [];
    if (filingFrequency.preference === "Q") {
      validMonths = ["Mar", "Jun", "Sep", "Dec"];
    } else if (filingFrequency.preference === "M") {
      validMonths = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
    } else {
      validMonths = [];
    }
    return validMonths;
  }; */

  onLoad = () => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      agent.Returns.gstr1Data(month, year, gstId)
        .then((response: any) => {
          this.setState({
            gstrDetails: response,
            loading: false
          });
        })
        .catch((err: any) => {
          this.setState({
            gstrDetails: {},
            loading: false
          });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  handleClick = () => {
    let gstId: string = (this.props as any).currentGst?._id;
    let year: string = (this.props as any).currentYear;
    let month: string = (this.props as any).currentMonth;
    agent.Returns.gstr1Json(month, year, gstId)
      .then((data: any) => {
        let res = JSON.stringify(data);
        let clientName = (this.props as any).currentGst?.lgnm;
        let fileName =
          "GSTR - 1 " +
          clientName.slice(0, 40) +
          " " +
          month +
          " " +
          year +
          ".json";
        fileDownloader(res, fileName);
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  render() {
    console.log("rohitnew", this.props);
    console.log("rohit", this.validMonths());
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="gstr1">
          <div>
            <h1 className="inline-block text-2xl font-semibold text-gray-900 mb-4">
              GSTR - 1
            </h1>
            <button
              type="button"
              onClick={this.handleClick}
              className="float-right inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              Generate JSON
            </button>
          </div>
          {!this.state.loading ? (
            this.validMonths().includes((this.props as any).currentMonth) ? (
              this.state.gstrDetails &&
              (this.state.gstrDetails.invoices ||
                this.state.gstrDetails.credits) ? (
                <>
                  {Object.keys(types).map((type: any) => {
                    return (
                      <>
                        {types[type] === "Invoices" ? (
                          <InvoiceTables state={this.state} />
                        ) : (
                          <CreditTables state={this.state} />
                        )}
                      </>
                    );
                  })}

                  <B2CDetails state={this.state} />

                  <NilRatedSupplies state={this.state} />
                </>
              ) : (
                // Empty activity
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    strokeWidth={1}
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No sales record present.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by importing sales files for Mar, Jun, Sep and
                    Dec months.
                  </p>
                  <div className="mt-6">
                    <Link to={(this.props as any).urlInfo + "/sales/import"}>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        <Icon
                          name="outline/upload"
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        IMPORT
                      </button>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              // Empty activity
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  strokeWidth={1}
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  GSTR-1 is not applicable for first two months of quarter. You
                  have to file IFF instead.
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by importing files for Mar, Jun, Sep and Dec
                  months.
                </p>
                <div className="mt-6">
                  <Link to={(this.props as any).urlInfo + "/sales/import"}>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                      <Icon
                        name="outline/upload"
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      IMPORT
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            // until the data loads, show loading skeleton
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-lg">
                          <table className="table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {invoiceHeaders.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(10)].map((e, i) => (
                                    <td className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default connector(GSTR1Summary);
