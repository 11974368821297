import React from "react";
import convertNumber from "../../../helpers/convertNumber";

const Notes = [
  {
    name: "Amazon_B2B",
    value: "Credit Notes - B2B"
  },
  {
    name: "SEZWP",
    value: "Credit Notes - SEZ with payment"
  },
  {
    name: "SEZWOP",
    value: "Credit Notes - SEZ without payment"
  },
  {
    name: "RCM",
    value: "Credit Notes - Reverse Charge"
  },
  {
    name: "Amazon_B2C",
    value: "Credit Notes - B2C"
  },
  {
    name: "EWP",
    value: "Credit Notes - Exports with payment"
  },
  {
    name: "EWOP",
    value: "Credit Note - Exports without payment"
  }
];

const noteHeaders = [
  "Date",
  "Credit Note No.",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "CGST",
  "SGST/UTGST",
  "Note Value"
];

const creditSezwpHeaders = [
  "Date",
  "Credit Note No.",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "Note Value"
];

const creditSezwopHeaders = [
  "Date",
  "Credit Note No.",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "Note Value"
];

const creditEwpHeaders = [
  "Date",
  "Credit Note No.",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "Note Value",
  "Port Code",
  "Shipping Bill No./Bill of Export No.",
  "Shipping Bill Date/Bill of Export Date"
];

const creditEwopHeaders = [
  "Date",
  "Credit Number",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "Invoice Value",
  "Port Code",
  "Shipping Bill No./Bill of Export No.",
  "Shipping Bill Date/Bill of Export Date"
];

type Props = { state?: any };

//Main Classs
class CreditTables extends React.Component<Props> {
  render() {
    return (
      <>
        {(this.props as any).state.gstrDetails?.credits
          ? (this.props as any).state.gstrDetails.credits.map((Note: any) =>
              Note.credits?.length > 0 ? (
                <div className="my-4 max-w-7xl mx-auto">
                  <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                    <div className="px-5 py-5 sm:p-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {Notes.filter(notes => notes.name === Note.name).map(
                          filteredNotes => {
                            return Note.name === filteredNotes.name
                              ? filteredNotes.value
                              : null;
                          }
                        )}
                      </h3>
                    </div>
                  </div>
                  <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                    <table className="min-w-full table-auto divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {Note.name === "EWOP"
                            ? creditEwopHeaders.map(creditEwopHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {creditEwopHeader}
                                </th>
                              ))
                            : Note.name === "EWP"
                            ? creditEwpHeaders.map(creditEwpHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {creditEwpHeader}
                                </th>
                              ))
                            : Note.name === "SEZWOP"
                            ? creditSezwopHeaders.map(creditSezwopHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {creditSezwopHeader}
                                </th>
                              ))
                            : Note.name === "SEZWP"
                            ? creditSezwpHeaders.map(creditSezwpHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {creditSezwpHeader}
                                </th>
                              ))
                            : noteHeaders.map(noteHeader => (
                                <th
                                  scope="col"
                                  className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {noteHeader}
                                </th>
                              ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Note.credits.map((row: any) => (
                          <tr className="bg-white">
                            <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                              {row.date
                                ? new Date(row.date).toLocaleDateString()
                                : "-"}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                              {row.invoiceNumber ? row.invoiceNumber : "-"}
                            </td>
                            {Note.name === "EWOP" ||
                            Note.name === "EWP" ? null : (
                              <td className="px-4 py-4 whitespace-wrap text-sm text-gray-500">
                                {row.clientGstin ? row.clientGstin : "-"}
                              </td>
                            )}
                            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                              {row.partyName ? row.partyName : "-"}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                              {row.gstRate ? row.gstRate : 0}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                              {convertNumber(row.taxableAmount)}
                            </td>
                            {Note.name === "EWOP" ||
                            Note.name === "SEZWOP" ? null : (
                              <>
                                <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                  {convertNumber(row.igstAmt)}
                                </td>
                                {Note.name === "EWP" ||
                                Note.name === "SEZWP" ? null : (
                                  <>
                                    <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                      {convertNumber(row.cgstAmt)}
                                    </td>
                                    <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                      {convertNumber(row.utgstOrSgstAmt)}
                                    </td>
                                  </>
                                )}
                              </>
                            )}
                            <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                              {convertNumber(row.invoiceAmount)}
                            </td>
                            {Note.name === "EWP" || Note.name === "EWOP" ? (
                              <>
                                <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                  {row.portCode ? row.portCode : "-"}
                                </td>
                                <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                  {row.shippingBillNumber
                                    ? row.shippingBillNumber
                                    : "-"}
                                </td>
                                <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                  {row.shippingBillDate
                                    ? new Date(
                                        row.shippingBillDate
                                      ).toLocaleDateString()
                                    : "-"}
                                </td>
                              </>
                            ) : null}
                          </tr>
                        ))}
                        <tr className="bg-gray-50">
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                            Total
                          </td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          {Note.name === "EWOP" ||
                          Note.name === "EWP" ? null : (
                            <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          )}
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                            {convertNumber(Note.total.taxableAmount)}
                          </td>
                          {Note.name === "EWOP" ||
                          Note.name === "SEZWOP" ? null : (
                            <>
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                {convertNumber(Note.total.igstAmt)}
                              </td>
                              {Note.name === "EWP" ||
                              Note.name === "SEZWP" ? null : (
                                <>
                                  <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                    {convertNumber(Note.total.cgstAmt)}
                                  </td>
                                  <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                    {convertNumber(Note.total.utgstOrSgstAmt)}
                                  </td>
                                </>
                              )}
                            </>
                          )}
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                            {convertNumber(Note.total.invoiceAmount)}
                          </td>
                          {Note.name === "EWP" || Note.name === "EWOP" ? (
                            <>
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                              {Note.name === "EWOP" ? (
                                <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                              ) : null}
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                            </>
                          ) : null}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null
            )
          : null}
      </>
    );
  }
}

export default CreditTables;
