import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Dashboard from "../../../../components/Dashboard";
import agent from "../../../../agent";
import { ADD_NOTIFICATION } from "../../../../store/types";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { ViewGridAddIcon, UploadIcon } from "@heroicons/react/outline";
import convertNumber from "../../../../helpers/convertNumber";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstr2summary"
  },
  dataLayerName: "PageDataLayer"
};

const items = [
  {
    name: "Add Your GST Number",
    description: "Start Getting Insights by adding a GST Number.",
    href: "/gsts/add",
    iconCss:
      "bg-pink-500 inline-flex items-center justify-center h-10 w-10 rounded-lg",
    icon: ViewGridAddIcon
  },
  {
    name: "Import GSTR-2A/GSTR-2B Transaction Files",
    description: "Start by importing GSTR-2A/GSTR-2B transaction files.",
    href: "./import",
    iconCss:
      "bg-purple-500 inline-flex items-center justify-center h-10 w-10 rounded-lg",
    icon: UploadIcon
  }
];

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const Gstr2aFields: { [key: string]: string } = {
  Gstr2aAmended: "Amended (GSTR - 2A)",
  Gstr2aAmendedReverseCharge: "Amended Reverse Charge (GSTR - 2A)",
  Gstr2aCreditNotes: "Credit Notes (GSTR - 2A)",
  Gstr2aCreditNotesAmended: "Credit Notes - Amended (GSTR - 2A)",
  Gstr2aCreditNotesAmendedReverseCharge:
    "Credit Notes - Amended - Reverse Charge (GSTR - 2A)",
  Gstr2aCreditNotesReverseCharge: "Credit Notes - Reverse Charge (GSTR - 2A)",
  Gstr2aDebitNotes: "Debit Notes (GSTR - 2A)",
  Gstr2aDebitNotesAmended: "Debit Notes - Amended (GSTR - 2A)",
  Gstr2aDebitNotesAmendedReverseCharge:
    "Debit Notes - Amended - Reverse Charge (GSTR - 2A)",
  Gstr2aDebitNotesReverseCharge: "Debit Notes - Reverse Charge (GSTR - 2A)",
  Gstr2aImpg: "IMPG (GSTR - 2A)",
  Gstr2aImpgAmended: "IMPG - Amended (GSTR - 2A)",
  Gstr2aImpgSez: "IMPG SEZ (GSTR - 2A)",
  Gstr2aImpgSezAmended: "IMPG SEZ - Amended (GSTR - 2A)",
  Gstr2aInvoices: "Invoices (GSTR - 2A)",
  Gstr2aInvoicesReverseCharge: "Invoices - Reverse Charge (GSTR - 2A)",
  Gstr2aTcs: "TCS (GSTR - 2A)"
};
const Gstr2bFields: { [key: string]: string } = {
  Gstr2bAmended: "Amended (GSTR - 2B)",
  Gstr2bAmendedReverseCharge: "Amended - Reverse Charge (GSTR - 2B)",
  Gstr2bCreditNotes: "Credit Notes (GSTR - 2B)",
  Gstr2bCreditNotesAmended: "Credit Notes - Amended (GSTR - 2B)",
  Gstr2bCreditNotesAmendedReverseCharge:
    "Credit Notes - Amended - Reverse Charge (GSTR - 2B)",
  Gstr2bCreditNotesReverseCharge: "Credit Notes (GSTR - 2B)",
  Gstr2bDebitNotes: "Debit Notes (GSTR - 2B)",
  Gstr2bDebitNotesAmended: "Debit Notes - Amended (GSTR - 2B)",
  Gstr2bDebitNotesAmendedReverseCharge:
    "Debit Notes - Amended - Reverse Charge (GSTR - 2B)",
  Gstr2bDebitNotesReverseCharge: "Debit Notes - Reverse Charge (GSTR - 2B)",
  Gstr2bImpg: "IMPG (GSTR - 2B)",
  Gstr2bImpgAmended: "IMPG - Amended (GSTR - 2B)",
  Gstr2bImpgSez: "IMPG SEZ (GSTR - 2B)",
  Gstr2bImpgSezAmended: "IMPG SEZ - Amended (GSTR - 2B)",
  Gstr2bInvoices: "Invoices (GSTR - 2B)",
  Gstr2bInvoicesReverseCharge: "Invoices - Reverse Charge (GSTR - 2B)",
  Gstr2bTcs: "TCS (GSTR - 2B)"
};

const types = ["ITC Available", "ITC Not Available"];

const headers2a = ["Taxable Amount", "IGST", "CGST", "SGST"];

const headers2b = [
  "Transaction Type",
  "Taxable Amount",
  "IGST",
  "CGST",
  "SGST"
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Summary extends React.Component<RouteComponentProps, PropsFromRedux> {
  state: { loading: boolean; summaryDetails: any };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      summaryDetails: {}
    };
  }

  componentDidMount() {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  }

  //Changed logic for stopping API multiple time
  /* componentDidUpdate(prevProps: any) {
    if (
      (prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear) !== (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  } */

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentGst !== "")
      if (
        prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear !== (this.props as any).currentYear
      ) {
        this.onLoad();
      }
  }

  onLoad = () => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      agent.Returns.getGstr2Summary(month, year, gstId)
        .then((response: any) => {
          this.setState({
            summaryDetails: response,
            loading: false
          });
          Object.keys(this.state.summaryDetails).map(key => {
            return this.setState({
              summaryDetails: this.state.summaryDetails[key],
              types: this.state.summaryDetails[key]
            });
          });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="gstr2summary">
          <h1 className="text-2xl font-semibold text-gray-900 mb-4">
            GSTR - 2 Summary
          </h1>
          {!this.state.loading ? (
            this.state.summaryDetails &&
            this.state.summaryDetails.length > 0 ? (
              <>
                {Object.keys(this.state.summaryDetails[0])?.map(
                  (entry: any) => {
                    return (
                      <div className="my-5">
                        {this.state.summaryDetails[0][entry][0] ? (
                          Object.keys(Gstr2aFields).includes(entry) ? (
                            <>
                              <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                                <div className="px-4 py-5 sm:p-6">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {Gstr2aFields[entry]}
                                  </h3>
                                </div>
                              </div>
                              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      {headers2a.map(header => (
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                          {header}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="bg-white">
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.summaryDetails[0][entry][0]
                                            ?.taxableAmount
                                        )}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.summaryDetails[0][entry][0]
                                            ?.igst
                                        )}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.summaryDetails[0][entry][0]
                                            ?.cgst
                                        )}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {convertNumber(
                                          this.state.summaryDetails[0][entry][0]
                                            ?.sgst
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : null
                        ) : null}
                        {this.state.summaryDetails[0][entry].itc &&
                        this.state.summaryDetails[0][entry].notItc ? (
                          this.state.summaryDetails[0][entry].itc[0] ||
                          this.state.summaryDetails[0][entry].notItc[0] ? (
                            Object.keys(Gstr2bFields).includes(entry) ? (
                              <>
                                <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                                  <div className="px-4 py-5 sm:p-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                      {Gstr2bFields[entry]}
                                    </h3>
                                  </div>
                                </div>
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg">
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        {headers2b.map(header => (
                                          <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            {header}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {types.map(type => (
                                        <tr
                                          className={
                                            type === "Net Purchase"
                                              ? "bg-gray-50"
                                              : "bg-white"
                                          }
                                        >
                                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {type}
                                          </td>
                                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {type === "ITC Available"
                                              ? convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ]?.itc[0]?.taxableAmount
                                                )
                                              : convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ]?.notItc[0]?.taxableAmount
                                                )}
                                          </td>
                                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {type === "ITC Available"
                                              ? convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ]?.itc[0]?.igst
                                                )
                                              : convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ]?.notItc[0]?.igst
                                                )}
                                          </td>
                                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {type === "ITC Available"
                                              ? convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ].itc[0]?.cgst
                                                )
                                              : convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ]?.notItc[0]?.cgst
                                                )}
                                          </td>
                                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {type === "ITC Available"
                                              ? convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ]?.itc[0]?.sgst
                                                )
                                              : convertNumber(
                                                  this.state.summaryDetails[0][
                                                    entry
                                                  ]?.notItc[0]?.sgst
                                                )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ) : null
                          ) : null
                        ) : null}
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <div className="max-w-lg my-20 mx-auto">
                <h2 className="text-lg font-medium text-gray-900">
                  No GSTR-2A/GSTR-2B entries present.
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Get Your First GSTR - 2 Summary by performing the following
                  action
                </p>
                <ul className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
                  {((this.props as any).currentGst
                    ? items.filter(
                        i =>
                          i.name === "Import GSTR-2A/GSTR-2B Transaction Files"
                      )
                    : items.filter(i => i.name === "Add Your GST Number")
                  ).map((item, itemIdx) => (
                    <li key={itemIdx}>
                      <div className="relative group py-4 flex items-start space-x-3">
                        <div className="flex-shrink-0">
                          <span className={item.iconCss}>
                            <item.icon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-medium text-gray-900">
                            <Link to={item.href}>
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </div>
                          <p className="text-sm text-gray-500">
                            {item.description}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )
          ) : (
            // until the data loads, show loading skeleton
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {headers2a.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(6)].map((e, i) => (
                                    <td className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default connector(Summary);
