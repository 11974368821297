import React from "react";
import getState from "../../../constants/states";
import convertNumber from "../../../helpers/convertNumber";
const b2cHeaders = [
  "Place of Supply",
  "Taxable Amount",
  "GST Rate",
  "IGST",
  "CGST",
  "SGST/UTGST"
];

type Props = { state?: any };

//Main Classs
class B2CDetails extends React.Component<Props> {
  render() {
    return (
      <>
        {(this.props as any).state.gstrDetails?.B2C?.b2c &&
        (this.props as any).state.gstrDetails?.B2C?.b2c.length > 0 ? (
          <div className="my-4 max-w-7xl mx-auto">
            <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  B2C
                </h3>
              </div>
            </div>
            <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
              <table className="min-w-full table-auto divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {b2cHeaders.map(b2cHeader => (
                      <th
                        scope="col"
                        className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {b2cHeader}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(this.props as any).state.gstrDetails.B2C.b2c.map(
                    (row: any) => (
                      <tr className="bg-white">
                        <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                          {row.pos ? getState(row.pos) : "-"}
                        </td>
                        <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                          {convertNumber(row.taxableAmount)}
                        </td>
                        <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                          {row.gstRate ? row.gstRate : 0}
                        </td>
                        <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                          {convertNumber(row.igst)}
                        </td>
                        <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                          {convertNumber(row.cgst)}
                        </td>
                        <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                          {convertNumber(row.sgst)}
                        </td>
                      </tr>
                    )
                  )}
                  <tr className="bg-gray-50">
                    <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                      Total
                    </td>
                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                      {convertNumber(
                        (this.props as any).state.gstrDetails.B2C.b2cTotal
                          .taxableAmount
                      )}
                    </td>
                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500"></td>
                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                      {convertNumber(
                        (this.props as any).state.gstrDetails.B2C.b2cTotal.igst
                      )}
                    </td>
                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                      {convertNumber(
                        (this.props as any).state.gstrDetails.B2C.b2cTotal.cgst
                      )}
                    </td>
                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                      {convertNumber(
                        (this.props as any).state.gstrDetails.B2C.b2cTotal.sgst
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default B2CDetails;
