import axios, { CancelTokenSource } from "axios";
import config from "./config";
import { dispatch } from "./store";

axios.defaults.baseURL = config.baseURL;

let token: string | undefined;

const sources = new Map<string, CancelTokenSource>();

type RequestMethod = "get" | "getBlob" | "post" | "put" | "delete";

export const cancelRequest = (
  method: RequestMethod,
  url: string,
  data?: any
) => {
  const key = method + url + JSON.stringify(data);
  if (sources.has(key)) {
    sources.get(key)!.cancel("Request Cancelled");
    sources.delete(key);
  }
};

const createRequest = (method: RequestMethod, url: string, data?: any) => {
  const key = method + url + JSON.stringify(data);

  if (sources.has(key)) {
    sources.get(key)!.cancel("Operation cancelled due to new request.");
  }

  // Create a new CancelToken
  const source = axios.CancelToken.source();
  sources.set(key, source);

  const headers = token
    ? { headers: { Authorization: `Bearer ${token}` } }
    : {};

  const config = { cancelToken: source.token, ...headers };

  switch (method) {
    case "get":
    case "delete":
      return axios[method](url, config)
        .then(res => res?.data)
        .catch(err => {
          if (
            err.message === "Operation cancelled due to new request." ||
            err.message === "Request Cancelled"
          ) {
            return;
          }
        });
    case "getBlob":
      return axios
        .get(url, config)
        .then(res => res)
        .catch(err => {
          if (
            err.message === "Operation cancelled due to new request." ||
            err.message === "Request Cancelled"
          ) {
            return;
          }
        });
    default:
      return axios[method](url, data, config)
        .then(res => res.data)
        .catch(err => {
          if (
            err.message === "Operation cancelled due to new request." ||
            err.message === "Request Cancelled"
          ) {
            return;
          }
        });
  }
};

const requests = {
  get: (url: string) => createRequest("get", url),
  getBlob: (url: string, options: any) =>
    createRequest("getBlob", url, options),
  post: (url: string, body?: any) => createRequest("post", url, body),
  put: (url: string, body: any) => createRequest("put", url, body),
  delete: (url: string) => createRequest("delete", url)
};

axios.interceptors.response.use(response => {
  const { data, config } = response;
  const { maintenance, upcomingMaintenanceMessage, maintenanceRunningMessage } =
    data;

  if (maintenance) {
    if (config.url?.includes("/maintenance")) {
      return response;
    }

    dispatch({
      type: "UPDATE_COMMON",
      payload: {
        showMaintenance: true,
        upcomingMaintenanceMessage,
        maintenanceRunningMessage
      }
    });
    return Promise.reject({ message: maintenanceRunningMessage });
  }
  return response;
});

const maintenance = {
  getMaintenanceStatus: () => {
    return requests.get(`/maintenance`);
  }
};

const Agency = {
  Client: {
    list: () => requests.get("/gsts")
  }
};

const Auth = {
  //Login
  login: (email: string, password: string) =>
    requests.post("/auth/login", { email, password }),
  //Signup
  signup: (
    name: string,
    email: string,
    password: string,
    userType: string,
    mobile: number
  ) =>
    requests.post("/auth/signup", { name, email, password, userType, mobile }),
  verifySignupOTP: (userId: string, otp: number) =>
    requests.post("/auth/verify/signup/otp", { userId, otp }),
  verifySignupToken: (token: string) =>
    requests.post("/auth/verify/signup/token", { token }),
  //Forgot Password
  forgotPassword: (email: string) =>
    requests.post("/auth/forgotPassword", { email }),
  verifyResetOtp: (userId: string, otp: number, password: string) =>
    requests.post("/auth/verify/reset/otp", { userId, otp, password }),
  verifyResetToken: (token: string, password: string) =>
    requests.post("/auth/verify/reset/token", { token, password })
};

const Gst = {
  addGst: (gstin: string) => requests.post("/gsts", { gstin }),
  getAll: () => requests.get("/gsts"),
  getGst: (gstin: string) => requests.get(`/gsts/${gstin}`),
  changeName: (body: { id: string; name: string }) =>
    requests.put("/gsts/changeName", body)
};

const Misc = {
  lock: (month: string, year: string, gstId: string) =>
    requests.post(`/misc/lockmonth?month=${month}&year=${year}&gstId=${gstId}`),
  unlock: (month: string, year: string, gstId: string) =>
    requests.post(
      `/misc/unlockmonth?month=${month}&year=${year}&gstId=${gstId}`
    )
};

const Sales = {
  import: (file: any) => requests.post("/sales/import", file),
  startImport: (importRequest: string) =>
    requests.post("/sales/import/start", { importRequest }),
  getImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `/sales/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  deleteImportData: (importRequest: string) =>
    requests.delete(`/sales/import/deleteFile?importRequest=${importRequest}`),
  getSummary: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/summary?month=${month}&year=${year}&gstId=${gstId}`),
  deleteTransaction: (id: string) => requests.delete(`/sales/${id}`),
  getTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    download: boolean,
    searchText: string,
    platform: string,
    transactionType: string
  ) =>
    download === false
      ? requests.get(
          `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&download=${download}&searchText=${searchText}&gstId=${gstId}&platform=${platform}&transactionType=${transactionType}`
        )
      : requests.getBlob(
          //by default all the records are downloaded, concept of skip and limit is not considered at the time of downloading
          `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&download=${download}&searchText=${searchText}&gstId=${gstId}&platform=${platform}&transactionType=${transactionType}`,
          {
            responseType: "blob"
          }
        )
  /* getReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Sales Return"
      )}`
    ) */
  /* getSpecificReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/sales/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&platform=${platform}&transactionType=${encodeURI(
        "Sales Return"
      )}`
    ) */
  /* exportSalesTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/sales/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}
      `,
      {
        responseType: "blob"
      }
    ), */
  /* exportSpecificSalesTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/sales/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}&platform=${platform}
    `,
      {
        responseType: "blob"
      }
    ) */
};

const Purchase = {
  import: (file: any) => requests.post("/purchase/import", file),
  startImport: (importRequest: string) =>
    requests.post("/purchase/import/start", { importRequest }),
  getImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `/purchase/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  deleteImportData: (importRequest: string) =>
    requests.delete(
      `/purchase/import/deleteFile?importRequest=${importRequest}`
    ),
  getSummary: (month: string, year: string, gstId: string) =>
    requests.get(
      `/purchase/summary?month=${month}&year=${year}&gstId=${gstId}`
    ),
  getTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    download: boolean,
    searchText: string,
    platform: string,
    transactionType: string
  ) =>
    download === false
      ? requests.get(
          `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&download=${download}&searchText=${searchText}&gstId=${gstId}&platform=${platform}&transactionType=${transactionType}`
        )
      : requests.getBlob(
          //by default all the records are downloaded, concept of skip and limit is not considered at the time of downloading
          `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&download=${download}&searchText=${searchText}&gstId=${gstId}&platform=${platform}&transactionType=${transactionType}`,
          {
            responseType: "blob"
          }
        ),
  /* getSpecificTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=Purchase&platform=${platform}`
    ), */
  deleteTransaction: (id: string) => requests.delete(`/purchase/${id}`)
  /* getReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Purchase Return"
      )}`
    ), */
  /* getSpecificReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Purchase Return"
      )}&platform=${platform}`
    ), */
  /* exportPurchaseTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/purchase/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}
    `,
      {
        responseType: "blob"
      }
    ), */
  /* exportSpecificPurchaseTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/purchase/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}&platform=${platform}
  `,
      {
        responseType: "blob"
      }
    ) */
};

const Returns = {
  iffData: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/iff?month=${month}&year=${year}&gstId=${gstId}`),
  iffJson: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/iffJson?month=${month}&year=${year}&gstId=${gstId}`),
  gstr1Data: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr1?month=${month}&year=${year}&gstId=${gstId}`),
  gstr1Json: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr1Json?month=${month}&year=${year}&gstId=${gstId}`),
  gstr3bData: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr3b?month=${month}&year=${year}&gstId=${gstId}`),
  gstr3bJson: (month: string, year: string, gstId: string) =>
    requests.get(
      `/sales/gstr3bjson?month=${month}&year=${year}&gstId=${gstId}`
    ),
  gstr2import: (file: any) => requests.post("/gstr2/import", file),
  startGstr2Import: (importRequest: string) =>
    requests.post("/gstr2/import/start", { importRequest }),
  getGstr2ImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `gstr2/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  gstr2distinctTypes: (month: string, year: string, gstId: string) =>
    requests.get(
      `gstr2/distinctTypes?month=${month}&year=${year}&gstId=${gstId}`
    ),
  gstr2Data: (
    month: string,
    year: string,
    gstId: string,
    skip: string,
    limit: string,
    platform: string,
    type: string,
    download: boolean,
    searchText: string
  ) =>
    download === false
      ? requests.get(
          `gstr2/transactions?month=${month}&year=${year}&gstId=${gstId}&skip=${skip}&limit=${limit}&platform=${platform}&type=${type}&download=${download}&searchText=${searchText}`
        )
      : requests.getBlob(
          //by default all the records are downloaded, concept of skip and limit is not considered at the time of downloading
          `gstr2/transactions?month=${month}&year=${year}&gstId=${gstId}&skip=${skip}&limit=${limit}&platform=${platform}&type=${type}&download=${download}&searchText=${searchText}`,
          {
            responseType: "blob"
          }
        ),

  getGstr2Summary: (month: string, year: string, gstId: string) =>
    requests.get(`/gstr2/summary?month=${month}&year=${year}&gstId=${gstId}`),
  bulkCopy: (
    month: string,
    year: string,
    gstId: string,
    platforms: [],
    types: []
  ) =>
    requests.put(
      `/gstr2/bulkCopyTransaction?gstId=${gstId}&month=${month}&year=${year}`,
      { gstId, month, year, platforms, types }
    ),
  copyToPurchase: (id: string, copied: boolean) =>
    requests.put(`gstr2/${id}/copied`, { copied }),
  itcClaimCount: (gstId: string, month: string, year: string) =>
    requests.get(
      `/purchase/getNoOfTransactions?gstId=${gstId}&month=${month}&year=${year}`
    ),
  get2APurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/2aTransaction?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  get2BPurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/2bTransaction?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  getUnclaimedPurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/normalTransactions?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  claimItc: (
    purchaseId: string,
    claim: boolean,
    month: string,
    year: string,
    removeItcFields: boolean
  ) =>
    requests.put(`/purchase/${purchaseId}/claimItc`, {
      claim,
      month,
      year,
      removeItcFields
    })
};

const Report = {
  Summary: (month: string, year: string, gstId: string, periodType: string) =>
    requests.getBlob(
      `/reports/summary?month=${month}&year=${year}&gstId=${gstId}&periodType=${periodType}`,
      {
        responseType: "blob"
      }
    )
};

let agent = {
  setToken: (_token: string | undefined) => (token = _token),
  Agency,
  Auth,
  Gst,
  Misc,
  Sales,
  Purchase,
  Returns,
  Report,
  maintenance
};

export default agent;
