import { PlusIcon } from "@heroicons/react/solid";
import React from "react";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { connect, ConnectedProps } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import agent from "../../../agent";
import Dashboard from "../../../components/Dashboard";
import DeleteModal from "../../../components/DeleteModal";
import Icon from "../../../components/Icon";
import FileUploader from "../../../components/Import/FileUploader";
import ImportModal from "../../../components/Import/ImportModal";
import SelectMenuProfile from "../../../components/SelectMenuProfile";
import ImportTypes from "../../../constants/importTypes";
import { ADD_NOTIFICATION } from "../../../store/types";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "salesimport"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const heaaders = ["File", "Platform", "Status", "Date", ""];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

class Imports extends React.Component<RouteComponentProps, PropsFromRedux> {
  state: {
    file: File | undefined;
    importRequests: any;
    totalRecords: number;
    displayRecords: any;
    loading: boolean;
    logging: boolean;
    selectedPlatform: any;
    showModal: boolean;
    showDeleteModal: boolean;
    deleteId: boolean | undefined;
    importDetails: any;
    merge: any;
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      file: undefined,
      importRequests: [],
      totalRecords: 0,
      displayRecords: [],
      loading: false,
      logging: false,
      selectedPlatform: ImportTypes.SalesPlatformTypes[0],
      showModal: false,
      showDeleteModal: false,
      deleteId: undefined,
      importDetails: {},
      merge: {}
    };
  }

  componentDidMount() {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      (prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear) !== (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  }

  // Chunk Size for number of table data displayed in each page during pagination
  chunkSize = 10;
  // Selected pagination value
  currPage = 0;

  onLoad = () => {
    if ((this.props as any).currentGst) {
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      this.setState({ loading: true });
      agent.Sales.getImportRequests(gstId, year, month)
        .then((response: any) => {
          response
            ? this.setState({
                importRequests: response.importRequests,
                totalRecords: response.importRequests.length,
                displayRecords: response.importRequests.slice(
                  0,
                  this.chunkSize
                ),
                loading: false
              })
            : this.setState({ loading: false });
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  // on page change, load new data from database
  handlePageClick = (data: any) => {
    this.currPage = data.selected;
    this.setState({
      displayRecords: this.state.importRequests.slice(
        this.currPage * this.chunkSize,
        this.currPage * this.chunkSize + this.chunkSize
      )
    });
  };

  import = () => {
    if (!this.state.selectedPlatform) {
      return (this.props as any).addNotification(
        "Warning",
        "No Platform Selected",
        "warn"
      );
    }
    let platform = this.state.selectedPlatform?.value;

    let gstId = (this.props as any).currentGst?._id;
    if (!gstId) {
      return (this.props as any).addNotification(
        "Warning",
        "No GST Selected",
        "warn"
      );
    }

    let month = (this.props as any).currentMonth;
    if (!month) {
      return (this.props as any).addNotification(
        "Warning",
        "No month Selected",
        "warn"
      );
    }

    let year = (this.props as any).currentYear;
    if (!year) {
      return (this.props as any).addNotification(
        "Warning",
        "No year Selected",
        "warn"
      );
    }

    let data = new FormData();
    data.append("file", this.state.file as File);
    data.append(
      "body",
      JSON.stringify({
        platform,
        gstId,
        month,
        year
      })
    );
    this.setState({
      logging: true
    });
    agent.Sales.import(data)
      .then((response: any) => {
        let merge: any = {};
        if (response.successLines)
          response.successLines.forEach((successLine: any) => {
            if (!successLine.sheet) successLine.sheet = 1;
            if (!merge[successLine.sheet]) merge[successLine.sheet] = {};
            merge[successLine.sheet].successLineCount =
              successLine?.successLines;
          });
        if (response.ignoreLines)
          response.ignoreLines.forEach((ignoreLine: any) => {
            if (!ignoreLine.sheet) ignoreLine.sheet = 1;
            if (!merge[ignoreLine.sheet]) merge[ignoreLine.sheet] = {};
            if (!merge[ignoreLine.sheet].ignoreLines)
              merge[ignoreLine.sheet].ignoreLines = {};
            if (!merge[ignoreLine.sheet].ignoreLines[ignoreLine.message]) {
              merge[ignoreLine.sheet].ignoreLines[ignoreLine.message] = [
                ignoreLine.row
              ];
            } else {
              merge[ignoreLine.sheet].ignoreLines[ignoreLine.message].push(
                ignoreLine.row
              );
            }
            if (!merge[ignoreLine.sheet].ignoreLineCount) {
              merge[ignoreLine.sheet].ignoreLineCount = 1;
            } else {
              merge[ignoreLine.sheet].ignoreLineCount =
                merge[ignoreLine.sheet].ignoreLineCount + 1;
            }
          });
        if (response.errorLines)
          response.errorLines.forEach((errorLine: any) => {
            if (!errorLine.sheet) errorLine.sheet = 1;
            if (!merge[errorLine.sheet]) merge[errorLine.sheet] = {};
            if (!merge[errorLine.sheet].errorLines)
              merge[errorLine.sheet].errorLines = {};
            if (!merge[errorLine.sheet].errorLines[errorLine.message]) {
              merge[errorLine.sheet].errorLines[errorLine.message] = [
                errorLine.row
              ];
            } else {
              merge[errorLine.sheet].errorLines[errorLine.message].push(
                errorLine.row
              );
            }
            if (!merge[errorLine.sheet].errorLineCount) {
              merge[errorLine.sheet].errorLineCount = 1;
            } else {
              merge[errorLine.sheet].errorLineCount =
                merge[errorLine.sheet].errorLineCount + 1;
            }
          });
        if (response.warningLines)
          response.warningLines.forEach((warningLine: any) => {
            if (!warningLine.sheet) warningLine.sheet = 1;
            if (!merge[warningLine.sheet]) merge[warningLine.sheet] = {};
            if (!merge[warningLine.sheet].warningLines)
              merge[warningLine.sheet].warningLines = {};
            if (!merge[warningLine.sheet].warningLines[warningLine.message]) {
              merge[warningLine.sheet].warningLines[warningLine.message] = [
                warningLine.row
              ];
            } else {
              merge[warningLine.sheet].warningLines[warningLine.message].push(
                warningLine.row
              );
            }
            if (!merge[warningLine.sheet].warningLineCount) {
              merge[warningLine.sheet].warningLineCount = 1;
            } else {
              merge[warningLine.sheet].warningLineCount =
                merge[warningLine.sheet].warningLineCount + 1;
            }
          });
        this.setState({
          showModal: true,
          logging: false,
          importDetails: response,
          merge: merge
        });
        this.onLoad();
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  isImportEnabled = () => {
    return this.state.file && this.state.selectedPlatform;
  };

  onFileChange = (file: File) => {
    this.setState({ file });
  };

  onImportPlatformChange = (importPlatform: any) => {
    this.setState({ selectedPlatform: importPlatform });
  };

  setOpen = (open: boolean) => {
    this.setState({
      file: open === false ? undefined : this.state.file,
      showModal: open
    });
  };

  formatAMPM(date: any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  startImport = () => {
    this.setState({
      logging: true
    });
    agent.Sales.startImport(this.state.importDetails.importRequest)
      .then((response: any) => {
        this.setState({
          logging: false
        });
        this.setOpen(false);
        this.onLoad();
        return (this.props as any).addNotification(
          "Success",
          "Import successful!",
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({
          logging: false
        });
        this.setOpen(false);
        return (this.props as any).addNotification(
          "Import not successful",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  deleteFileInitiate = (requestId: string) => {
    this.deleteModalSetOpen(true);
    this.setState({ deleteId: requestId });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 salesimport">
          <div className="my-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <h1 className="text-lg leading-6 font-medium text-gray-900">
                  Sales Imports
                </h1>
                {(this.props as any).currentGst ? (
                  <>
                    <div className="w-52 h-12">
                      {/* Select Platform */}
                      <SelectMenuProfile
                        items={ImportTypes.SalesPlatformTypes}
                        default={0}
                        onChange={this.onImportPlatformChange}
                      />
                      <div className="mt-2">
                        {this.state.selectedPlatform.value === "Amazon_B2B" ||
                        this.state.selectedPlatform.value === "Amazon_B2C" ? (
                          <a
                            className="text-indigo-600 text-center whitespace-nowrap font-medium text-sm"
                            href="https://youtu.be/GY-h4sbICpo"
                            target="blank"
                          >
                            - How To Download Amazon Report?
                          </a>
                        ) : this.state.selectedPlatform.value === "Flipkart" ? (
                          <a
                            className="text-indigo-600 text-center whitespace-nowrap font-medium text-sm"
                            href="https://www.youtube.com/watch?v=DxNFdKam4fc"
                            target="blank"
                          >
                            - How To Download Flipkart Report?
                          </a>
                        ) : this.state.selectedPlatform.value === "Offline" ? (
                          <a
                            className="text-indigo-600 text-center whitespace-nowrap font-medium text-sm"
                            href="/template/Sales - Normal.xlsx"
                            download
                          >
                            - Download Template
                          </a>
                        ) : this.state.selectedPlatform.value ===
                          "Offline_Extended" ? (
                          <a
                            className="text-indigo-600 text-center whitespace-nowrap font-medium text-sm"
                            href="/template/Sales - RCM, SEZ, Export.xlsx"
                            download
                          >
                            - Download Template
                          </a>
                        ) : null}
                      </div>
                    </div>

                    {/* File Uploading Area */}
                    <FileUploader
                      addNotification={(this.props as any).addNotification}
                      file={this.state.file}
                      onChange={this.onFileChange}
                      currentPlatform={this.state.selectedPlatform}
                    />
                  </>
                ) : (
                  <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 lg:mt-20 lg:mx-40 sm:mx-0 rounded-lg">
                    <Icon
                      name="outline/document-add"
                      strokeWidth={1}
                      className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No GST Selected
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started entering a GST Number
                    </p>
                    <div className="mt-6">
                      <Link to="/gsts/add">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                        >
                          <PlusIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          Add GSTN
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              {/* Action Buttons */}
              {(this.props as any).currentGst ? (
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        this.isImportEnabled()
                          ? "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                          : "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400 "
                      }
                      onClick={this.import}
                      disabled={
                        !this.isImportEnabled() || this.state.logging === true
                      }
                    >
                      {this.state.logging ? <Icon name="loading" /> : null}
                      Import
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <ImportModal
            startImport={this.startImport}
            setOpen={this.setOpen}
            state={this.state}
          />

          <DeleteModal
            onLoad={this.onLoad}
            deleteModalSetOpen={this.deleteModalSetOpen}
            state={this.state}
            type="salesImport"
          />

          {/* Import Requests */}
          {!this.state.loading && this.state.displayRecords ? (
            this.state.totalRecords > 0 ? (
              <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 sm:px-6">
                  <h2
                    id="billing-history-heading"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Import Requests
                  </h2>
                </div>
                <div className="mt-6 flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-hidden border-t border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {heaaders.map(header => (
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {this.state.displayRecords.map(
                              (displayRecord: any) => (
                                <tr key={displayRecord._id}>
                                  <td className="px-6 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                    {displayRecord.url ? (
                                      <a href={displayRecord.url}>
                                        {displayRecord.fileName
                                          ? displayRecord.fileName
                                          : "-"}
                                      </a>
                                    ) : displayRecord.fileName ? (
                                      displayRecord.fileName
                                    ) : (
                                      "-"
                                    )}
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {displayRecord.platform
                                      ? ImportTypes.convertValueToName(
                                          displayRecord.platform
                                        )
                                      : "-"}
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                                    <span
                                      className={
                                        displayRecord.status === "SUCCEEDED"
                                          ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                          : displayRecord.status === "FAILED"
                                          ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                                          : displayRecord.status === "UPLOADED"
                                          ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-700"
                                          : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-700"
                                      }
                                    >
                                      {displayRecord.status
                                        ? toTitleCase(displayRecord.status)
                                        : "-"}
                                    </span>
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {displayRecord.createdAt
                                      ? new Date(
                                          displayRecord.createdAt
                                        ).toLocaleDateString() +
                                        " - " +
                                        this.formatAMPM(
                                          new Date(displayRecord.createdAt)
                                        )
                                      : "-"}
                                  </td>

                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.deleteFileInitiate(
                                          displayRecord._id
                                        )
                                      }
                                      className="inline-flex items-center px-1 py-1 border border-transparent text-xs rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none"
                                    >
                                      <Icon
                                        name="delete"
                                        className="mx-auto flex-shrink-0 h-4 w-4"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        {this.state.totalRecords > this.chunkSize ? (
                          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                              <div>
                                <p className="text-sm text-gray-700">
                                  Showing{" "}
                                  <span className="font-medium">
                                    {this.currPage * this.chunkSize + 1}
                                  </span>{" "}
                                  to{" "}
                                  <span className="font-medium">
                                    {(this.currPage + 1) * this.chunkSize >
                                    this.state.totalRecords
                                      ? this.state.totalRecords
                                      : (this.currPage + 1) * this.chunkSize}
                                  </span>{" "}
                                  of{" "}
                                  <span className="font-medium">
                                    {this.state.totalRecords}
                                  </span>{" "}
                                  results
                                </p>
                              </div>
                            </div>
                            <div
                              id="pagination"
                              className="text-sm text-gray-500 my-2"
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(
                                  this.state.totalRecords / this.chunkSize
                                )}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {heaaders.map(header => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-8 whitespace-nowrap text-sm text-gray-500">
                      * Import Request Data Not Available.
                    </td>
                    <td className="px-6 py-8 whitespace-nowrap text-sm text-gray-500"></td>
                    <td className="px-6 py-8 whitespace-nowrap text-sm text-gray-500"></td>
                    <td className="px-6 py-8 whitespace-nowrap text-sm text-gray-500"></td>
                    <td className="px-6 py-8 whitespace-nowrap text-sm text-gray-500"></td>
                  </tr>
                </tbody>
              </table>
            )
          ) : (
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-lg">
                          <table className="table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {heaaders.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(5)].map((e, i) => (
                                    <td className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default connector(Imports);
