import React from "react";
import convertNumber from "../../../helpers/convertNumber";

const Notes = [
  {
    name: "B2B",
    value: "Credit Notes - B2B"
  },
  {
    name: "SEZWP",
    value: "Credit Notes - SEZ with payment"
  },
  {
    name: "SEZWOP",
    value: "Credit Notes - SEZ without payment"
  },
  {
    name: "RCM",
    value: "Credit Notes - Reverse Charge"
  }
];

const noteHeaders = [
  "Credit Note No.",
  "Date",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "CGST",
  "SGST/UTGST",
  "Note Value"
];

type Props = { state?: any };

//Main Classs
class CreditTables extends React.Component<Props> {
  render() {
    return (
      <>
        {(this.props as any).state.iffDetails?.credits
          ? (this.props as any).state.iffDetails.credits.map((Note: any) =>
              Note.credits?.length > 0 ? (
                <div className="my-4 max-w-7xl mx-auto">
                  <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                    <div className="px-5 py-5 sm:p-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {Notes.filter(notes => notes.name === Note.name).map(
                          filteredNotes => {
                            return Note.name === filteredNotes.name
                              ? filteredNotes.value
                              : null;
                          }
                        )}
                      </h3>
                    </div>
                  </div>
                  <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                    <table className="min-w-full table-auto divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {noteHeaders.map(notesHeader => (
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                            >
                              {notesHeader}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {Note.credits.map((row: any) => (
                          <tr className="bg-white">
                            <td className="px-4 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                              {row.invoiceNumber ? row.invoiceNumber : "-"}
                            </td>
                            <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                              {row.date
                                ? new Date(row.date).toLocaleDateString()
                                : "-"}
                            </td>
                            <td className="px-4 py-4 whitespace-wrap text-sm text-gray-500">
                              {row.clientGstin ? row.clientGstin : "-"}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                              {row.partyName ? row.partyName : "-"}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {row.gstRate ? row.gstRate : 0}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {convertNumber(row.taxableAmount)}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {convertNumber(row.igstAmt)}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {convertNumber(row.cgstAmt)}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {convertNumber(row.utgstOrSgstAmt)}
                            </td>
                            <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                              {convertNumber(row.invoiceAmount)}
                            </td>
                          </tr>
                        ))}
                        <tr className="bg-gray-50">
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                            Total
                          </td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500"></td>
                          <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                            {convertNumber(Note.total.taxableAmount)}
                          </td>
                          <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                            {convertNumber(Note.total.igstAmt)}
                          </td>
                          <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                            {convertNumber(Note.total.cgstAmt)}
                          </td>
                          <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                            {convertNumber(Note.total.utgstOrSgstAmt)}
                          </td>
                          <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                            {convertNumber(Note.total.invoiceAmount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null
            )
          : null}
      </>
    );
  }
}

export default CreditTables;
