import React from "react";
import convertNumber from "../../../helpers/convertNumber";

const nillRatedValues = [
  {
    name: "registered_interState",
    value: "Inter-state supplies to registered person"
  },
  {
    name: "registered_intraState",
    value: "Intra-state supplies to registered person"
  },
  {
    name: "unregistered_interState",
    value: "Inter-state supplies to unregistered person"
  },
  {
    name: "unregistered_intraState",
    value: "Intra-state supplies to unregistered person"
  }
];

type Props = { state?: any };

//Main Classs
class NilRatedSupplies extends React.Component<Props> {
  render() {
    return (
      <>
        {(this.props as any).state.gstrDetails?.nillRatedSupplies &&
        ((this.props as any).state.gstrDetails?.nillRatedSupplies
          .registered_interState ||
          (this.props as any).state.gstrDetails?.nillRatedSupplies
            .registered_intraState ||
          (this.props as any).state.gstrDetails?.nillRatedSupplies
            .unregistered_interState ||
          (this.props as any).state.gstrDetails?.nillRatedSupplies
            .unregistered_intraState) ? (
          <div className="my-4 max-w-7xl mx-auto">
            <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Nil Rated Supplies
                </h3>
              </div>
            </div>
            <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
              <table className="min-w-full table-auto divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nil Rated Supplies
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Exempted
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Non-GST Supplies
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {nillRatedValues.map((row: any) => (
                    <tr className="bg-white">
                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                        {row.value ? row.value : "-"}
                      </td>
                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                        {convertNumber(
                          (this.props as any).state.gstrDetails
                            .nillRatedSupplies[row.name]
                        )}
                      </td>
                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                        0
                      </td>
                      <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                        0
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default NilRatedSupplies;
