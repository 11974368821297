import {
  Component,
  MutableRefObject,
  RefObject,
  createRef,
  ComponentType
} from "react";
import agent from "../agent";
import Icon from "./Icon";
import { compose } from "redux";
import { AppDispatch, RootState } from "../store";
import { UPDATE_COMMON } from "../store/types";
import { connect, ConnectedProps } from "react-redux";
import { CommonAction } from "../store/reducers/common";
import { ChevronDownIcon } from "@heroicons/react/solid";

interface MaintenanceData {
  frontendVersion: number;
  maintenance: boolean;
  maintenanceRunningMessage: string;
  upcomingMaintenanceMessage: string;
}

const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux> {
  showInfoFooter: boolean;
  showHideInfoFooter: () => void;
  infoFooterHeight: number;
}

interface State {
  showMaintenaceMessage: boolean;
  showRefreshMessage: boolean;
  frontendVersion?: MaintenanceData["frontendVersion"];
}

class MessageBar extends Component<Props, State> {
  state: State = {
    showMaintenaceMessage: false,
    showRefreshMessage: false
  };

  getFrontEndVersionIntervalRef: MutableRefObject<NodeJS.Timeout | null> =
    createRef();
  toggleInfoButtonRef: RefObject<HTMLDivElement> = createRef();

  componentDidMount() {
    localStorage.getItem("frontendJSVersion") &&
      this.setState({
        frontendVersion: JSON.parse(
          localStorage.getItem("frontendJSVersion") as string
        )
      });
    this.getMaintenanceStatus();
    this.getFrontEndVersionIntervalRef.current = setInterval(() => {
      this.getMaintenanceStatus();
    }, 1000 * 60 * 10);
  }

  componentWillUnmount() {
    if (this.getFrontEndVersionIntervalRef.current) {
      clearInterval(this.getFrontEndVersionIntervalRef.current);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.frontendVersion !== this.state.frontendVersion &&
      !prevState.frontendVersion &&
      this.state.frontendVersion
    ) {
      localStorage.setItem(
        "frontendJSVersion",
        JSON.stringify(this.state.frontendVersion)
      );
    }
    if (
      prevState.frontendVersion !== this.state.frontendVersion &&
      prevState.frontendVersion &&
      this.state.frontendVersion &&
      prevState?.frontendVersion < this.state?.frontendVersion
    ) {
      this.setState({ showRefreshMessage: true });
    }
  }

  getMaintenanceStatus() {
    agent.maintenance
      .getMaintenanceStatus()
      .then((response: MaintenanceData) => {
        const {
          maintenance,
          frontendVersion,
          upcomingMaintenanceMessage,
          maintenanceRunningMessage
        } = response;

        this.setState({
          frontendVersion,
          showMaintenaceMessage: !!upcomingMaintenanceMessage
        });
        this.props.updateCommon?.({
          showMaintenance: maintenance,
          upcomingMaintenanceMessage,
          maintenanceRunningMessage
        });
      });
    // .catch((error: Error | any) => {
    //   console.log(error);
    // });
  }

  refreshPage() {
    window.location.reload();
    localStorage.setItem(
      "frontendJSVersion",
      JSON.stringify(this.state.frontendVersion)
    );
  }

  render() {
    const {
      showInfoFooter,
      showHideInfoFooter,
      infoFooterHeight,
      upcomingMaintenanceMessage
    } = this.props;
    const { showMaintenaceMessage, showRefreshMessage } = this.state;

    return (
      <>
        {(showMaintenaceMessage || showRefreshMessage) && (
          <div
            ref={this.toggleInfoButtonRef}
            style={{
              bottom: showInfoFooter
                ? `calc(${infoFooterHeight}px +  ${
                    showRefreshMessage && showMaintenaceMessage
                      ? "8rem"
                      : showRefreshMessage
                      ? "4rem"
                      : "3rem"
                  })`
                : "0"
            }}
            className="md:hidden absolute z-0 left-6 border-2 border-red-500 rounded-t-md bg-red-400 cursor-pointer transition-[bottom] duration-300 ease-in-out"
            onClick={showHideInfoFooter}
          >
            <ChevronDownIcon
              className={`h-8 w-8 transition-transform duration-300 ease-in-out ${
                showInfoFooter ? "" : "rotate-180"
              }`}
            />
          </div>
        )}
        <div
          className={`space-y-3 divide-y fixed bottom-0 inset-x-0 z-10 inline-block w-full text-center bg-red-400 text-white text-sm font-bold transition-transform origin-bottom duration-300 ease-in-out ${
            showInfoFooter ? "" : "scale-y-0"
          }`}
        >
          {showRefreshMessage && (
            <button
              onClick={() => this.refreshPage()}
              className={`pt-2 ${
                showRefreshMessage && !showMaintenaceMessage ? "pb-2" : ""
              }`}
            >
              <p>
                The Content of the page has been updated. Please refresh the
                page to see the latest content.
                <span className="bg-white text-black font-medium ml-10 my-1 rounded-lg px-2 py-1 inline-flex gap-2 items-center">
                  Refresh
                  <Icon name="refresh" className="h-4 w-4 text-inherit" />
                </span>
              </p>
            </button>
          )}
          {showMaintenaceMessage && (
            <p className="py-2">{upcomingMaintenanceMessage}</p>
          )}
        </div>
      </>
    );
  }
}

export default compose<ComponentType<Props>>(connector)(MessageBar);
