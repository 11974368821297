import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { createRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { compose } from "redux";
import agent, { cancelRequest } from "../../agent";
import months from "../../constants/months";
import years from "../../constants/years";
import {
  validFinMonth,
  validFinYear
} from "../../helpers/TimeValidationFunction";
import {
  ADD_NOTIFICATION,
  GET_GSTS,
  LOGOUT,
  UPDATE_COMMON
} from "../../store/types";
import Icon from "../Icon";
import SelectMenu from "../SelectMenu";
import MultiSelect from "../MultiSelect";
import Switch from "../switch";
import MessageBar from "../MessageBar";

const menuItems = [
  {
    name: "GST",
    iconName: "outline/document-report",
    route: "/gsts"
  },
  {
    name: "Sales",
    iconName: "outline/document-text",
    children: [
      { name: "Summary", route: "/sales/summary" },
      { name: "Import", route: "/sales/import" },
      // { name: "Manual Transactions", route: "/sales/manual" },
      { name: "Transactions", route: "/sales/transactions" }
    ],
    route: "/sales"
  },
  {
    name: "Purchases",
    iconName: "outline/document-add",
    children: [
      {
        name: "Summary",
        route: "/purchase/summary"
      },
      { name: "Import", route: "/purchase/import" },
      // { name: "Manual Transactions", route: "/purchase/manual" },
      { name: "Transactions", route: "/purchase/transactions" }
    ],
    route: "/purchase"
  },
  {
    name: "Returns",
    iconName: "outline/document-text",
    children: [
      { name: "IFF", route: "/returns/iff" },
      { name: "GSTR 1", route: "/returns/gstr1" },
      {
        name: "GSTR 2A/2B",
        children: [
          {
            name: "Import",
            route: "/returns/gstr2/import"
          },
          {
            name: "Transactions",
            route: "/returns/gstr2/transactions"
          },
          { name: "Summary", route: "/returns/gstr2/summary" }
        ],
        route: "/returns/gstr2"
      },
      { name: "ITC Claim", route: "/returns/itcClaim" },
      { name: "GSTR 3B", route: "/returns/gstr3b" }
    ],
    route: "/returns"
  },
  {
    name: "Reports",
    iconName: "outline/document-report",
    route: "/reports"
  },
  {
    name: "Settings",
    iconName: "outline/settings",
    route: "/settings"
  }
  // {
  //   name: "Insights",
  //   iconName: "outline/document-report",
  //   route: "/insights",
  // },
];

interface DashboardProps {
  children: any;
  match: any;
}

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common,
  ...state.gsts,
  ...state.notification
});

const mapDispatchToProps = (dispatch: any) => ({
  onLoad: (gsts: any) => dispatch({ type: GET_GSTS, payload: { gsts } }),
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Dashboard extends React.Component<DashboardProps, PropsFromRedux> {
  state: {
    menuShow: boolean;
    gsts: any;
    lock: any;
    loading: boolean;
    menuState: any;
    subMenuState: any;
    profileMenuShow: boolean;
    showInfoFooter: boolean;
    infoFooterHeight: number;
  };

  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      gsts: undefined,
      lock: false,
      loading: false,
      menuShow: false,
      menuState: {},
      subMenuState: {},
      profileMenuShow: false,
      showInfoFooter: true,
      infoFooterHeight: 0
    };
  }

  infoFooterRef = createRef<HTMLDivElement>();

  showHideInfoFooter = () => {
    this.setState({ showInfoFooter: !this.state.showInfoFooter });
  };

  componentDidMount = () => {
    this.updateRoute();
    this.getInitialUserInfo();
    if (!this.state.loading) {
      if ((this.props as any).gsts) {
        this.setState({ gsts: (this.props as any).gsts });
      } else {
        this.setState({ loading: true });
        agent.Gst.getAll()
          .then((response: any) => {
            this.setState({ gsts: response.gsts, loading: false });
            (this.props as any).updateCommon({
              currentGst: (this.props as any).currentGst
                ? (this.props as any).currentGst
                : response.gsts[0],
              currentMonth: (this.props as any).currentMonth
                ? (this.props as any).currentMonth
                : months[0].name,
              currentYear: (this.props as any).currentYear
                ? (this.props as any).currentYear
                : years[0].name
            });
            this.updatePathName();
            setTimeout(() => {
              if ((this.props as any).match.url === "/gsts") {
                (this.props as any).history.push(
                  (this.props as any).urlInfo + "/gsts"
                );
              }
            }, 100);
            (this.props as any).onLoad(response.gsts);
          })
          .catch((err: any) => {
            if (err.message === "Request Cancelled") {
              return;
            }
            this.setState({ loading: false });
          });
      }
    }
  };

  componentWillUnmount(): void {
    cancelRequest("get", "/gsts");
    this.setState({ loading: false });
  }

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if ((this.props as any).gsts !== prevProps.gsts) {
      this.setState({
        gsts: (this.props as any).gsts
      });
    }

    if ((this.props as any).match !== prevProps.match) {
      this.updateRoute();
    }
    if (prevState.showInfoFooter !== this.state.showInfoFooter) {
      this.setState({
        infoFooterHeight: this.state.showInfoFooter
          ? this.infoFooterRef.current?.clientHeight || 0
          : 0
      });
    }
  };

  updatePathName = () => {
    setTimeout(() => {
      if (!this.state.loading) {
        if (
          (this.props as any).currentGst &&
          (this.props as any).currentYear &&
          (this.props as any).currentMonth
        ) {
          let formattedYear =
            (this.props as any).currentYear.substring(0, 4) +
            (this.props as any).currentYear.substring(5);
          let newParams =
            (this.props as any).currentGst._id +
            "-" +
            formattedYear +
            "-" +
            (this.props as any).currentMonth;
          let path = (this.props as any).match.url;
          (this.props as any).updateCommon({ urlInfo: "/" + newParams });
          let newPath;
          if ((this.props as any).match.params?.userInfo) {
            let paramLength = (this.props as any).match.params.userInfo.length;
            newPath = "/" + newParams + path.substring(paramLength + 1);
          } else {
            newPath = "/" + newParams + path;
          }
          (this.props as any).history.push(newPath);
        }
      }
    }, 100);
  };

  updateRoute = () => {
    let path = (this.props as any).match?.path;
    let url = (this.props as any).match?.url;
    let paramLength = (this.props as any).match.params?.userInfo?.length;
    path = url.substring(paramLength + 1);
    let menuState: any = {};
    let subMenuState: any = {};
    menuItems.forEach((menuItem: any) => {
      if (path.indexOf(menuItem.route) === 0) {
        menuState[menuItem.name] = true;
      }
      menuItem.children?.forEach((subItem: any) => {
        if (path.indexOf(subItem.route) === 0) {
          subMenuState[subItem.name] = true;
        }
      });
    });
    this.setState({ menuState, subMenuState });
  };

  getInitialUserInfo = () => {
    let path = (this.props as any).match?.url;
    if (path && !(path === "/gsts")) {
      let [userId, year, month] = (
        this.props as any
      ).match.params?.userInfo.split("-");
      if (!userId || !year || !month) {
        this.updatePathName();
        (this.props as any).onNotify(
          "Invalid URL",
          "Gstn or year or month is not present",
          "danger"
        );
      } else {
        year = year.substring(0, 4) + "-" + year.substring(4);
        if (!validFinYear(year)) {
          (this.props as any).onNotify(
            "Invalid URL",
            "Year is not valid",
            "warning"
          );
        } else if (!validFinMonth(month)) {
          (this.props as any).onNotify(
            "Invalid URL",
            "Month is not valid",
            "warning"
          );
        } else {
          (this.props as any).updateCommon({
            currentYear: year,
            currentMonth: month
          });
          this.setState({
            loading: true
          });
          (this.props as any).updateCommon({
            urlInfo: "/" + (this.props as any).match.params.userInfo
          });
          agent.Gst.getGst(userId)
            .then((response: any) => {
              if (response.gst) {
                (this.props as any).updateCommon({ currentGst: response.gst });
              }
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              if (err.response) {
                (this.props as any).onNotify(
                  "Invalid URL",
                  err.response.data?.message,
                  "danger"
                );
              }
              this.setState({ loading: false });
            });
        }
      }
    }
  };

  onGstChange = (item: any) => {
    (this.props as any).updateCommon({ currentGst: item });
    this.updatePathName();
  };

  onYearChange = (item: any) => {
    (this.props as any).updateCommon({ currentYear: item.name });
    this.updatePathName();
  };

  onMonthChange = (item: any) => {
    (this.props as any).updateCommon({ currentMonth: item.name });
    this.updatePathName();
  };

  onLockChange = (item: any) => {
    this.setState({ loading: true });
    item === true
      ? agent.Misc.lock(
          (this.props as any).currentMonth,
          (this.props as any).currentYear,
          (this.props as any).currentGst._id
          //(this.props as any).urlInfo
        )
          .then((response: any) => {
            this.setState({ loading: false });
          })
          .catch((err: any) => {
            this.setState({ loading: false });
          })
      : agent.Misc.unlock(
          (this.props as any).currentMonth,
          (this.props as any).currentYear,
          (this.props as any).currentGst._id
          //(this.props as any).urlInfo
        )
          .then((response: any) => {
            this.setState({ loading: false });
          })
          .catch((err: any) => {
            this.setState({ loading: false });
          });
  };

  //(this.props as any).updateCommon({ lock: item.name });

  toggleDropdown = (name: any) => {
    if (this.state.menuState[name]) {
      this.setState({ menuState: {} });
    } else {
      this.setState({ menuState: { [name]: true } });
    }
  };

  toggleSubItemDropdown = (name: any) => {
    if (this.state.subMenuState[name]) {
      this.setState({ subMenuState: {} });
    } else {
      this.setState({ subMenuState: { [name]: true } });
    }
  };

  toggleMenu = () => {
    this.setState({ menuShow: !this.state.menuShow });
  };

  toggleProfileMenu = () => {
    this.setState({ profileMenuShow: !this.state.profileMenuShow });
  };

  logout = () => {
    (this.props as any).onLogout();
    (this.props as any).onNotify(
      "Successful",
      "You have been successfully logged out.",
      "success"
    );
  };

  render() {
    return (
      <div>
        <div
          style={
            {
              height: `calc(100vh - ${this.state.infoFooterHeight}px)`
            } as React.CSSProperties
          }
          className="h-screen flex overflow-hidden bg-gray-100"
        >
          {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
          <div
            className="fixed inset-0 flex z-40 md:hidden"
            role="dialog"
            aria-modal="true"
          >
            <Transition
              show={this.state.menuShow}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-600 bg-opacity-75"
                aria-hidden="true"
              ></div>
            </Transition>
            <Transition
              show={this.state.menuShow}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                <Transition
                  show={this.state.menuShow}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none">
                      <span className="sr-only">Close sidebar</span>
                      <Icon name="outline/x" className="h-6 w-6 text-white" />
                    </button>
                  </div>
                </Transition>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="/images/TaxPido_Icon.png"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {menuItems.map((menuItem: any) =>
                      !menuItem.children ? (
                        <div key={menuItem.name}>
                          <Link
                            to={menuItem.route}
                            key={menuItem.name}
                            className={
                              this.props.match.path === menuItem.route
                                ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            }
                          >
                            <Icon
                              name={menuItem.iconName}
                              className={
                                this.state.menuState[menuItem.name]
                                  ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                                  : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                              }
                              aria-hidden="true"
                            />
                            {menuItem.name}
                          </Link>
                        </div>
                      ) : (
                        <div key={menuItem.name}>
                          <Disclosure
                            as="div"
                            key={menuItem.name}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <div
                                onClick={() =>
                                  this.toggleDropdown(menuItem.name)
                                }
                              >
                                <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                  <span className="flex items-center">
                                    <Icon
                                      name={menuItem.iconName}
                                      className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    {menuItem.name}
                                  </span>
                                  <ChevronDownIcon
                                    className={
                                      !this.state.menuState[menuItem.name]
                                        ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                    }
                                  />
                                </Disclosure.Button>
                                {this.state.menuState[menuItem.name] ? (
                                  <Disclosure.Panel
                                    className="space-y-1 ml-11 border-l border-gray-500"
                                    static
                                  >
                                    {menuItem.children.map((subItem: any) =>
                                      !subItem.children ? (
                                        <div key={subItem.name}>
                                          <Link
                                            to={subItem.route}
                                            key={subItem.name}
                                            className={
                                              this.props.match.path ===
                                              subItem.route
                                                ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                            }
                                          >
                                            {subItem.name}
                                          </Link>
                                        </div>
                                      ) : (
                                        <div key={subItem.name}>
                                          <Disclosure
                                            as="div"
                                            key={subItem.name}
                                            className="space-y-1"
                                          >
                                            {({ open }) => (
                                              <div
                                                onClick={() =>
                                                  this.toggleSubItemDropdown(
                                                    subItem.name
                                                  )
                                                }
                                              >
                                                <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                                  <span className="flex items-center">
                                                    {subItem.name}
                                                  </span>
                                                  <ChevronDownIcon
                                                    className={
                                                      !this.state.subMenuState[
                                                        subItem.name
                                                      ]
                                                        ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                        : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                    }
                                                  />
                                                </Disclosure.Button>
                                                {this.state.subMenuState[
                                                  subItem.name
                                                ] ? (
                                                  <Disclosure.Panel
                                                    className="space-y-1 ml-6 border-l border-gray-500"
                                                    static
                                                  >
                                                    {subItem.children.map(
                                                      (subSubItem: any) => (
                                                        <Link
                                                          to={subSubItem.route}
                                                          key={subSubItem.name}
                                                          className={
                                                            this.props.match
                                                              .path ===
                                                            subSubItem.route
                                                              ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                              : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                          }
                                                        >
                                                          {subSubItem.name}
                                                        </Link>
                                                      )
                                                    )}
                                                  </Disclosure.Panel>
                                                ) : null}
                                              </div>
                                            )}
                                          </Disclosure>
                                        </div>
                                      )
                                    )}
                                  </Disclosure.Panel>
                                ) : null}
                              </div>
                            )}
                          </Disclosure>
                        </div>
                      )
                    )}
                  </nav>
                </div>
              </div>
            </Transition>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
          {/* <!-- Static sidebar for desktop --> */}
          <div className="hidden md:flex md:flex-shrink-0">
            <div className="flex flex-col w-64">
              {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
              <div className="flex flex-col h-0 flex-1">
                <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
                  <img
                    className="h-8 m-auto w-auto"
                    src="/images/Taxpido_logo_white.png"
                    alt="Workflow"
                  />
                </div>
                <div className="flex-1 flex flex-col overflow-y-auto">
                  <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1">
                    {menuItems.map((menuItem: any) =>
                      !menuItem.children ? (
                        <div key={menuItem.name}>
                          <Link
                            to={(this.props as any).urlInfo + menuItem.route}
                            key={menuItem.name}
                            className={
                              this.props.match.url ===
                              (this.props as any).urlInfo + menuItem.route
                                ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            }
                          >
                            <Icon
                              name={menuItem.iconName}
                              className={
                                this.state.menuState[menuItem.name]
                                  ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                                  : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                              }
                              aria-hidden="true"
                            />
                            {menuItem.name}
                          </Link>
                        </div>
                      ) : (
                        <div key={menuItem.name}>
                          <Disclosure
                            as="div"
                            key={menuItem.name}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <div>
                                <div
                                  onClick={() => {
                                    this.toggleDropdown(menuItem.name);
                                  }}
                                >
                                  <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                    <span className="flex items-center">
                                      <Icon
                                        name={menuItem.iconName}
                                        className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      {menuItem.name}
                                    </span>
                                    <ChevronDownIcon
                                      className={
                                        !this.state.menuState[menuItem.name]
                                          ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                          : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                      }
                                    />
                                  </Disclosure.Button>
                                </div>
                                {this.state.menuState[menuItem.name] ? (
                                  <Disclosure.Panel
                                    className="space-y-1 ml-11 border-l border-gray-500"
                                    static
                                  >
                                    {menuItem.children.map((subItem: any) =>
                                      !subItem.children ? (
                                        <div key={subItem.name}>
                                          <Link
                                            to={
                                              (this.props as any).urlInfo +
                                              subItem.route
                                            }
                                            key={subItem.name}
                                            className={
                                              this.props.match.url ===
                                              (this.props as any).urlInfo +
                                                subItem.route
                                                ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                            }
                                          >
                                            {subItem.name}
                                          </Link>
                                        </div>
                                      ) : (
                                        <div key={subItem.name}>
                                          <Disclosure
                                            as="div"
                                            key={subItem.name}
                                            className="space-y-1"
                                          >
                                            {({ open }) => (
                                              <div
                                                onClick={() =>
                                                  this.toggleSubItemDropdown(
                                                    subItem.name
                                                  )
                                                }
                                              >
                                                <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                                  <span className="flex items-center">
                                                    <Icon
                                                      name={subItem.iconName}
                                                      className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                                      aria-hidden="true"
                                                    />
                                                    {subItem.name}
                                                  </span>
                                                  <ChevronDownIcon
                                                    className={
                                                      !this.state.subMenuState[
                                                        subItem.name
                                                      ]
                                                        ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                        : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                    }
                                                  />
                                                </Disclosure.Button>
                                                {this.state.subMenuState[
                                                  subItem.name
                                                ] ? (
                                                  <Disclosure.Panel
                                                    className="space-y-1 ml-6 border-l border-gray-500"
                                                    static
                                                  >
                                                    {subItem.children.map(
                                                      (subSubItem: any) => (
                                                        <Link
                                                          to={
                                                            (this.props as any)
                                                              .urlInfo +
                                                            subSubItem.route
                                                          }
                                                          key={subSubItem.name}
                                                          className={
                                                            this.props.match
                                                              .url ===
                                                            (this.props as any)
                                                              .urlInfo +
                                                              subSubItem.route
                                                              ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                              : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                          }
                                                        >
                                                          {subSubItem.name}
                                                        </Link>
                                                      )
                                                    )}
                                                  </Disclosure.Panel>
                                                ) : null}
                                              </div>
                                            )}
                                          </Disclosure>
                                        </div>
                                      )
                                    )}
                                  </Disclosure.Panel>
                                ) : null}
                              </div>
                            )}
                          </Disclosure>
                        </div>
                      )
                    )}
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="relative z-40 flex-shrink-0 flex h-16 bg-white shadow">
              <button
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none md:hidden"
                onClick={this.toggleMenu}
              >
                <span className="sr-only">Open sidebar</span>
                <Icon
                  name="outline/menu-alt-2"
                  className="h-6 w-6"
                  onClick={this.toggleMenu}
                />
              </button>
              <div className="flex-1 px-4 flex justify-between">
                {this.state.gsts && this.state.gsts.length > 0 ? (
                  <div className="flex">
                    <div className="ml-4 mr-4 mt-2.5 w-72 grow">
                      <MultiSelect
                        items={this.state.gsts.map((gst: any) => {
                          return {
                            ...gst,
                            name: gst.lgnm
                          };
                        })}
                        selected={{
                          name: (this.props as any).currentGst?.lgnm
                        }}
                        onChange={this.onGstChange}
                        placeholder="Select Client"
                      />
                      {/* old code for showing the select menu without searching */}
                      {/* <SelectMenu
                        items={this.state.gsts.map((gst: any) => {
                          return {
                            ...gst,
                            name: gst.lgnm
                          };
                        })}
                        selected={{ name: (this.props as any).currentGst?.lgnm }}
                        onChange={this.onGstChange}
                      /> */}
                    </div>
                    <div className="ml-4 mr-4 flex">
                      <SelectMenu
                        items={years}
                        selected={{ name: (this.props as any).currentYear }}
                        onChange={this.onYearChange}
                      />
                    </div>
                    <div className="ml-4 mr-4 flex">
                      <SelectMenu
                        items={months}
                        selected={{ name: (this.props as any).currentMonth }}
                        onChange={this.onMonthChange}
                      />
                    </div>
                    <div className="ml-4 mr-4 mt-1 flex grid content-center">
                      {/* <Switch
                        openIcon="outline/lock-open"
                        closeIcon="outline/lock-closed"
                        selected={false}
                        onChange={this.onLockChange}
                      /> */}
                    </div>
                  </div>
                ) : (
                  <div className="flex-3 flex"></div>
                )}
                <div className="ml-4 flex items-center md:ml-6">
                  <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none">
                    <span className="sr-only">View notifications</span>
                    <Icon name="outline/bell" className="h-6 w-6" />
                  </button>
                  {/* <!-- Profile dropdown --> */}
                  <div className="ml-3 relative">
                    <div>
                      <button
                        type="button"
                        className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none"
                        id="user-menu-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={this.toggleProfileMenu}
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </button>
                    </div>
                    <Transition
                      show={this.state.profileMenuShow}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu-button"
                        tabIndex={-1}
                      >
                        <Link
                          to="/"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          tabIndex={-1}
                          id="user-menu-item-0"
                        >
                          Your Profile
                        </Link>
                        <Link
                          to="/settings"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          tabIndex={-1}
                          id="user-menu-item-1"
                        >
                          Settings
                        </Link>
                        <Link
                          to="/"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          tabIndex={-1}
                          id="user-menu-item-2"
                          onClick={this.logout}
                        >
                          Sign out
                        </Link>
                      </div>
                    </Transition>
                  </div>
                </div>
              </div>
            </div>
            <main className="flex-1 relative overflow-y-auto focus:outline-none">
              <div className="py-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  {this.props.children}
                </div>
              </div>
            </main>
          </div>
        </div>
        <div ref={this.infoFooterRef} className="fixed md:relative">
          <MessageBar
            showInfoFooter={this.state.showInfoFooter}
            showHideInfoFooter={this.showHideInfoFooter}
            infoFooterHeight={this.state.infoFooterHeight}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Dashboard) as React.ComponentType<DashboardProps>;
