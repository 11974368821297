import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Dashboard from "../../../components/Dashboard";
import agent from "../../../agent";
import { ADD_NOTIFICATION } from "../../../store/types";
import Skeleton from "react-loading-skeleton";
import { connect, ConnectedProps } from "react-redux";
import fileDownloader from "js-file-download";
import Icon from "../../../components/Icon";
import InvoiceTables from "./invoices";
import CreditTables from "./credits";
import FilingFreqency from "../../../helpers/filingFrequency";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "iff"
  },
  dataLayerName: "PageDataLayer"
};

const invalidMonths = ["Mar", "Jun", "Sep", "Dec"];

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const types = ["Invoices", "Notes"];

const invoiceHeaders = [
  "Invoice Number",
  "Date",
  "Client GSTIN",
  "Party Name",
  "GST Rate",
  "Taxable Value",
  "IGST",
  "CGST",
  "SGST/UTGST",
  "Invoice Value"
];

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class IFFSummary extends React.Component<RouteComponentProps, PropsFromRedux> {
  state: { loading: boolean; iffDetails: any; totalRecords: number };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: false,
      iffDetails: {},
      totalRecords: 0
    };
  }

  componentDidMount = () => {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad();
    }
  };

  componentDidUpdate(prevProps: any) {
    if (
      (prevProps.currentGst !== (this.props as any).currentGst ||
        prevProps.currentMonth !== (this.props as any).currentMonth ||
        prevProps.currentYear) !== (this.props as any).currentYear
    ) {
      (this.props as any).currentMonth === "Mar" ||
      (this.props as any).currentMonth === "Jun" ||
      (this.props as any).currentMonth === "Sep" ||
      (this.props as any).currentMonth === "Dec"
        ? this.displayInvalidMonth()
        : this.onLoad();
    }
  }

  displayInvalidMonth = () => {
    this.setState({
      iffDetails: "",
      loading: false
    });
  };

  onLoad = () => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      agent.Returns.iffData(month, year, gstId)
        .then((response: any) => {
          this.getTotalRecords(response);
          this.setState({
            iffDetails: response,
            loading: false
          });
        })
        .catch((err: any) => {
          this.setState({
            iffDetails: {},
            loading: false
          });
          (this.props as any).addNotification(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    }
  };

  getTotalRecords = (response: any) => {
    let recordsNum = 0;
    Object.keys(response).forEach((items: any) =>
      Object.values(response[items]).forEach(
        (item: any) =>
          (recordsNum += item.invoices
            ? item.invoices.length
            : item.credits.length)
      )
    );
    this.setState({ totalRecords: recordsNum });
  };

  handleClick = () => {
    let gstId: string = (this.props as any).currentGst?._id;
    let year: string = (this.props as any).currentYear;
    let month: string = (this.props as any).currentMonth;
    agent.Returns.iffJson(month, year, gstId)
      .then((data: any) => {
        let res = JSON.stringify(data);
        let clientName = (this.props as any).currentGst?.lgnm;
        let fileName =
          "IFF " + clientName.slice(0, 40) + " " + month + " " + year + ".json";
        fileDownloader(res, fileName);
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  render() {
    //console.log("rohitnew", this.props);
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="iff">
          <div>
            <h1 className="inline-block text-2xl font-semibold text-gray-900 mb-4">
              IFF Return
            </h1>
            <button
              type="button"
              onClick={this.handleClick}
              className="float-right inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              Generate JSON
            </button>
          </div>
          {!this.state.loading && (this.props as any).currentGst ? (
            invalidMonths.includes((this.props as any).currentMonth) ? (
              FilingFreqency.findFilingFreq(
                (this.props as any).currentGst.filingFrequency,
                (this.props as any).currentMonth,
                (this.props as any).currentYear
              ) === "Quarterly" ? (
                // Empty activity
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    strokeWidth={1}
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    IFF is not applicable for last month of quarter. You have to
                    file GSTR-1 instead.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by importing files for other months.
                  </p>
                  <div className="mt-6">
                    <Link to="/sales/import">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        <Icon
                          name="outline/upload"
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        IMPORT
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    strokeWidth={1}
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    IFF is not applicable as QRMP preference is selected as
                    monthly for relevant quarter. You have to file GSTR-1
                    instead.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Instead check GSTR - 1 data.
                  </p>
                  <div className="mt-6">
                    <Link to="./gstr1">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        SHOW GSTR-1
                      </button>
                    </Link>
                  </div>
                </div>
              )
            ) : this.state.iffDetails &&
              this.state.totalRecords > 0 &&
              (this.state.iffDetails.invoices ||
                this.state.iffDetails.credits) ? (
              <>
                {Object.keys(types).map((type: any) => {
                  return (
                    <>
                      {types[type] === "Invoices" ? (
                        <InvoiceTables state={this.state} />
                      ) : (
                        <CreditTables state={this.state} />
                      )}
                    </>
                  );
                })}
              </>
            ) : // Empty activity
            (this.props as any).currentGst?.filingFrequency ? (
              FilingFreqency.findFilingFreq(
                (this.props as any).currentGst.filingFrequency,
                (this.props as any).currentMonth,
                (this.props as any).currentYear
              ) === "Quarterly" ? (
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    strokeWidth={1}
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    There are no B2B invoices or Credit notes present for
                    selected month.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by importing files.
                  </p>
                  <div className="mt-6">
                    <Link to="/sales/import">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        <Icon
                          name="outline/upload"
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        IMPORT
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    strokeWidth={1}
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    IFF is not applicable as QRMP preference is selected as
                    monthly for relevant quarter. You have to file GSTR-1
                    instead.
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Instead check GSTR - 1 data.
                  </p>
                  <div className="mt-6">
                    <Link to="./gstr1">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        SHOW GSTR-1
                      </button>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <></>
            )
          ) : (
            // until the data loads, show loading skeleton
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-lg">
                          <table className="table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {invoiceHeaders.map((header, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(10)].map((e, i) => (
                                    <td className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default connector(IFFSummary);
