import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Icon from "../../../../components/Icon";

type Props = {
  startImport?: () => void;
  setOpen?: (open: boolean) => void;
  state?: any;
};

class ImportModal extends React.Component<Props> {
  startImport = () => {
    (this.props as any).startImport();
  };

  setOpen = (open: boolean) => {
    (this.props as any).setOpen(open);
  };

  render() {
    return (
      <>
        <Transition.Root
          show={(this.props as any).state.showModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => this.setOpen(false)}
          >
            {(this.props as any).state.showModal ? (
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-gray-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-5 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg mt-5 leading-6 font-medium text-gray-900"
                        >
                          Confirm Import
                        </Dialog.Title>
                      </div>
                    </div>
                    {(this.props as any).state.importDetails?.successLines
                      .length > 0 ? (
                      <div className="mt-2 bg-white rounded-tl-lg rounded-tr-lg">
                        {(
                          this.props as any
                        ).state.importDetails.successLines.map((item: any) => (
                          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                            <h3 className="text-lg py-2 leading-5 font-medium text-indigo-700">
                              {item.sheet.toUpperCase()}
                            </h3>
                            <p className="pb-2">
                              Success Lines:{" "}
                              <span className="text-green-600 font-semibold">
                                {item.count}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <div className="py-5 bg-white rounded-br-lg rounded-bl-lg px-4 border-gray-200 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={this.startImport}
                        disabled={(this.props as any).state.logging === true}
                      >
                        {(this.props as any).state.logging ? (
                          <Icon name="loading" />
                        ) : null}
                        Import
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => this.setOpen(false)}
                        ref={null}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            ) : null}
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default ImportModal;
