import React from "react";

const headersB2BAoriginal = ["Invoice Number", "Invoice Date"];

const headers2A_B2BA = [
  "GSTIN of Supplier",
  "Trade/Legal name of the supplier",
  "Invoice Details",
  "Place of supply",
  "Supply Attract Reverse Charge",
  "Rate (%)",
  "Taxable Value (₹)",
  "Tax Amount",
  "GSTR-1/IFF/GSTR-5 Filing Status",
  "GSTR-1/IFF/GSTR-5 Filing Date",
  "GSTR-1/IFF/GSTR-5 Filing Period",
  "GSTR-3B Filing Status",
  "Effective date of cancellation",
  "Amendment made, if any",
  "Original tax period in which reported "
];

const headers2B_B2BA = [
  "GSTIN of Supplier",
  "Trade/Legal name of the supplier",
  "Invoice Details",
  "Place of supply",
  "Supply Attract Reverse Charge",
  "Rate (%)",
  "Taxable Value (₹)",
  "Tax Amount",
  "GSTR-1/IFF/GSTR-5 Filing Period",
  "GSTR-1/IFF/GSTR-5 Filing Date",
  "ITC Availability",
  "Reason",
  "Applicable % of Tax Rate"
];

type Props = { state?: any };

//Main Classs
class B2BATables extends React.Component<Props> {
  render() {
    return (
      <>
        {/* {(this.props as any).state.gstrDetails ? ( */}
        <>
          {(this.props as any).state.selected === "GSTR - 2A (B2BA)" ? (
            <div className="my-4 max-w-7xl mx-auto">
              <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    GSTR - 2A (B2BA)
                  </h3>
                </div>
              </div>
              <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-3 text-xs bg-gray-100 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Original Details
                        <table>
                          <thead>
                            <tr>
                              {headersB2BAoriginal.map(headerTitle => (
                                <th
                                  scope="col"
                                  className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {headerTitle}
                                </th>
                              ))}
                            </tr>
                          </thead>
                        </table>
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Revised Details
                        <table>
                          <thead>
                            <tr>
                              {headers2A_B2BA.map(headerTitle =>
                                headerTitle === "Invoice Details" ? (
                                  <th
                                    scope="col"
                                    className="w-4/12 px-4 py-3 bg-gray-100 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {headerTitle}
                                    <table>
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice number
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice type
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice Date
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice Value (₹)
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                ) : headerTitle === "Tax Amount" ? (
                                  <th
                                    scope="col"
                                    className="w-4/12 px-4 py-3 bg-gray-100 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {headerTitle}
                                    <table>
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Integrated Tax (₹)
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Central Tax (₹)
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            State/UT tax (₹)
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Cess (₹)
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                ) : (
                                  <th
                                    scope="col"
                                    className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {headerTitle}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                        </table>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {Invoice.invoices.map((row: any) => (
                        <tr className='bg-white'>
                          <td className='px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900'>
                            {row.date
                              ? new Date(row.date).toLocaleDateString()
                              : 0}
                          </td>
                          <td className='pl-6 pr-4 py-4 whitespace-nowrap text-right text-sm text-gray-500'>
                            {row.invoiceNumber ? row.invoiceNumber : '-'}
                          </td>
                          <td className='px-4 py-4 whitespace-wrap text-sm text-gray-500'>
                            {row.clientGstin ? row.clientGstin : '-'}
                          </td>
                          <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'>
                            {row.partyName ? row.partyName : '-'}
                          </td>
                          <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                            {row.gstRate ? row.gstRate : 0}
                          </td>
                          <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                            {row.taxableAmount ? row.taxableAmount : 0}
                          </td>
                          {Invoice.name === 'EWOP' ? null : (
                            <>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.igstAmt ? row.igstAmt : 0}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.cgstAmt ? row.cgstAmt : 0}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.utgstOrSgstAmt ? row.utgstOrSgstAmt : 0}
                              </td>
                            </>
                          )}
                          <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                            {row.invoiceAmount ? row.invoiceAmount : 0}
                          </td>
                          {Invoice.name === 'EWP' || Invoice.name === 'EWOP' ? (
                            <>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.portCode ? row.portCode : '-'}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.shippingBillNumber
                                  ? row.shippingBillNumber
                                  : 0}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'>
                                {row.shippingBillDate
                                  ? new Date(
                                      row.shippingBillDate
                                    ).toLocaleDateString()
                                  : 0}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                      <tr className='bg-gray-50'>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'>
                          Total
                        </td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                          {Invoice.total?.taxableAmount
                            ? Invoice.total.taxableAmount
                            : 0}
                        </td>
                        {Invoice.name === 'EWOP' ? null : (
                          <>
                            <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                              {Invoice.total?.igstAmt
                                ? Invoice.total?.igstAmt
                                : 0}
                            </td>
                            <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                              {Invoice.total?.cgstAmt
                                ? Invoice.total.cgstAmt
                                : 0}
                            </td>
                            <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                              {Invoice.total?.utgstOrSgstAmt
                                ? Invoice.total.utgstOrSgstAmt
                                : 0}
                            </td>
                          </>
                        )}
                        <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                          {Invoice.total?.invoiceAmount
                            ? Invoice.total.invoiceAmount
                            : 0}
                        </td>
                        {Invoice.name === 'EWP' || Invoice.name === 'EWOP' ? (
                          <>
                            <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                            <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                            <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                          </>
                        ) : null} */}
                    {/* </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          {(this.props as any).state.selected === "GSTR - 2B (B2BA)" ? (
            <div className="my-4 max-w-7xl mx-auto">
              <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    GSTR - 2B (B2BA)
                  </h3>
                </div>
              </div>
              <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-3 text-xs bg-gray-100 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Original Details
                        <table>
                          <thead>
                            <tr>
                              {headersB2BAoriginal.map(headerTitle => (
                                <th
                                  scope="col"
                                  className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {headerTitle}
                                </th>
                              ))}
                            </tr>
                          </thead>
                        </table>
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Revised Details
                        <table>
                          <thead>
                            <tr>
                              {headers2B_B2BA.map(headerTitle =>
                                headerTitle === "Invoice Details" ? (
                                  <th
                                    scope="col"
                                    className="w-4/12 px-4 py-3 bg-gray-100 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {headerTitle}
                                    <table>
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice number
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice type
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice Date
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Invoice Value (₹)
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                ) : headerTitle === "Tax Amount" ? (
                                  <th
                                    scope="col"
                                    className="w-4/12 px-4 py-3 bg-gray-100 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {headerTitle}
                                    <table>
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Integrated Tax (₹)
                                          </th>
                                          <th
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Central Tax (₹)
                                          </th>
                                          <th
                                            id="Q3"
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            State/UT tax (₹)
                                          </th>
                                          <th
                                            id="Q4"
                                            scope="col"
                                            className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                          >
                                            Cess (₹)
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                  </th>
                                ) : (
                                  <th
                                    scope="col"
                                    className="px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {headerTitle}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                        </table>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {Invoice.invoices.map((row: any) => (
                        <tr className='bg-white'>
                          <td className='px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900'>
                            {row.date
                              ? new Date(row.date).toLocaleDateString()
                              : 0}
                          </td>
                          <td className='pl-6 pr-4 py-4 whitespace-nowrap text-right text-sm text-gray-500'>
                            {row.invoiceNumber ? row.invoiceNumber : '-'}
                          </td>
                          <td className='px-4 py-4 whitespace-wrap text-sm text-gray-500'>
                            {row.clientGstin ? row.clientGstin : '-'}
                          </td>
                          <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'>
                            {row.partyName ? row.partyName : '-'}
                          </td>
                          <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                            {row.gstRate ? row.gstRate : 0}
                          </td>
                          <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                            {row.taxableAmount ? row.taxableAmount : 0}
                          </td>
                          {Invoice.name === 'EWOP' ? null : (
                            <>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.igstAmt ? row.igstAmt : 0}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.cgstAmt ? row.cgstAmt : 0}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.utgstOrSgstAmt ? row.utgstOrSgstAmt : 0}
                              </td>
                            </>
                          )}
                          <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                            {row.invoiceAmount ? row.invoiceAmount : 0}
                          </td>
                          {Invoice.name === 'EWP' || Invoice.name === 'EWOP' ? (
                            <>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.portCode ? row.portCode : '-'}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                                {row.shippingBillNumber
                                  ? row.shippingBillNumber
                                  : 0}
                              </td>
                              <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'>
                                {row.shippingBillDate
                                  ? new Date(
                                      row.shippingBillDate
                                    ).toLocaleDateString()
                                  : 0}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                      <tr className='bg-gray-50'>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'>
                          Total
                        </td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                        <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                          {Invoice.total?.taxableAmount
                            ? Invoice.total.taxableAmount
                            : 0}
                        </td>
                        {Invoice.name === 'EWOP' ? null : (
                          <>
                            <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                              {Invoice.total?.igstAmt
                                ? Invoice.total?.igstAmt
                                : 0}
                            </td>
                            <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                              {Invoice.total?.cgstAmt
                                ? Invoice.total.cgstAmt
                                : 0}
                            </td>
                            <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                              {Invoice.total?.utgstOrSgstAmt
                                ? Invoice.total.utgstOrSgstAmt
                                : 0}
                            </td>
                          </>
                        )}
                        <td className='px-5 py-4 whitespace-wrap text-right text-sm text-gray-500'>
                          {Invoice.total?.invoiceAmount
                            ? Invoice.total.invoiceAmount
                            : 0}
                        </td>
                        {Invoice.name === 'EWP' || Invoice.name === 'EWOP' ? (
                          <>
                            <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                            <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                            <td className='px-5 py-4 whitespace-wrap text-sm text-gray-500'></td>
                          </>
                        ) : null} */}
                    {/* </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </>
        {/* ) : null} */}
      </>
    );
  }
}

export default B2BATables;
