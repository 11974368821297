import { Component, ComponentType } from "react";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import Icon from "../components/Icon";
import MessageBar from "../components/MessageBar";
import { AppDispatch, RootState } from "../store";
import { CommonAction } from "../store/reducers/common";
import { UPDATE_COMMON } from "../store/types";

const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux>;

class Maintenance extends Component<Props> {
  componentDidMount(): void {
    document.title = "TaxPido PMS | Under Maintenance";
  }
  render() {
    return (
      <>
        <div className="min-h-screen grow py-16 px-6 sm:py-24 grid place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div className="sm:flex text-center sm:text-left">
              <Icon
                name="outline/settings"
                className="w-24 text-gray-300 animate-[spin_15s_linear_infinite] fixed top-20 right-20"
              />
              <Icon
                name="outline/settings"
                className="w-20 text-gray-300 rotate-180 animate-[spin_8s_linear_infinite] fixed top-[4.5rem] right-40"
              />
              <div>
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Under maintenance
                </h1>
                <p className="mt-3 text-base text-gray-600">
                  {this.props.maintenanceRunningMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden">
          <MessageBar
            showInfoFooter={false}
            showHideInfoFooter={() => {}}
            infoFooterHeight={0}
          />
        </div>
      </>
    );
  }
}

export default compose<ComponentType<Props>>(connector)(Maintenance);
