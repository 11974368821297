/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import agent from "../../agent";
import { useDispatch } from "react-redux";
import { onNotify } from "../../store/reducers/notification";

const EditNameModal = ({
  gstId,
  openModal,
  handleModalChange
}: {
  gstId: string;
  openModal: boolean;
  handleModalChange: (value: boolean) => void;
}) => {
  useEffect(() => {
    if (openModal) {
      agent.Gst.getGst(gstId).then((response: any) => {
        setnewNameValue(response.gst.lgnm);
      });
    }
  }, [gstId, openModal]);
  const nameInputRef = useRef(null);
  const [newNameValue, setnewNameValue] = useState<string>("");
  const dispatch = useDispatch();

  const handleNameChangeConfirm = () => {
    handleModalChange(false);
    setnewNameValue("");
    if (gstId) {
      agent.Gst.changeName({ id: gstId, name: newNameValue })
        .then((response: any) =>
          dispatch(
            onNotify(
              "Edit success",
              "Name has been successfully changed",
              "success"
            )
          )
        )
        .catch((error: any) => {
          if (error.response.data.message) {
            dispatch(onNotify(error.response.data.message, "", "danger"));
          }
        });
    } else {
      dispatch(
        onNotify("Invalid GSTIN", "No GSTIN supplied to edit name", "warning")
      );
    }
  };
  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={nameInputRef}
        onClose={() => {
          handleModalChange(false);
          setnewNameValue("");
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h2"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Enter new name for corresponding GSTIN
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col items-start justify-center h-12 py-10">
                    <input
                      name="name"
                      id="name"
                      value={newNameValue}
                      placeholder="John Doe"
                      className="focus:outline-none focus:ring focus:border-indigo-500 p-1 mt-2 w-1/2 border-gray-300 border-solid border-2 rounded-sm"
                      ref={nameInputRef}
                      onChange={(e: any) => {
                        setnewNameValue(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={() => handleNameChangeConfirm()}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    handleModalChange(false);
                    setnewNameValue("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditNameModal;
