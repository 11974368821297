import React from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import agent from "./agent";
import Notification from "./components/Notification";
// Page Imports
import GSTs from "./pages/GSTs/Index";
import AddGst from "./pages/GSTs/Add";
// Sales Transactions Import
import SalesSummary from "./pages/Sales/Summary";
import SalesImport from "./pages/Sales/Import/Index";
import SalesTransactions from "./pages/Sales/Transactions";
// Purchase Transactions Import
import PurchaseSummary from "./pages/Purchases/Summary";
import PurchaseImport from "./pages/Purchases/Import/Index";
import PurchaseTransactions from "./pages/Purchases/Transactions";
// Returns
import IFFImport from "./pages/Returns/IFF";
import GSTR1Returns from "./pages/Returns/GSTR1";
import GSTR3BReturns from "./pages/Returns/GSTR3B";
import GSTR2ABTransactions from "./pages/Returns/GSTR2AB/Transactions";
import GSTR2ABSummary from "./pages/Returns/GSTR2AB/Summary";
import GSTR2ABImport from "./pages/Returns/GSTR2AB/Import";
import ITCClaim from "./pages/Returns/ITCClaim";
// Login Import
import Login from "./pages/Login";
import Settings from "./pages/Settings/Index";
// Reports
import Reports from "./pages/Reports/Index";
import SummaryReport from "./pages/Reports/SummaryReport/Index";
// Sign Up Imports
import Signup from "./pages/Signup";
import SignupOTP from "./pages/Verify/SignupOTP";
import SignupToken from "./pages/Verify/SignupToken";
// Forgot Password Imports
import ForgetPassword from "./pages/ForgetPassword";
import ResetOTP from "./pages/Verify/ResetOTP";
import ResetToken from "./pages/Verify/ResetToken";
// Connect to redux
import { TOKEN_PRESENT } from "./store/types";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "./store";
import Maintenance from "./pages/Maintenance";

const mapStateToProps = (state: RootState) => ({
  ...state.user,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onTokenPresent: (token: string) =>
    dispatch({ type: TOKEN_PRESENT, payload: { token } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class App extends React.Component<PropsFromRedux> {
  // Getting Cookies and Set Token from Cookies

  state: {
    loading: boolean;
  };
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false
    };
  }

  getCookie = (cname: string) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  async componentWillMount() {
    this.setState({ loading: true });
    if (this.getCookie("token")) {
      agent.setToken(this.getCookie("token"));
      await (this.props as any).onTokenPresent("PRESENT");
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  }

  PrivateRoute = ({ component: Component, ...rest }: any) => {
    return (
      <Route
        {...rest}
        render={props => {
          if ((this.props as any).isAuthenticated) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
    );
  };

  PublicRoute = ({ component: Component, ...rest }: any) => {
    return (
      <Route
        {...rest}
        render={props => {
          if (!(this.props as any).isAuthenticated) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/gsts" />;
          }
        }}
      />
    );
  };

  render() {
    return this.props.showMaintenance ? (
      <Maintenance />
    ) : (
      <div>
        <Notification />
        {!this.state.loading ? (
          <Router>
            <Switch>
              {/* SignUp */}
              <this.PublicRoute exact path="/signup" component={Signup} />
              <this.PublicRoute
                exact
                path="/verify/signup/otp/:id"
                component={SignupOTP}
              />
              <this.PublicRoute
                exact
                path="/verify/signup/token/:token"
                component={SignupToken}
              />
              {/* Forgot Password */}
              <this.PublicRoute
                exact
                path="/forgetPassword"
                component={ForgetPassword}
              />
              <this.PublicRoute
                exact
                path="/verify/reset/otp/:id"
                component={ResetOTP}
              />
              <this.PublicRoute
                exact
                path="/verify/reset/token/:token"
                component={ResetToken}
              />
              {/* GST Page */}
              <this.PrivateRoute
                exact
                path="/:userInfo/gsts"
                component={GSTs}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/gsts/add"
                component={AddGst}
              />
              {/* Sales Pages */}
              <this.PrivateRoute
                exact
                path="/:userInfo/sales/summary"
                component={SalesSummary}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/sales/import"
                component={SalesImport}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/sales/transactions"
                component={SalesTransactions}
              />
              {/* <this.PrivateRoute
                exact
                path='/sales/manual'
                component={SalesTransactions}
              /> */}
              {/* Purchase Pages */}
              <this.PrivateRoute
                exact
                path="/:userInfo/purchase/summary"
                component={PurchaseSummary}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/purchase/import"
                component={PurchaseImport}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/purchase/transactions"
                component={PurchaseTransactions}
              />
              {/* <this.PrivateRoute
                exact
                path='/purchase/manual'
                component={PurchaseTransactions}
              /> */}
              {/* Returns */}
              <this.PrivateRoute
                exact
                path="/:userInfo/returns/iff"
                component={IFFImport}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/returns/gstr1"
                component={GSTR1Returns}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/returns/gstr2/import"
                component={GSTR2ABImport}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/returns/gstr2/transactions"
                component={GSTR2ABTransactions}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/returns/gstr2/summary"
                component={GSTR2ABSummary}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/returns/itcClaim"
                component={ITCClaim}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/returns/gstr3b"
                component={GSTR3BReturns}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/reports"
                component={Reports}
              />
              <this.PrivateRoute
                exact
                path="/:userInfo/reports/summary"
                component={SummaryReport}
              />
              {/* Insights and settings */}
              {/* <this.PrivateRoute exact path="/insights" component={Settings} /> */}
              <this.PrivateRoute
                exact
                path="/:userInfo/settings"
                component={Settings}
              />
              <this.PrivateRoute exact path="/gsts" component={GSTs} />
              {/* Login */}
              <this.PublicRoute path="/" component={Login} />
            </Switch>
          </Router>
        ) : null}
      </div>
    );
  }
}

export default connector(App);
