import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { connect, ConnectedProps } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import agent from "../../../agent";
import Dashboard from "../../../components/Dashboard";
import Icon from "../../../components/Icon";
import { ADD_NOTIFICATION } from "../../../store/types";
import getState from "../../../constants/states";
//import ColumnsFilter from "../../../components/columnsFilter";
import convertNumber from "../../../helpers/convertNumber";
import DeleteModal from "../../../components/DeleteModal";
import ImportTypes from "../../../constants/importTypes";
import { downloadFile } from "../../../helpers/downloadFile";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "purchasetransactions"
  },
  dataLayerName: "PageDataLayer"
};

const types = ["Purchase", "Purchase Returns"];

const platforms = ["ALL", "B2B", "RCM", "IMG", "IMS"];

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Transaction extends React.Component<RouteComponentProps, PropsFromRedux> {
  state: {
    file: File | undefined;
    selected: string;
    selectedPlatform: string;
    showDeleteModal: boolean;
    deleteId: boolean | undefined;
    tableData: any;
    loading: boolean;
    totalRecords: number;
    currPage: number;
    changeView: boolean;
    headers: any;
    headersPurchase: any;
    exportIcon: string;
    searchText: string;
    typing: boolean;
    typingTimeout: number;
  };

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      file: undefined,
      selected: "Purchase",
      selectedPlatform: "ALL",
      showDeleteModal: false,
      deleteId: undefined,
      tableData: {},
      loading: false,
      totalRecords: 0,
      currPage: 0,
      changeView: false,
      headers: [],
      headersPurchase: {
        date: { id: 1, name: "Invoice Date", visible: true },
        invoiceNumber: { id: 2, name: "Invoice Number", visible: true },
        partyName: { id: 3, name: "Party Name", visible: true },
        vendorGstin: { id: 4, name: "GSTIN", visible: true },
        HsnSac: { id: 5, name: "HSN/ SAC", visible: true },
        placeOfSupply: { id: 6, name: "Place of Supply", visible: true },
        taxableAmount: { id: 7, name: "Taxable Amount", visible: true },
        gstRate: { id: 8, name: "GST Rate", visible: true },
        igstAmt: { id: 9, name: "IGST Amount", visible: true },
        cgstAmt: { id: 10, name: "CGST Amount", visible: true },
        utgstOrSgstAmt: { id: 11, name: "SGST/UTGST Amount", visible: true },
        invoiceAmount: { id: 12, name: "Invoice Amount", visible: true },
        remarks: { id: 13, name: "Remarks", visible: true },
        delete: { id: 14, name: "Delete Transaction", visible: true }
      },
      exportIcon: "outline/download",
      searchText: "",
      typing: false,
      typingTimeout: 0
    };
  }

  componentDidMount() {
    if (
      (this.props as any).currentGst &&
      (this.props as any).currentMonth &&
      (this.props as any).currentYear
    ) {
      this.onLoad(false, false);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.currentGst !== (this.props as any).currentGst ||
      prevProps.currentMonth !== (this.props as any).currentMonth ||
      prevProps.currentYear !== (this.props as any).currentYear
    ) {
      this.onLoad(false, false);
    }
  }

  // Chunk Size for number of table data displayed in each page during pagination
  chunkSize = 50;

  onLoad = (downloadtype: boolean, notification: boolean) => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      let skip: string = (this.state.currPage * this.chunkSize).toString();
      let limit: string = this.chunkSize.toString();
      let platform: string =
        this.state.selectedPlatform === "ALL"
          ? ""
          : this.state.selectedPlatform;
      let download: boolean = downloadtype;
      let searchText = this.state.searchText;
      let transactionType =
        this.state.selected === "Purchase" ? "Purchase" : "PurchaseReturn";
      downloadtype === false
        ? agent.Purchase.getTransactions(
            month,
            year,
            skip,
            limit,
            gstId,
            download,
            searchText,
            platform,
            transactionType
          )
            .then((response: any) => {
              this.setState({
                loading: false,
                tableData: response.purchases,
                totalRecords: response.length
              });
            })
            .catch((err: any) => {
              this.setState({ loading: false });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
            })
        : agent.Purchase.getTransactions(
            month,
            year,
            skip,
            limit,
            gstId,
            download,
            searchText,
            platform,
            transactionType
          )
            .then((response: any) => {
              transactionType === "Purchase"
                ? downloadFile(
                    response,
                    `Purchase Transactions-${month}${year}.xlsx`
                  )
                : downloadFile(
                    response,
                    `Purchase Return Transactions-${month}${year}.xlsx`
                  );
              this.setState({
                loading: false,
                exportIcon: "outline/download"
              });
            })
            .catch((err: any) => {
              this.setState({
                loading: false,
                exportIcon: "outline/download"
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
            });
    }
  };

  /* onLoad = () => {
    if ((this.props as any).currentGst) {
      this.setState({ loading: true });
      let gstId: string = (this.props as any).currentGst._id;
      let year: string = (this.props as any).currentYear;
      let month: string = (this.props as any).currentMonth;
      let skip: string = (this.state.currPage * this.chunkSize).toString();
      let limit: string = this.chunkSize.toString();
      let validPlatform: string = this.state.selectedPlatform;

      this.state.selected === "Purchase"
        ? validPlatform !== "ALL"
          ? agent.Purchase.getSpecificTransactions(
              month,
              year,
              skip,
              limit,
              gstId,
              validPlatform
            )
              .then((response: any) => {
                this.setState({
                  loading: false,
                  tableData: response.purchases,
                  totalRecords: response.length,
                  changeView: false
                });
              })
              .catch((err: any) => {
                this.setState({ loading: false, changeView: false });
                (this.props as any).addNotification(
                  "Error",
                  err?.response?.data?.message || err?.message || err,
                  "danger"
                );
              })
          : agent.Purchase.getTransactions(month, year, skip, limit, gstId)
              .then((response: any) => {
                this.setState({
                  loading: false,
                  tableData: response.purchases,
                  totalRecords: response.length,
                  changeView: false
                });
              })
              .catch((err: any) => {
                this.setState({ loading: false, changeView: false });
                (this.props as any).addNotification(
                  "Error",
                  err?.response?.data?.message || err?.message || err,
                  "danger"
                );
              })
        : validPlatform !== "ALL"
        ? agent.Purchase.getSpecificReturnTransactions(
            month,
            year,
            skip,
            limit,
            gstId,
            validPlatform
          )
            .then((response: any) => {
              this.setState({
                loading: false,
                tableData: response.purchases,
                totalRecords: response.length,
                changeView: false
              });
            })
            .catch((err: any) => {
              this.setState({ loading: false, changeView: false });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
            })
        : agent.Purchase.getReturnTransactions(month, year, skip, limit, gstId)
            .then((response: any) => {
              this.setState({
                loading: false,
                tableData: response.purchases,
                totalRecords: response.length,
                changeView: false
              });
            })
            .catch((err: any) => {
              this.setState({ loading: false, changeView: false });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
            });
    }
  }; */

  // on page change, load new data from database
  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.onLoad(false, false);
    });
  };

  setSelected = (ev: any) => {
    this.setState({ selected: ev, currPage: 0, changeView: true }, () => {
      this.onLoad(false, false);
    });
  };

  setSelectedPlatform = (ev: any) => {
    this.setState(
      { selectedPlatform: ev, currPage: 0, changeView: true },
      () => {
        this.onLoad(false, false);
      }
    );
  };

  setHeaders = () => {
    this.setState({
      headers: Object.keys(this.state.headersPurchase)
        .filter((o: any) => this.state.headersPurchase[o].visible === true)
        .map(obj => {
          return this.state.headersPurchase[obj];
        })
    });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      typing: false,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.onLoad(false, false);
      }, 700)
    });
  };

  /* exportTransactions = () => {
    this.setState({ loading: true, exportIcon: "loading" });
    let gstId: string = (this.props as any).currentGst._id;
    let year: string = (this.props as any).currentYear;
    let month: string = (this.props as any).currentMonth;
    let skip: string = (this.state.currPage * this.chunkSize).toString();
    let limit: string = this.chunkSize.toString();
    let validPlatform: string = this.state.selectedPlatform;
    let transactionType =
      this.state.selected !== "Purchase" ? "Purchase Return" : "Purchase";
    validPlatform === "ALL"
      ? agent.Purchase.exportPurchaseTransactions(
          month,
          year,
          skip,
          limit,
          gstId,
          transactionType
        )
          .then((response: any) => {
            downloadFile(response, `PurchaseTransactions-${month}${year}.xlsx`);
            this.setState({ loading: false, exportIcon: "outline/download" });
          })
          .catch((err: any) => {
            this.setState({ loading: false, exportIcon: "outline/download" });
            (this.props as any).addNotification(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          })
      : agent.Purchase.exportSpecificPurchaseTransactions(
          month,
          year,
          skip,
          limit,
          gstId,
          validPlatform,
          transactionType
        )
          .then((response: any) => {
            downloadFile(
              response,
              `PurchaseTransactions-${month}${year}-${validPlatform}.xlsx`
            );
            this.setState({ loading: false, exportIcon: "outline/download" });
          })
          .catch((err: any) => {
            this.setState({ loading: false, exportIcon: "outline/download" });
            (this.props as any).addNotification(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          });
  };
 */
  toggleHeader = (value: any) => {
    let values: any;
    let toggle: any;
    values = this.state.headersPurchase;
    toggle = Object.keys(values).find((o: any) => values[o] === value);
    values[toggle].visible = !values[toggle].visible;
    this.setState({
      headersPurchase: values,
      headers: Object.keys(this.state.headersPurchase)
        .filter((o: any) => this.state.headersPurchase[o].visible === true)
        .map(obj => {
          return this.state.headersPurchase[obj];
        })
    });
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  deleteTransactionInitiate = (requestId: string) => {
    this.setState({ deleteId: requestId });
    this.deleteModalSetOpen(true);
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard match={this.props.match}>
        <div className="purchasetransactions">
          {this.state.headers?.length > 0 ? null : this.setHeaders()}
          <div className="max-w-7xl grid grid-flow-col grid-cols-2 mx-auto mt-2">
            <h1 className="text-2xl font-semibold text-gray-900">
              {this.state.selected} Details
            </h1>
            <div className="ml-80">
              <Listbox value={this.state.selected} onChange={this.setSelected}>
                {({ open }) => (
                  <>
                    <div className="mt-1 right-0 relative max-w-xs">
                      <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-indigo-500 sm:text-sm">
                        <span className="block truncate">
                          {this.state.selected}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {types.map((type: any, i) => (
                            <Listbox.Option
                              key={i}
                              className={({ active }) =>
                                active
                                  ? "text-white bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9"
                                  : "text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                              }
                              value={type}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={
                                      selected
                                        ? "font-semibold block truncate"
                                        : "font-normal block truncate"
                                    }
                                  >
                                    {type}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={
                                        active
                                          ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                          : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                      }
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>

          <div className="max-w-7xl grid grid-flow-col grid-cols-2 mx-auto mt-2">
            <div></div>
            <div className="ml-80">
              <Listbox
                value={this.state.selectedPlatform}
                onChange={this.setSelectedPlatform}
              >
                {({ open }) => (
                  <>
                    <div className="mt-1 right-0 relative max-w-xs">
                      <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-indigo-500 sm:text-sm">
                        <span className="block">
                          {ImportTypes.convertValueToName(
                            this.state.selectedPlatform
                          ) || this.state.selectedPlatform}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {platforms.map((platform: any, i) => (
                            <Listbox.Option
                              key={i}
                              className={({ active }) =>
                                active
                                  ? "text-white bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9"
                                  : "text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                              }
                              value={platform}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={
                                      selected
                                        ? "font-semibold block"
                                        : "font-normal block"
                                    }
                                  >
                                    {ImportTypes.convertValueToName(platform) ||
                                      platform}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={
                                        active
                                          ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                          : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                      }
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            {/* {this.state.totalRecords > 0 ? (
              <ColumnsFilter
                state={this.state}
                headers={this.state.headersPurchase}
                toggleHeader={this.toggleHeader}
              />
            ) : null} */}
          </div>
          {this.state.totalRecords > 0 || this.state.searchText.length > 0 ? (
            <div className="flex grid-cols-2 basis-2 grid max-w-7xl mt-2">
              <div className="justify-self-start">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  onClick={() => this.onLoad(true, false)}
                >
                  <Icon
                    name={this.state.exportIcon}
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Export as XLSX
                </button>
              </div>
              <div>
                <div className="justify-self-stretch items-center">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Search in invoice date, invoice number, party name, GSTIN, amounts & remarks"
                    value={this.state.searchText}
                    onChange={(ev: any) => {
                      this.handleSearchTextChange(ev);
                    }}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          ) : null}

          <DeleteModal
            type="purchaseTransactions"
            onLoad={() => this.onLoad(false, false)}
            deleteModalSetOpen={this.deleteModalSetOpen}
            state={this.state}
          />

          {/* If records are present show the table else show the empty activity */}
          {!this.state.loading ? (
            (this.state.totalRecords > 0 || this.state.searchText.length > 0) &&
            this.state.tableData ? (
              <div className="max-w-7xl mx-auto overflow-hidden">
                <div className="py-6">
                  <div className="flex flex-col ">
                    <div
                      id="table-scroll"
                      className="max-h-screen overflow-x-auto sm:-mx-6 lg:-mx-8"
                    >
                      {/* <div className="h-screen overflow-x-auto sm:-mx-6 lg:-mx-8"> */}
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow border-b border-gray-200 rounded-lg">
                          <table
                            className="overflow-scroll table-auto w-full divide-y divide-gray-200 border-separate"
                            style={{ borderSpacing: 0 }}
                          >
                            <thead className="bg-gray-50">
                              {" "}
                              <tr>
                                {Object.keys(this.state.headers).map(header => (
                                  <th
                                    key={this.state.headers[header]?.id}
                                    scope="col"
                                    className={
                                      this.state.selected === "Purchase"
                                        ? "sticky top-0 w-4/12 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider border-b border-gray-300 bg-gray-50 bg-opacity-100 backdrop-blur backdrop-filter"
                                        : "sticky top-0 w-4/12 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider border-b border-gray-300 bg-gray-50 bg-opacity-100 backdrop-blur backdrop-filter"
                                    }
                                  >
                                    {this.state.headers[header]?.name}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            {this.state.totalRecords === 0 ? (
                              <div className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                No record found matching your search criteria
                              </div>
                            ) : (
                              <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.tableData
                                  ? this.state.tableData.map((row: any) => (
                                      <tr key={row._id}>
                                        {this.state.headersPurchase["date"]
                                          ?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {row.date
                                              ? new Date(
                                                  row.date
                                                ).toLocaleDateString()
                                              : "-"}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase[
                                          "invoiceNumber"
                                        ]?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                            {row.invoiceNumber
                                              ? row.invoiceNumber
                                              : "-"}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase["partyName"]
                                          ?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {row.partyName
                                              ? row.partyName
                                              : "-"}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase[
                                          "vendorGstin"
                                        ]?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.vendorGstin
                                              ? row.vendorGstin
                                              : "-"}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase["HsnSac"]
                                          ?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {row.HsnSac ? row.HsnSac : "-"}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase[
                                          "placeOfSupply"
                                        ]?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.placeOfSupply
                                              ? getState(row.placeOfSupply)
                                              : "-"}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase[
                                          "taxableAmount"
                                        ]?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                            {convertNumber(row.taxableAmount)}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase["gstRate"]
                                          ?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                            {row.gstRate ? row.gstRate : 0}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase["igstAmt"]
                                          ?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                            {convertNumber(row.igstAmt)}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase["cgstAmt"]
                                          ?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                            {convertNumber(row.cgstAmt)}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase[
                                          "utgstOrSgstAmt"
                                        ]?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                            {convertNumber(row.utgstOrSgstAmt)}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase[
                                          "invoiceAmount"
                                        ]?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                            {convertNumber(row.invoiceAmount)}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase["remarks"]
                                          ?.visible ? (
                                          <td className="pl-4 pr-2 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                                            {row.remarks ? row.remarks : "-"}
                                          </td>
                                        ) : null}
                                        {this.state.headersPurchase["delete"]
                                          ?.visible ? (
                                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                this.deleteTransactionInitiate(
                                                  row._id
                                                )
                                              }
                                              className="inline-flex items-center px-1 py-1 border border-transparent text-xs rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none"
                                            >
                                              <Icon
                                                name="delete"
                                                className="mx-auto flex-shrink-0 h-4 w-4"
                                              />
                                            </button>
                                          </td>
                                        ) : null}
                                      </tr>
                                    ))
                                  : null}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // Empty activity
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  strokeWidth={1}
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No {this.state.selected} Data
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by importing {this.state.selected} files.
                </p>
                <div className="mt-6">
                  <Link to="./import">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                      <Icon
                        name="outline/upload"
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      IMPORT
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            // until the data loads, show loading skeleton
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {Object.keys(this.state.headers).map(header => (
                                  <th
                                    key={this.state.headers[header]?.id}
                                    scope="col"
                                    className="w-1/16 px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {this.state.headers[header]?.name}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(4)].map((e, i) => (
                                <tr className="bg-white">
                                  {[...Array(14)].map((e, i) => (
                                    <td className="w-full px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900">
                                      <Skeleton />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Show pagination when total records > chunk count */}
          {this.state.totalRecords > 0 && !this.state.changeView ? (
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {this.state.currPage * this.chunkSize + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {(this.state.currPage + 1) * this.chunkSize >
                      this.state.totalRecords
                        ? this.state.totalRecords
                        : (this.state.currPage + 1) * this.chunkSize}
                    </span>{" "}
                    of{" "}
                    <span className="font-medium">
                      {this.state.totalRecords}
                    </span>{" "}
                    results
                  </p>
                </div>
              </div>
              <div id="pagination" className="text-sm text-gray-500 my-2">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(
                    this.state.totalRecords / this.chunkSize
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Dashboard>
    );
  }
}

export default connector(Transaction);
