import { Dialog, Transition } from "@headlessui/react";
import React, { ComponentType, Fragment } from "react";
import agent from "../../../../agent";
import Icon from "../../../../components/Icon";
import { gstr2Types } from "../../../../constants/gstr2types";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../../../store/types";
import ImportTypes from "../../../../constants/importTypes";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    }),
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload })
});

type Props = {
  setOpen?: (open: boolean) => void;
  state?: any;
  routeData?: any;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class BulkCopy extends React.Component<Props, PropsFromRedux> {
  state: {
    displayTypes: any;
    displayPlatforms: any;
    loading: boolean;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      displayTypes: [],
      displayPlatforms: [],
      loading: false
    };
  }

  setDisplay = () => {
    let type = (this.props as any).state.types.map(
      (type: any) => gstr2Types[type].type
    );
    let platform = (this.props as any).state.types.map(
      (type: any) => gstr2Types[type].platform
    );
    this.setState({
      displayTypes: type.filter(this.onlyUnique).map((type: any) => {
        return { type: type, checked: true };
      }),
      displayPlatforms: platform
        .filter(this.onlyUnique)
        .map((platform: any) =>
          platform === "Gstr2A"
            ? { platform: platform, checked: false }
            : { platform: platform, checked: true }
        )
    });
  };

  setOpen = (open: boolean) => {
    (this.props as any).setOpen(open);
  };

  onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  setPlatformChecked = (value: any) => {
    this.setState({
      displayPlatforms: this.state.displayPlatforms.map((o: any) =>
        o.platform === value.platform
          ? { platform: o.platform, checked: !o.checked }
          : o
      )
    });
  };

  setTypeChecked = (value: any) => {
    this.setState({
      displayTypes: this.state.displayTypes.map((o: any) =>
        o.type === value.type ? { type: o.type, checked: !o.checked } : o
      )
    });
  };

  startCopy = () => {
    this.setState({ loading: true });
    let gstId: string = (this.props as any).currentGst?._id;
    let month: string = (this.props as any).currentMonth;
    let year: string = (this.props as any).currentYear;
    let platforms: any = this.state.displayPlatforms
      .filter((o: any) => o.checked === true)
      .map((obj: any) => {
        return obj.platform;
      });
    let types: any = this.state.displayTypes
      .filter((o: any) => o.checked === true)
      .map((obj: any) => {
        return obj.type;
      });
    console.log("gstId", gstId, "month", month, "year", year);
    agent.Returns.bulkCopy(month, year, gstId, platforms, types)
      .then((response: any) => {
        this.setState({
          loading: false
        });
        (this.props as any).updateCommon({
          bulkCopyDone: true
        });
        (this.props as any).addNotification(
          "Success!",
          "Copied all transactions successfully.",
          "success"
        );
        this.setOpen(false);
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
        this.setOpen(false);
      });
  };

  render() {
    return (
      <>
        {this.state.displayTypes.length === 0 ? this.setDisplay() : null}
        <Transition.Root
          show={(this.props as any).state.showCopyModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-30 inset-0 overflow-y-auto"
            onClose={() => this.setOpen(false)}
          >
            {(this.props as any).state.showCopyModal ? (
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-gray-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div className="sm:flex text-center">
                      <div className="mt-5 w-full sm:mt-0 sm:text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg my-3 mt-5 text-left leading-6 font-medium text-gray-700"
                        >
                          Confirm Bulk Copy
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="py-5 rounded-tr-lg bg-white rounded-tl-lg px-4 sm:px-6">
                      <h3 className="text-lg my-2 leading-6 font-medium text-indigo-600">
                        Platforms
                      </h3>
                      {this.state.displayPlatforms.map((value: any) => (
                        <p className="ml-4">
                          <input
                            id={`header-${value.platform}`}
                            name={`header-${value.platform}`}
                            type="checkbox"
                            defaultChecked={value.checked}
                            onChange={() => this.setPlatformChecked(value)}
                            className="h-4 w-4 mr-2 text-indigo-600 border-gray-300 rounded"
                          />
                          <label
                            htmlFor={`header-${value.platform}`}
                            className="text-gray-700 select-none"
                          >
                            {ImportTypes.convertValueToName(value.platform)}
                          </label>
                        </p>
                      ))}
                      <h3 className="text-lg mt-5 mb-2 leading-6 font-medium text-indigo-600">
                        Types
                      </h3>
                      {this.state.displayTypes.map((value: any) => (
                        <p className="ml-4">
                          <input
                            id={`header-${value.type}`}
                            name={`header-${value.type}`}
                            type="checkbox"
                            defaultChecked={value.checked}
                            onChange={() => this.setTypeChecked(value)}
                            className="h-4 w-4 mr-2 text-indigo-600 border-gray-300 rounded"
                          />
                          <label
                            htmlFor={`header-${value.type}`}
                            className="text-gray-700 select-none"
                          >
                            {value.type.toUpperCase()}
                          </label>
                        </p>
                      ))}
                    </div>
                    <div className="py-5 bg-white rounded-br-lg rounded-bl-lg px-4 border-t border-indigo-400 border-opacity-10 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={this.startCopy}
                      >
                        {(this.props as any).state.logging ? (
                          <Icon name="loading" />
                        ) : null}
                        Bulk Copy
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => this.setOpen(false)}
                        ref={null}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <p className="text-xs text-gray-500 px-4 pt-5">
                        <b>Note:</b> <br />- Rows where move to purchase is made
                        no will not be copied. <br />- Rows which are already in
                        purchase or purchase return will be ignored
                        automatically.
                      </p>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            ) : null}
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector<ComponentType<Props>>(BulkCopy);
