import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import React, { ComponentType, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../../../store/types";
import agent from "../../../../agent";
import getState from "../../../../constants/states";
import BulkCopy from "./bulkCopy";
//import ColumnsFilter from "../../../../components/columnsFilter";
import convertNumber from "../../../../helpers/convertNumber";
import { formatDate } from "../../../../helpers/formatDate";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const options = ["Yes", "No", "-"];

type Props = {
  state?: any;
  setSelectedOption?: (ev: any) => void;
  routeData?: any;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

//Main Classs
class B2BTables extends React.Component<Props, PropsFromRedux> {
  state: {
    showCopyModal: boolean;
    types: any;
    headers: any;
    headers2A_B2B: any;
    headers2B_B2B: any;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      showCopyModal: false,
      types: (this.props as any).state.distinctTypes,
      headers: [],
      headers2A_B2B: {
        itcAvl: { id: 1, name: "ITC Availability", visible: true },
        cpyToPurchase: { id: 2, name: "Copy to Purchase", visible: true },
        clientGstin: { id: 3, name: "GSTIN of supplier", visible: true },
        legalName: {
          id: 4,
          name: "Trade/Legal name of the Supplier",
          visible: true
        },
        invoiceDetails: { id: 5, name: "Invoice Details", visible: true },
        placeOfSupply: { id: 6, name: "Place of supply", visible: true },
        reverseCharge: {
          id: 7,
          name: "Supply Attract Reverse Charge",
          visible: false
        },
        gstRate: { id: 8, name: "Rate (%)", visible: true },
        taxableAmount: { id: 9, name: "Taxable Value (₹)", visible: true },
        taxAmount: { id: 10, name: "Tax Amount", visible: true },
        gstr1FilingStatus: {
          id: 11,
          name: "GSTR-1/IFF/GSTR-5 Filing Status",
          visible: false
        },
        gstr1FilingDate: {
          id: 12,
          name: "GSTR-1/IFF/GSTR-5 Filing Date",
          visible: false
        },
        gstr1FilingPeriod: {
          id: 13,
          name: "GSTR-1/IFF/GSTR-5 Filing Period",
          visible: false
        },
        gstr3bFilingStatus: {
          id: 14,
          name: "GSTR-3B Filing Status",
          visible: false
        },
        amended: { id: 15, name: "Amendment made, if any", visible: false },
        aspd: {
          id: 16,
          name: "Tax Period in which Amended",
          visible: false
        },
        dtcancel: {
          id: 17,
          name: "Effective date of cancellation",
          visible: false
        },
        srctyp: { id: 18, name: "Source", visible: false },
        irn: { id: 19, name: "IRN", visible: false },
        irnDate: { id: 20, name: "IRN date", visible: false }
      },
      headers2B_B2B: {
        itcAvl: { id: 1, name: "ITC Availability", visible: true },
        cpyToPurchase: { id: 2, name: "Copy to Purchase", visible: true },
        clientGstin: { id: 3, name: "GSTIN of supplier", visible: true },
        legalName: {
          id: 4,
          name: "Trade/Legal name of the Supplier",
          visible: true
        },
        invoiceDetails: { id: 5, name: "Invoice Details", visible: true },
        placeOfSupply: { id: 6, name: "Place of supply", visible: true },
        reverseCharge: {
          id: 7,
          name: "Supply Attract Reverse Charge",
          visible: false
        },
        gstRate: { id: 8, name: "Rate (%)", visible: true },
        taxableAmount: { id: 9, name: "Taxable Value (₹)", visible: true },
        taxAmount: { id: 10, name: "Tax Amount", visible: true },
        gstr1FilingPeriod: {
          id: 11,
          name: "GSTR-1/IFF/GSTR-5 Period",
          visible: false
        },
        gstr1FilingDate: {
          id: 12,
          name: "GSTR-1/IFF/GSTR-5 Filing Date",
          visible: false
        },
        rsn: { id: 13, name: "Reason", visible: true },
        diffprcnt: { id: 14, name: "Applicable % of Tax Rate", visible: false },
        srctyp: { id: 15, name: "Source", visible: false },
        irn: { id: 16, name: "IRN", visible: false },
        irnDate: { id: 17, name: "IRN date", visible: false }
      }
    };
  }

  setOpen = (open: boolean) => {
    this.setState({
      showCopyModal: open
    });
  };

  setHeaders = () => {
    this.setState({
      headers:
        (this.props as any).state.selected === "GSTR - 2A (B2B)"
          ? Object.keys(this.state.headers2A_B2B)
              .filter((o: any) => this.state.headers2A_B2B[o].visible === true)
              .map(obj => {
                return this.state.headers2A_B2B[obj];
              })
          : Object.keys(this.state.headers2B_B2B)
              .filter((o: any) => this.state.headers2B_B2B[o].visible === true)
              .map(obj => {
                return this.state.headers2B_B2B[obj];
              })
    });
  };

  setSelectedOption = (ev: any, id: any) => {
    let copied: boolean = ev === "Yes" ? true : false;
    agent.Returns.copyToPurchase(id, copied)
      .then((response: any) => {
        (this.props as any).setSelectedOption(ev);
        copied
          ? (this.props as any).addNotification(
              "Success",
              "Successfully Copied to Purchase!",
              "success"
            )
          : (this.props as any).addNotification(
              "Success",
              "Status changed successfully!",
              "success"
            );
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  toggleHeader = (value: any) => {
    let values: any;
    let toggle: any;
    (this.props as any).state.selected === "GSTR - 2A (B2B)"
      ? (values = this.state.headers2A_B2B)
      : (values = this.state.headers2B_B2B);
    toggle = Object.keys(values).find((o: any) => values[o] === value);
    values[toggle].visible = !values[toggle].visible;
    (this.props as any).state.selected === "GSTR - 2A (B2B)"
      ? this.setState({
          headers2A_B2B: values,
          headers: Object.keys(this.state.headers2A_B2B)
            .filter((o: any) => this.state.headers2A_B2B[o].visible === true)
            .map(obj => {
              return this.state.headers2A_B2B[obj];
            })
        })
      : this.setState({
          headers2B_B2B: values,
          headers: Object.keys(this.state.headers2B_B2B)
            .filter((o: any) => this.state.headers2B_B2B[o].visible === true)
            .map(obj => {
              return this.state.headers2B_B2B[obj];
            })
        });
  };

  render() {
    return (
      <>
        {this.state.headers?.length > 0 ? null : this.setHeaders()}
        {(this.props as any).state.gstrDetails ? (
          <>
            <div className="flex justify-between h-10">
              <button
                type="button"
                onClick={() => this.setOpen(true)}
                className="items-center mt-2 px-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
              >
                Bulk Copy to Purchase
              </button>
              {this.state.showCopyModal ? (
                <BulkCopy
                  setOpen={this.setOpen}
                  state={this.state}
                  //routeData={(this.props as any).routeData} Removed by Rohit as it is was not working correctly on top dropdown changes. Taken directly from props
                />
              ) : null}
              {/* {(this.props as any).state.selected === "GSTR - 2A (B2B)" ? (
                <ColumnsFilter
                  state={this.state}
                  headers={this.state.headers2A_B2B}
                  toggleHeader={this.toggleHeader}
                />
              ) : (
                <ColumnsFilter
                  state={this.state}
                  headers={this.state.headers2B_B2B}
                  toggleHeader={this.toggleHeader}
                />
              )} */}
            </div>
            {(this.props as any).state.selected === "GSTR - 2A (B2B)" ? (
              <div className="my-4 max-w-7xl mx-auto">
                <div className="bg-white overflow-hidden shadow sm:rounded-t-lg">
                  <div className="px-4 py-5 flex justify-between sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      GSTR - 2A (B2B)
                    </h3>
                  </div>
                </div>
                <div className="shadow overflow-scroll border-b border-gray-200 sm:rounded-b-lg">
                  <table className="min-w-full table-auto divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {Object.keys(this.state.headers).map(headerTitle => (
                          <th
                            key={this.state.headers[headerTitle]?.id}
                            scope="col"
                            className="w-4/12 px-4 py-3 bg-gray-100 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {this.state.headers[headerTitle]?.name ===
                            "Invoice Details" ? (
                              <>
                                {this.state.headers[headerTitle]?.name}
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Invoice number
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Invoice type
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Invoice Date
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Invoice Value (₹)
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </>
                            ) : this.state.headers[headerTitle]?.name ===
                              "Tax Amount" ? (
                              <>
                                {this.state.headers[headerTitle]?.name}
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Integrated Tax (₹)
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Central Tax (₹)
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        State/UT tax (₹)
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-4 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Cess (₹)
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </>
                            ) : (
                              <>{this.state.headers[headerTitle]?.name}</>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props as any).state.gstrDetails?.transactions?.map(
                        (row: any) => (
                          <tr className="bg-white">
                            {this.state.headers2A_B2B["itcAvl"]?.visible ? (
                              <td className="px-5 py-4 text-center whitespace-wrap text-sm font-medium text-gray-900">
                                {!row.itcAvl
                                  ? "-"
                                  : row.itcAvl === "Y"
                                  ? "Yes"
                                  : "No"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["cpyToPurchase"]
                              ?.visible ? (
                              <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div className="mt-1 relative">
                                  <Listbox
                                    value={
                                      row.gstr2Copied === true
                                        ? "Yes"
                                        : row.gstr2Copied === false
                                        ? "No"
                                        : "-"
                                    }
                                    onChange={e =>
                                      this.setSelectedOption(e, row._id)
                                    }
                                  >
                                    {({ open }) => (
                                      <>
                                        <div className="mt-1 right-0 relative max-w-xs">
                                          <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-indigo-500 sm:text-sm">
                                            <span className="block truncate">
                                              {row.gstr2Copied === true
                                                ? "Yes"
                                                : row.gstr2Copied === false
                                                ? "No"
                                                : "-"}
                                            </span>
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                              <SelectorIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          </Listbox.Button>

                                          <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                              {options.map((option: any, i) => (
                                                <Listbox.Option
                                                  key={i}
                                                  className={({ active }) =>
                                                    active
                                                      ? "text-white bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9"
                                                      : "text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                                                  }
                                                  value={option}
                                                >
                                                  {({ selected, active }) => (
                                                    <>
                                                      <span
                                                        className={
                                                          selected
                                                            ? "font-semibold block"
                                                            : "font-normal block"
                                                        }
                                                      >
                                                        {option}
                                                      </span>

                                                      {selected ? (
                                                        <span
                                                          className={
                                                            active
                                                              ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                                              : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                          }
                                                        >
                                                          <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              ))}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                      </>
                                    )}
                                  </Listbox>
                                </div>
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["clientGstin"]
                              ?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                {row.clientGstin ? row.clientGstin : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["legalName"]?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-sm text-gray-500 min-w-max">
                                {row.legalName ? row.legalName : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["invoiceDetails"]
                              ?.visible ? (
                              <td>
                                <tr className="flex py-3 text-gray-500">
                                  <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                    {row.invoiceNumber
                                      ? row.invoiceNumber
                                      : "-"}
                                  </td>
                                  <td className="w-3/12 px-3 py-3 text-center whitespace-nowrap text-sm text-gray-500">
                                    {row.invoiceType ? row.invoiceType : "-"}
                                  </td>
                                  <td className="w-3/12 px-3 py-3 whitespace-nowrap text-sm  font-medium text-gray-900">
                                    {row.date ? formatDate(row.date) : "-"}
                                  </td>
                                  <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                    {convertNumber(row.invoiceAmount)}
                                  </td>
                                </tr>
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["placeOfSupply"]
                              ?.visible ? (
                              <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {row.placeOfSupply
                                  ? getState(row.placeOfSupply)
                                  : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["reverseCharge"]
                              ?.visible ? (
                              <td className="px-5 py-4 whitespace-wrap text-center text-sm text-gray-500">
                                {row.reverseCharge ? row.reverseCharge : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["gstRate"]?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-right text-sm font-medium text-gray-900">
                                {row.gstRate ? row.gstRate : 0}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["taxableAmount"]
                              ?.visible ? (
                              <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                {convertNumber(row.taxableAmount)}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["taxAmount"]?.visible ? (
                              <td>
                                <tr className="flex py-3">
                                  <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm  font-medium text-gray-900">
                                    {convertNumber(row.igstAmt)}
                                  </td>
                                  <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                    {convertNumber(row.cgstAmt)}
                                  </td>
                                  <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm  font-medium text-gray-900">
                                    {convertNumber(row.utgstOrSgstAmt)}
                                  </td>
                                  <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                    {convertNumber(row.csamt)}
                                  </td>
                                </tr>
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["gstr1FilingStatus"]
                              ?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-center text-sm font-medium text-gray-900">
                                {row.gstr1FilingStatus
                                  ? row.gstr1FilingStatus
                                  : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["gstr1FilingDate"]
                              ?.visible ? (
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                {row.gstr1FilingDate
                                  ? formatDate(row.gstr1FilingDate)
                                  : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["gstr1FilingPeriod"]
                              ?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                {row.gstr1FilingPeriod
                                  ? row.gstr1FilingPeriod
                                  : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["gstr3bFilingStatus"]
                              ?.visible ? (
                              <td className="px-5 py-4 whitespace-wrap text-center text-sm text-gray-500">
                                {row.gstr3bFilingStatus
                                  ? row.gstr3bFilingStatus
                                  : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["amended"]?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                {row.amended ? row.amended : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["aspd"]?.visible ? (
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                {row.aspd ? row.aspd : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["dtcancel"]?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                {row.dtcancel ? row.dtcancel : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["srctyp"]?.visible ? (
                              <td className="px-5 py-4 whitespace-wrap text-center text-sm text-gray-500">
                                {row.srctyp ? row.srctyp : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["irn"]?.visible ? (
                              <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                {row.irn ? row.irn : "-"}
                              </td>
                            ) : null}
                            {this.state.headers2A_B2B["irnDate"]?.visible ? (
                              <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                {row.irnDate ? formatDate(row.irnDate) : "-"}
                              </td>
                            ) : null}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            {(this.props as any).state.selected === "GSTR - 2B (B2B)" ? (
              <div className="my-4 max-w-7xl mx-auto overflow-hidden">
                <div className="bg-white shadow sm:rounded-t-lg">
                  <div className="px-4 py-5 flex justify-between sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      GSTR - 2B (B2B)
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="h-screen overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-b-lg">
                      <div>
                        <table
                          className="mx-3	overflow-scroll table-auto w-full divide-y divide-gray-200 border-separate"
                          style={{ borderSpacing: 0 }}
                        >
                          <thead className="bg-gray-50">
                            <tr>
                              {Object.keys(this.state.headers).map(
                                headerTitle => (
                                  <th
                                    key={this.state.headers[headerTitle]?.id}
                                    scope="col"
                                    className="z-20 sticky top-0 w-4/12 px-6 py-3 align-middle text-xs font-bold text-gray-500 uppercase tracking-wider border-b border-gray-300 bg-gray-50 bg-opacity-100 backdrop-blur backdrop-filter"
                                  >
                                    {this.state.headers[headerTitle]?.name ===
                                    "Invoice Details" ? (
                                      <>
                                        {this.state.headers[headerTitle]?.name}
                                        <table>
                                          <thead>
                                            <tr>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left text-xs font-bold whitespace-nowrap text-gray-500 uppercase tracking-wider backdrop-blur backdrop-filter"
                                              >
                                                Invoice number
                                              </th>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left text-xs font-bold whitespace-nowrap text-gray-500 uppercase tracking-wider backdrop-blur backdrop-filter"
                                              >
                                                Invoice type
                                              </th>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left text-xs font-bold whitespace-nowrap text-gray-500 uppercase tracking-wider backdrop-blur backdrop-filter"
                                              >
                                                Invoice Date
                                              </th>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left text-xs font-bold whitespace-nowrap text-gray-500 uppercase tracking-wider backdrop-blur backdrop-filter"
                                              >
                                                Invoice Value (₹)
                                              </th>
                                            </tr>
                                          </thead>
                                        </table>
                                      </>
                                    ) : this.state.headers[headerTitle]
                                        ?.name === "Tax Amount" ? (
                                      <>
                                        {this.state.headers[headerTitle]?.name}
                                        <table>
                                          <thead>
                                            <tr>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                              >
                                                Integrated Tax (₹)
                                              </th>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                              >
                                                Central Tax (₹)
                                              </th>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                              >
                                                State/UT tax (₹)
                                              </th>
                                              <th
                                                scope="col"
                                                className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                              >
                                                Cess (₹)
                                              </th>
                                            </tr>
                                          </thead>
                                        </table>
                                      </>
                                    ) : (
                                      <>
                                        {this.state.headers[headerTitle]?.name}
                                      </>
                                    )}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {(
                              this.props as any
                            ).state.gstrDetails?.transactions?.map(
                              (row: any) => (
                                <tr className="bg-white">
                                  {this.state.headers2B_B2B["itcAvl"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 text-center whitespace-wrap text-sm font-medium text-gray-900">
                                      {!row.itcAvl
                                        ? "-"
                                        : row.itcAvl === "Y"
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["cpyToPurchase"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                      <div className="mt-1 relative">
                                        <Listbox
                                          value={
                                            row.gstr2Copied === true
                                              ? "Yes"
                                              : row.gstr2Copied === false
                                              ? "No"
                                              : "-"
                                          }
                                          onChange={e =>
                                            this.setSelectedOption(e, row._id)
                                          }
                                        >
                                          {({ open }) => (
                                            <>
                                              <div className="mt-1 right-0 relative max-w-xs">
                                                <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-indigo-500 sm:text-sm">
                                                  <span className="block truncate">
                                                    {row.gstr2Copied === true
                                                      ? "Yes"
                                                      : row.gstr2Copied ===
                                                        false
                                                      ? "No"
                                                      : "-"}
                                                  </span>
                                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                    <SelectorIcon
                                                      className="h-5 w-5 text-gray-400"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                </Listbox.Button>

                                                <Transition
                                                  show={open}
                                                  as={Fragment}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                                >
                                                  <Listbox.Options className="z-10 absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                    {options.map(
                                                      (option: any, i) => (
                                                        <Listbox.Option
                                                          key={i}
                                                          className={({
                                                            active
                                                          }) =>
                                                            active
                                                              ? "text-white bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9"
                                                              : "text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                                                          }
                                                          value={option}
                                                        >
                                                          {({
                                                            selected,
                                                            active
                                                          }) => (
                                                            <>
                                                              <span
                                                                className={
                                                                  selected
                                                                    ? "font-semibold block"
                                                                    : "font-normal block"
                                                                }
                                                              >
                                                                {option}
                                                              </span>

                                                              {selected ? (
                                                                <span
                                                                  className={
                                                                    active
                                                                      ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                                                      : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                                                  }
                                                                >
                                                                  <CheckIcon
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                  />
                                                                </span>
                                                              ) : null}
                                                            </>
                                                          )}
                                                        </Listbox.Option>
                                                      )
                                                    )}
                                                  </Listbox.Options>
                                                </Transition>
                                              </div>
                                            </>
                                          )}
                                        </Listbox>
                                      </div>
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["clientGstin"]
                                    ?.visible ? (
                                    <td className="px-4 py-4 whitespace-wrap text-sm font-medium text-gray-900">
                                      {row.clientGstin ? row.clientGstin : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["legalName"]
                                    ?.visible ? (
                                    <td className="px-4 py-4 whitespace-wrap text-sm text-gray-500">
                                      {row.legalName ? row.legalName : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["invoiceDetails"]
                                    ?.visible ? (
                                    <td>
                                      <tr className="flex py-3 text-sm text-gray-500">
                                        <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                          {row.invoiceNumber
                                            ? row.invoiceNumber
                                            : "-"}
                                        </td>
                                        <td className="w-3/12 px-3 py-3 text-center whitespace-nowrap text-sm text-gray-500">
                                          {row.invoiceType
                                            ? row.invoiceType
                                            : "-"}
                                        </td>
                                        <td className="w-3/12 px-3 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                          {row.date
                                            ? formatDate(row.date)
                                            : "-"}
                                        </td>
                                        <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                          {convertNumber(row.invoiceAmount)}
                                        </td>
                                      </tr>
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["placeOfSupply"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {row.placeOfSupply
                                        ? getState(row.placeOfSupply)
                                        : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["reverseCharge"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-center text-sm text-gray-500">
                                      {row.reverseCharge
                                        ? row.reverseCharge
                                        : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["gstRate"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-right text-sm font-medium text-gray-900">
                                      {row.gstRate ? row.gstRate : 0}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["taxableAmount"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-right text-sm text-gray-500">
                                      {convertNumber(row.taxableAmount)}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["taxAmount"]
                                    ?.visible ? (
                                    <td>
                                      <tr className="flex py-3">
                                        <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                          {convertNumber(row.igstAmt)}
                                        </td>
                                        <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                          {convertNumber(row.cgstAmt)}
                                        </td>
                                        <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                          {convertNumber(row.utgstOrSgstAmt)}
                                        </td>
                                        <td className="w-3/12 px-3 py-3 whitespace-nowrap text-right text-sm text-gray-500">
                                          {convertNumber(row.csamt)}
                                        </td>
                                      </tr>
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["gstr1FilingPeriod"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-center text-sm font-medium text-gray-900">
                                      {row.gstr1FilingPeriod
                                        ? row.gstr1FilingPeriod
                                        : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["gstr1FilingDate"]
                                    ?.visible ? (
                                    <td className="px-4 py-4 whitespace-wrap text-sm text-gray-500">
                                      {row.gstr1FilingDate
                                        ? formatDate(row.gstr1FilingDate)
                                        : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["rsn"]?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                      {row.rsn ? row.rsn : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["diffprcnt"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-right text-sm font-medium text-gray-900">
                                      {row.diffprcnt ? row.diffprcnt : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["srctyp"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-center text-sm text-gray-500">
                                      {row.srctyp ? row.srctyp : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["irn"]?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-right text-sm font-medium text-gray-900">
                                      {row.irn ? row.irn : "-"}
                                    </td>
                                  ) : null}
                                  {this.state.headers2B_B2B["irnDate"]
                                    ?.visible ? (
                                    <td className="px-5 py-4 whitespace-wrap text-sm text-gray-500">
                                      {row.irnDate
                                        ? formatDate(row.irnDate)
                                        : "-"}
                                    </td>
                                  ) : null}
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

export default connector<ComponentType<Props>>(B2BTables);
